import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { theme } from '../styles/Theme';

/**
 * Twitter icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function TwitterIcon({ fontsize }) {
    return (
        <SvgIcon viewBox="0 0 22 17.868" fontSize={fontsize}>
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main} />
                    <stop offset="1" stopColor={theme.palette.primary.var3} />
                </linearGradient>
            </defs>
            <path id="Path_932" data-name="Path 932" d="M19.739-12.731A9.692,9.692,0,0,0,22-15.062a9.039,9.039,0,0,1-2.6.7,4.5,4.5,0,0,0,1.982-2.485,8.877,8.877,0,0,1-2.862,1.089,4.5,4.5,0,0,0-3.294-1.424,4.507,4.507,0,0,0-4.509,4.509,5.09,5.09,0,0,0,.112,1.033,12.816,12.816,0,0,1-9.3-4.718,4.475,4.475,0,0,0-.614,2.275,4.506,4.506,0,0,0,2.01,3.755A4.541,4.541,0,0,1,.893-10.9v.056A4.509,4.509,0,0,0,4.509-6.421a4.768,4.768,0,0,1-1.187.154,5.683,5.683,0,0,1-.852-.07A4.517,4.517,0,0,0,6.687-3.211a9.03,9.03,0,0,1-5.6,1.926A9.331,9.331,0,0,1,0-1.34,12.742,12.742,0,0,0,6.924.684,12.741,12.741,0,0,0,19.753-12.145C19.753-12.34,19.753-12.536,19.739-12.731Z"
                transform="translate(0 17.184)" fill={theme.palette.primary.var3} />
        </SvgIcon>
    )
}
