import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import languageEN from './locale/translate_en.json'
import languageDE from './locale/translate_de.json'
import languageFR from './locale/translate_fr.json'
import {Language} from "./data/Constants";
import {TENANT} from "./data/Configuration";

const NS = "translations"

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources: {
        [Language.EN]: languageEN,
        [Language.DE]: languageDE,
        [Language.FR]: languageFR
    },
    lng: Language.current(),
    fallbackLng: Language.EN,
    debug: true,
    ns: [NS],
    defaultNS: NS,
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
})

let load = (tenant, lang) => {
    if (tenant === 'default') return
    import("./locale/translate_" + tenant + "_" + lang + ".json").then(data => {
        console.log("import", tenant, lang, data)
        i18n.addResourceBundle(lang, NS, data[NS], true, true)
    }).catch(e => {
        console.log("failed to import translations for tenant " + tenant, e)
    });
}

const tenant = TENANT || 'default'
load(tenant, Language.EN)
load(tenant, Language.DE)
load(tenant, Language.FR)

export default i18n;
