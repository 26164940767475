import {SvgIcon} from '@material-ui/core';
import React from 'react'

/**
 * Support Chat Icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 * */

export default function ConversationIcon() {
    return (
        <SvgIcon viewBox="0 0 35 35" style={{width:'100%', height: '100%'}}>
            <path id="Path_747" data-name="Path 747" d="M30.625-30.625H4.375A4.379,4.379,0,0,0,0-26.25V-6.562A4.379,4.379,0,0,0,4.375-2.187h6.563V3.555a.818.818,0,0,0,.82.82.785.785,0,0,0,.485-.164l8.538-6.4h9.844A4.379,4.379,0,0,0,35-6.562V-26.25A4.379,4.379,0,0,0,30.625-30.625ZM32.813-6.562a2.194,2.194,0,0,1-2.187,2.188H20.05l-.581.438L13.125.82v-5.2H4.375A2.194,2.194,0,0,1,2.188-6.562V-26.25a2.194,2.194,0,0,1,2.188-2.187h26.25a2.194,2.194,0,0,1,2.188,2.188ZM8.75-18.047a1.637,1.637,0,0,0-1.641,1.641A1.637,1.637,0,0,0,8.75-14.766a1.637,1.637,0,0,0,1.641-1.641A1.637,1.637,0,0,0,8.75-18.047Zm8.75,0a1.637,1.637,0,0,0-1.641,1.641A1.637,1.637,0,0,0,17.5-14.766a1.637,1.637,0,0,0,1.641-1.641A1.637,1.637,0,0,0,17.5-18.047Zm8.75,0a1.637,1.637,0,0,0-1.641,1.641,1.637,1.637,0,0,0,1.641,1.641,1.637,1.637,0,0,0,1.641-1.641A1.637,1.637,0,0,0,26.25-18.047Z" transform="translate(0 30.625)" fill="#fff"/>
        </SvgIcon>
    )
}