import { SvgIcon } from '@material-ui/core';
import React from 'react';

/**
 * Globe button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function BadFeedBackIcon({ width, height }) {
    return (
        <SvgIcon style={{ width: width ? width : '100%', height: height ? height : '100%' }} viewBox="0 0 45.205 42.191">
            <rect id="Rectangle_155" data-name="Rectangle 155" width="45.205" height="42.191" rx="17" fill="#E82132" />
            <path id="Path_735" data-name="Path 735" d="M342.019,640.625A2.022,2.022,0,1,1,340,638.534,2.058,2.058,0,0,1,342.019,640.625Z" transform="translate(-307.851 -620.215)" fill="#424242" />
            <path id="Path_736" data-name="Path 736" d="M333.729,640.625a2.023,2.023,0,1,1-2.022-2.091A2.058,2.058,0,0,1,333.729,640.625Z" transform="translate(-318.65 -620.215)" fill="#424242" />
            <path id="Path_737" data-name="Path 737" d="M345.145,646.629a.609.609,0,0,0,.571-.99,9.431,9.431,0,0,0-13.953,0,.608.608,0,0,0,.569.99,42.7,42.7,0,0,1,12.813,0Z" transform="translate(-316.137 -614.979)" fill="#424242" />
        </SvgIcon>
    )
}
