import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

export default function DocIcon() {
    return (
        <SvgIcon x="0px" y="0px" viewBox="0 0 57.758 77.025">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main}/>
                    <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_926" data-name="Path 926" d="M55.637-20.268,43.017-32.887a7.22,7.22,0,0,0-5.1-2.121H7.22A7.244,7.244,0,0,0,0-27.773V34.8a7.222,7.222,0,0,0,7.22,7.22H50.538a7.222,7.222,0,0,0,7.22-7.22V-15.154A7.255,7.255,0,0,0,55.637-20.268Zm-3.4,3.414a2.367,2.367,0,0,1,.632,1.113H38.505V-30.1a2.367,2.367,0,0,1,1.113.632ZM50.538,37.2H7.22A2.414,2.414,0,0,1,4.813,34.8V-27.773A2.414,2.414,0,0,1,7.22-30.18H33.692v15.643a3.6,3.6,0,0,0,3.61,3.61H52.944V34.8A2.414,2.414,0,0,1,50.538,37.2ZM42.476.128c-1.023,4.933-4.888,21.012-5.024,22.607-.872-4.377-.105.241-6.287-22.7A1.819,1.819,0,0,0,29.42-1.3h-.963A1.8,1.8,0,0,0,26.713.038c-5.761,21.4-5.625,21.148-5.926,23.268-.617-3.595.316-.436-5.174-23.223A1.8,1.8,0,0,0,13.853-1.3H12.77A1.8,1.8,0,0,0,11.025.94c1.429,5.716,5.189,20.666,6.347,25.269a1.8,1.8,0,0,0,1.745,1.369h2.557a1.819,1.819,0,0,0,1.745-1.339c5.144-19.207,5.039-18.561,5.52-21.494.978,4.678.03,1.053,5.52,21.494A1.819,1.819,0,0,0,36.2,27.578H38.31c.827,0,2.061-.557,2.271-1.354L47.018.955A1.81,1.81,0,0,0,45.274-1.3H44.251A1.819,1.819,0,0,0,42.476.128Z" transform="translate(0 35.008)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
