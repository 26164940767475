import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Attendees menu icon
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function AttendeesIcon() {
    return (
        <SvgIcon width="32" height="26.534" viewBox="0 0 32 26.534">
            <g id="Group_282" data-name="Group 282" transform="translate(0 -43.729)">
                <g id="Group_6" data-name="Group 6" transform="translate(0 55.352)">
                    <g id="Group_5" data-name="Group 5" transform="translate(0 0)">
                        <path id="Path_145" data-name="Path 145"
                              d="M27.313,232.085H24.18a4.675,4.675,0,0,0-1.232.165,4.691,4.691,0,0,0-4.18-2.57H13.232a4.691,4.691,0,0,0-4.18,2.57,4.675,4.675,0,0,0-1.232-.165H4.688A4.693,4.693,0,0,0,0,236.772v5.007a2.816,2.816,0,0,0,2.813,2.813H29.188A2.816,2.816,0,0,0,32,241.779v-5.007A4.693,4.693,0,0,0,27.313,232.085ZM8.544,234.368v8.349H2.813a.939.939,0,0,1-.938-.937v-5.007a2.816,2.816,0,0,1,2.813-2.812H7.82a2.8,2.8,0,0,1,.736.1C8.549,234.16,8.544,234.263,8.544,234.368Zm13.037,8.349H10.419v-8.349a2.816,2.816,0,0,1,2.813-2.812h5.537a2.816,2.816,0,0,1,2.813,2.812Zm8.544-.937a.939.939,0,0,1-.937.938H23.456v-8.349c0-.1,0-.207-.011-.31a2.806,2.806,0,0,1,.736-.1h3.132a2.816,2.816,0,0,1,2.813,2.813Z"
                              transform="translate(0 -229.68)" fill={theme.palette.quaternary.var5}/>
                    </g>
                </g>
                <g id="Group_8" data-name="Group 8" transform="translate(2.088 48.902)">
                    <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
                        <path id="Path_146" data-name="Path 146"
                              d="M37.579,126.5a4.165,4.165,0,1,0,4.165,4.165A4.17,4.17,0,0,0,37.579,126.5Zm0,6.456a2.29,2.29,0,1,1,2.29-2.29A2.293,2.293,0,0,1,37.579,132.96Z"
                              transform="translate(-33.414 -126.504)" fill={theme.palette.quaternary.var5}/>
                    </g>
                </g>
                <path id="Path_147" data-name="Path 147"
                      d="M172.527,43.729a5.565,5.565,0,1,0,5.565,5.565A5.571,5.571,0,0,0,172.527,43.729Zm0,9.255a3.69,3.69,0,1,1,3.69-3.69A3.694,3.694,0,0,1,172.527,52.984Z"
                      transform="translate(-156.527)" fill={theme.palette.quaternary.var5}/>
                <g id="Group_12" data-name="Group 12" transform="translate(21.58 48.902)">
                    <g id="Group_11" data-name="Group 11" transform="translate(0 0)">
                        <path id="Path_148" data-name="Path 148"
                              d="M349.459,126.5a4.165,4.165,0,1,0,4.165,4.165A4.17,4.17,0,0,0,349.459,126.5Zm0,6.456a2.29,2.29,0,1,1,2.29-2.29A2.293,2.293,0,0,1,349.459,132.96Z"
                              transform="translate(-345.294 -126.504)" fill={theme.palette.quaternary.var5}/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}