import ApiService from "../data/ApiService";
import {ENDPOINT_GROUPS} from "../data/Configuration";
import axios from "axios";

/**
 * AttendeeGroup service, manages CRUD operations
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 **/
const GroupService = new ApiService(ENDPOINT_GROUPS, ["name", "event.name.en"])

/**
 * Imports to a specified user group, user entries contained in specified file
 * @param id group identifier
 * @param file user contents file
 * @returns {Promise<AxiosResponse<any>>}
 */
GroupService.import = function(id, file) {
    let data = new FormData();
    data.append("data", file);
    return axios.post(this.endpoint + id + "/import", data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export default GroupService
