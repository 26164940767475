import axios from "axios";
import {API_URL} from "./Configuration";
import ReactGA from 'react-ga4';
import GAEvent from "../models/GAEvent";

/**
 * Api service abstraction for general CRUD operation management. The constructor endpoint must include trailing slash
 * @author dame.gjorgjievski
 */
export default class ApiService {

    /**
     * Create a service given an endpoint and search properties
     * @param endpoint rest service endpoint
     * @param properties search properties
     */
    constructor(endpoint, properties) {
        this.endpoint = API_URL + endpoint
        this.properties = properties || []
    }

    /**
     * Performs a search query
     * @param query search query
     * @returns {AxiosResponse<any>} response promise
     */
    async search(query) {
        console.log("query", query)
        let filters = []
        let sorts = []
        if (query.search && query.search.length > 0) {
            const wrapper = {operator: 101, value: []}
            this.properties.forEach(prop => {
                wrapper.value.push({property: prop, operator: 7, value: "%" + query.search + "%"})
            })
            filters.push(wrapper)
        }
        if (query.orderBy) {
            sorts.push({property: query.orderBy.field, desc: query.orderDirection === 'desc'})
        }
        // ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_SEARCH, this.endpoint + "search"))
        return await axios.post(this.endpoint + "search", {
            filters: filters,
            sorts: sorts,
            page: query.page,
            size: query.pageSize
        })
    }

    /**
     * Find list of entities by search criteria
     * @param page index of page to load
     * @param size size of page to load
     * @param filters search filters
     * @param sorts search sorting
     * @returns {Promise<AxiosResponse<any>>} response promise
     */
    async find(page, size, filters = [], sorts = []) {
        // ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_SEARCH, this.endpoint + "find"))
        return await axios.post(this.endpoint + "find", {page: page, size: size, filters: filters, sorts: sorts})
    }

    /**
     * Load entity by primary id
     * @param id primary id
     * @returns {Promise<AxiosResponse<any>>} response promise
     */
    async load(id) {
        // ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_LOAD, this.endpoint + id))
        return await axios.get(this.endpoint + id)
    }

    /**
     * Delete an entity by id
     * @param id primary id
     * @returns {Promise<AxiosResponse<any>>} response promise
     */
    async delete(id) {
        ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_DELETE, this.endpoint + id))
        return await axios.delete(this.endpoint + id)
    }

    /**
     * Update an entity
     * @param data entity data
     * @returns {Promise<AxiosResponse<any>>} response promise
     */
    async update(data) {
        ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_UPDATE, this.endpoint + data.id))
        return await axios.put(this.endpoint + data.id, data)
    }

    /**
     * Create an entity
     * @param data entity data
     * @returns {Promise<AxiosResponse<any>>} response promise
     */
    async create(data) {
        ReactGA.event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.ENTITY_CREATE, this.endpoint))
        return await axios.post(this.endpoint, data)
    }

    /**
     * Saves an entity by invoking create if entity id is not known, otherwise invokes update
     * @param data entity data
     * @returns {Promise<AxiosResponse<*>>} response promise
     */
    async save(data) {
        if (data.id) return this.update(data)
        else return this.create(data)
    }
}
