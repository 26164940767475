import React from 'react'
import {Box, Slide, Tooltip, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from "@material-ui/core/styles"
import {theme} from '../styles/Theme';
import {SponsorType} from "../data/Constants";
import FileService from "../services/FileService";
import PropTypes from "prop-types";
import useLanguage from "../data/Language";
import {useTranslation} from "react-i18next";

/**
 * Sponsor box display component
 * @param props
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function SponsorBox(props) {

    const {value} = useLanguage()
    const {t} = useTranslation()
    const flagColor = {
        [SponsorType.BRONZE]: `${theme.palette.bronze}`,
        [SponsorType.SILVER]: `${theme.palette.silver}`,
        [SponsorType.GOLD]: `${theme.palette.golden}`,
        [SponsorType.MEDIA_PARTNER]: `${theme.palette.blue}`,
        [SponsorType.FOUNDING_PARTNER]: `${theme.palette.black}`
    }[props.sponsor.type]
    const flagTextColor = {
        [SponsorType.BRONZE]: `${theme.palette.common.white}`,
        [SponsorType.SILVER]: `${theme.palette.common.white}`,
        [SponsorType.GOLD]: `${theme.palette.common.white}`,
        [SponsorType.MEDIA_PARTNER]: `${theme.palette.common.white}`,
        [SponsorType.FOUNDING_PARTNER]: `${theme.palette.common.white}`
    }[props.sponsor.type]

    const classes = makeStyles((theme) => createStyles({
        rootBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: theme.spacing(45),
            maxHeight: theme.spacing(33),
            minHeight: theme.spacing(33),
            width: '100%',
            height: '100%',
            padding: theme.spacing(2),
            overflow: 'hidden',
            borderRadius: 10,
            boxShadow: '2px 7px 218px -89px rgba(0,0,0,0.42)',
            cursor: 'pointer',
            '& .badge': {
                top: 20,
                left: 0,
                padding: theme.spacing(0.8),
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6,
                color: flagTextColor,
                backgroundColor: flagColor,
            },
            '& .badge-text': {
                textTransform: 'uppercase'
            },
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }
        }
    }))()

    return (
        <Tooltip title={value(props.sponsor.name)} TransitionComponent={Slide} TransitionProps={{timeout: 0}}>
            <Box className={classes.rootBox} bgcolor={theme.palette.common.white} position="relative"
                 onClick={e => props.onSelect(props.sponsor, e)}>
                <Box position="absolute" className="badge">
                    <Typography className="badge-text" variant="button" component="span">
                        {t(`data.sponsor-type.${props.sponsor.type.toString().toLowerCase()}`)}
                    </Typography>
                </Box>
                <img src={props.sponsor.image ? FileService.url(props.sponsor.image.uri) : ''}
                     alt={value(props.sponsor.name)}/>
            </Box>
        </Tooltip>)
}

SponsorBox.propTypes = {
    sponsor: PropTypes.object,
    onSelect: PropTypes.func
}

SponsorBox.defaultProps = {
    sponsor: {},
    onSelect: function (sponsor, event) {
    }
}
