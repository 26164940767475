import PropTypes from "prop-types";
import { Avatar, Card, CardActions, CardHeader, createStyles, IconButton, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import LetterIcon from "../assets/LetterIcon";
import GlobeIcon from "../assets/GlobeIcon";
import LinkedinIcon from "../assets/LinkedinIcon";
import FacebookIcon from "../assets/FacebookIcon";
import InstagramIcon from "../assets/InstagramIcon";
import TwitterIcon from "../assets/TwitterIcon";
import MessageIcon from "../assets/MessageIcon";
import StarIcon from "../assets/StarIcon";
import { useAuth } from "./ProvideAuth";
import UserService from "../services/UserService";
import { UserAction, Visibility } from "../data/Constants";
import FileService from "../services/FileService";
import { useTranslation } from "react-i18next";
import { SocialMediaName } from '../data/Constants';

const styles = makeStyles((theme) => createStyles({
    root: {
        backgroundColor: theme.palette.primary.var2,
        borderRadius: theme.spacing(2),
        boxShadow: theme.spacing(0, 0, 1 / 2, 1 / 2) + theme.palette.secondary.var3,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
            padding: theme.spacing(2),
        }
    },
    avatar: {
        width: 45,
        height: 45,
        border: '2px solid ' + theme.palette.secondary.var1,
        cursor: 'pointer',
        [theme.breakpoints.up('sm')]: {
            width: 85,
            height: 85,
            border: '4px solid ' + theme.palette.secondary.var1,
            marginRight: theme.spacing(2),
        }
    },
    header: {
        '& .MuiCardHeader-content': {
            marginTop: theme.spacing(2),
            display: 'inline',
            width: '90%',
            overflow: 'hidden'
        }
    },
    subtitle: {
        margin: theme.spacing(1, 0)
    },
    icon: {
        padding: theme.spacing(1)
    },
    actions: {
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: -theme.spacing(1)
    }
}))

/**
 * Card component for displaying application user details
 * @author dame.gjorgjievski
 */
export default function UserCard(props) {

    const { t } = useTranslation()
    const username = props.user.name + ' ' + props.user.surname
    const classes = styles()
    const theme = useTheme()
    const auth = useAuth()
    const position = props.user && props.user.function ? props.user.function + ' | ' +
        (props.user.organization ? props.user.organization : ('')) : ('')

    return <Card className={classes.root}>
        <CardHeader className={classes.header}
            avatar={<Avatar aria-label={t('client.user.labels.image')} className={classes.avatar}
                alt={username} about={username} onClick={e => !props.maskdata && props.onSelect(props.user, e)}
                src={props.user.image && !props.maskdata ? FileService.url(props.user.image.uri) : ''}>
                {(!props.user.image || props.user.visibility !== Visibility.VISIBLE) && (!props.maskdata ? props.user.name.charAt(0).toUpperCase() : 'A')}
            </Avatar>}
            action={
                props.user.id !== auth.user.id &&
                <IconButton aria-label={t('client.user.labels.settings')}>
                    <StarIcon />
                </IconButton>
            }
            title={<div onClick={e => props.onSelect(props.user, e)}>{props.maskdata ? t('client.user.labels.anonymous') : username}</div>}
            titleTypographyProps={{ variant: 'h5', display: 'block', style: { cursor: 'pointer' } }}
            subheader={
                <div>
                    <div style={{ fontWeight: 'bold' }}>{position}</div>
                    <CardActions disableSpacing className={classes.actions}>
                        {props.event && props.event.features && props.event.features.showAttendeeEmail && props.user.visibility === Visibility.VISIBLE &&
                            <IconButton onClick={e => UserService.onAction(UserAction.MAIL, props.user, e)}
                                className={classes.icon}>
                                <LetterIcon fontsize='small' />
                            </IconButton>}
                        {props.user.socialMedia[SocialMediaName.LINKEDIN]
                            ? <IconButton name={SocialMediaName.LINKEDIN}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <LinkedinIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.FACEBOOK]
                            ? <IconButton name={SocialMediaName.FACEBOOK}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <FacebookIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.INSTAGRAM]
                            ? <IconButton name={SocialMediaName.INSTAGRAM}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <InstagramIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.TWITTER]
                            ? <IconButton name={SocialMediaName.TWITTER}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <TwitterIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.WEBSITE]
                            ? <IconButton name={SocialMediaName.WEBSITE}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <GlobeIcon fontsize='small' />
                            </IconButton>
                            : null
                        }

                        {props.user.id !== auth.user.id && props.user.visibility === Visibility.VISIBLE &&
                            <IconButton onClick={e => UserService.onAction(UserAction.MESSAGE, props.user, e)}
                                className={classes.icon}>
                                <MessageIcon fontsize='small' />
                            </IconButton>}
                        {/*                                 {props.user.id !== auth.user.id && */}
                        {/*                                 <IconButton onClick={e => UserService.onAction(UserAction.CALL, props.user, e)} */}
                        {/*                                             className={classes.icon}> */}
                        {/*                                     <CameraIcon/> */}
                        {/*                                 </IconButton>} */}
                    </CardActions>
                </div>
            }
            subheaderTypographyProps={{
                variant: 'body1', display: 'block',
                style: { marginTop: theme.spacing(0.5) }
            }}
        >
        </CardHeader>
    </Card>
}

UserCard.propTypes = {
    user: PropTypes.object,
    maskdata: PropTypes.bool,
    onSelect: PropTypes.func
}

UserCard.defaultProps = {
    user: {},
    maskdata: false,
    onSelect: function (user) {
    }
}
