import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Letter button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function LetterIcon({className, fontsize}) {
    return (
        <SvgIcon className={`${className}`} width="20" height="15" viewBox="0 0 20 15" fontSize={fontsize}>
            <path id="Path_748" data-name="Path 748"
                  d="M18.125-15H1.875A1.875,1.875,0,0,0,0-13.125v11.25A1.875,1.875,0,0,0,1.875,0h16.25A1.875,1.875,0,0,0,20-1.875v-11.25A1.875,1.875,0,0,0,18.125-15ZM1.875-13.75h16.25a.627.627,0,0,1,.625.625v1.617c-.855.723-2.078,1.719-5.883,4.738C12.207-6.246,10.906-4.984,10-5c-.906.016-2.211-1.246-2.867-1.77-3.8-3.02-5.027-4.016-5.883-4.738v-1.617A.627.627,0,0,1,1.875-13.75Zm16.25,12.5H1.875a.627.627,0,0,1-.625-.625V-9.883c.891.73,2.3,1.859,5.105,4.09C7.156-5.152,8.57-3.742,10-3.75c1.422.012,2.824-1.387,3.645-2.043,2.809-2.23,4.215-3.359,5.105-4.09v8.008A.627.627,0,0,1,18.125-1.25Z"
                  transform="translate(0 15)" fill={theme.palette.primary.var3}/>
        </SvgIcon>
    )
}