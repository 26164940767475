import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {apiFacade} from "../data/ApiFacade";
import {EventType, Visibility} from "../data/Constants";
import {Avatar, Box, CardHeader, makeStyles, Typography} from "@material-ui/core";
import FileService from "../services/FileService";
import {useTranslation} from "react-i18next";
import UserService from "../services/UserService";
import {useAnalytics} from "../data/Analytics";
import {useDispatch} from "react-redux";

export const styles = makeStyles((theme) => ({
    viewersWrap: {
        height: '100%',
        '& .viewersWrapInner': {
            overflow: 'hidden scroll',
            margin: theme.spacing(1, 0),
            "-ms-overflow-style": "none"
        },
        '& .viewersWrapInner::-webkit-scrollbar': {
            display: 'none'
        }
    },
    avatar: {
        backgroundColor: theme.palette.secondary.var1,
    },
    header: {
        padding: theme.spacing(0.5),
        cursor: 'pointer',
        '& .MuiCardHeader-action': {
            marginTop: 0,
            marginLeft: 0
        }
    },
    title: {
        fontWeight: 'bold'
    }
}))

/**
 * Custom component displaying the current room viewers
 * @author dame.gjorgjievski
 */
export default function RoomViewers(props) {

    const classes = styles()
    const [state, setState] = useState({})
    const {t} = useTranslation()
    const analytics = useAnalytics()
    const dispatch = useDispatch()
    const onUserSelect = (user) => (event) => {
        if (user.visibility === Visibility.VISIBLE) {
            UserService.viewProfile(dispatch, analytics, user)
        }
    }

    useEffect(() => {
        console.log("Room viewers mount")
        apiFacade.on(EventType.USER_JOIN, handleUserEvent)
        apiFacade.on(EventType.USER_LEAVE, handleUserEvent)
        apiFacade.on(EventType.USER_UPDATE, handleUserEvent)
        return () => {
            console.log("Room viewers unmount")
            apiFacade.off(EventType.USER_JOIN, handleUserEvent)
            apiFacade.off(EventType.USER_LEAVE, handleUserEvent)
            apiFacade.off(EventType.USER_UPDATE, handleUserEvent)
        }
    }, [])

    const handleUserEvent = (e) => {
        // console.log("RoomViewers user event", e, props.room)
        // set local state to force component render
        setState({})
    }

    const maskOrElse = (user, orelse) => user.visibility !== Visibility.VISIBLE ?
        t('client.user.labels.anonymous') :
        orelse

    return <>
        <Box className={classes.viewersWrap} display="flex" flexDirection="column" justifyContent="space-between">
            <Box display="flex" flexDirection="column" className="viewersWrapInner">
                {props.room.users.map((user, i) =>
                    // for some suspicios reason, user and user.name/surname ends up to be undefined
                    // maybe some cache issues. To avoid show "undifined undefined" we do check before
                    user && user.name && user.surname && (
                        <CardHeader className={classes.header} component="div" key={"viewer" + i}
                                    title={
                                        <Typography variant="subtitle2" component="p"
                                                    style={{maxHeight: 20, overflow: 'hidden'}}>
                                            { maskOrElse(user, user.name + ' ' + user.surname) }
                                        </Typography>
                                    }

                                    titleTypographyProps={{variant: 'title'}}
                                    avatar={<Avatar aria-label={t('client.user.labels.user')} className={classes.avatar}
                                                    src={user && user.image ? FileService.url(user.image.uri) : ''}
                                                    onClick={onUserSelect(user)}>
                                        {maskOrElse(user, user.name).split("")[0].toUpperCase()}
                                    </Avatar>}>
                        </CardHeader>
                    )
                                )}
            </Box>
        </Box>
    </>
}

RoomViewers.propTypes = {
    room: PropTypes.object
}

RoomViewers.defaultProps = {
    room: {}
}
