import { Box, Grid, Link, Paper, Typography, useMediaQuery } from '@material-ui/core'
import draftToHtml from 'draftjs-to-html'
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import FacebookIcon from '../assets/FacebookIcon'
import GlobeSocialIcon from '../assets/GlobeSocialIcon'
import InstagramIcon from '../assets/InstagramIcon'
import LinkedinIcon from '../assets/LinkedinIcon'
import TwitterIcon from '../assets/TwitterIcon'
import FileList from "../components/FileList"
import ProgressDialog from "../components/ProgressDialog"
import UserInfoList from "../components/UserInfoList"
import { useAnalytics } from "../data/Analytics"
import { DEFAULT_EVENT_IMAGE } from "../data/Configuration"
import { SocialMediaName } from '../data/Constants'
import useLanguage from "../data/Language"
import { setEvent } from "../data/Store"
import GAEvent from "../models/GAEvent"
import EventService from "../services/EventService"
import FileService from "../services/FileService"
import SponsorService from "../services/SponsorService"
import UserService from '../services/UserService'
import { styles } from "../styles/EventSponsorViewStyle"
import { theme } from "../styles/Theme"
import WindowUtil from "../utils/WindowUtil"


/**
 * Event sponsor details page, shows general information about the sponsor, related documents and team members
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function EventSponsorView() {

    const { t } = useTranslation()
    const { id, sid } = useParams()
    const classes = styles()
    const events = useSelector(state => state.events)
    const event = useSelector(state => state.event)
    const [sponsor, setSponsor] = useState(null)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const analytics = useAnalytics()
    const { value, l } = useLanguage()
    const laptop = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        let e = (events || []).filter(e => e.id === parseInt(id))[0]
        if (e && !event) {
            dispatch(setEvent(e))
            setLoading(false)
        } else if (!event || event.id !== parseInt(id))
            EventService.load(id).then(result => {
                dispatch(setEvent(result.data))
                setLoading(false)
            })
        else setLoading(false)

    }, [])

    useEffect(() => {
        if (event == null) return
        if (event.id !== parseInt(id)) return // skip for non current event
        // let sponsor = (event.sponsors || []).filter(s => s.id === parseInt(id))
        // setSponsor(sponsor)
        setLoading(true)
        SponsorService.load(sid).then(response => {
            setSponsor(response.data)
            setLoading(false)
        })
    }, [event])

    const onUrlSelect = (url, event) => {
        analytics.event(new GAEvent(GAEvent.Category.NAV, GAEvent.Action.LINK, url))
    }

    const onUserSelect = (user, event) => {
        UserService.viewProfile(dispatch, analytics, user)
    }

    return (
        <main className={classes.root}>
            <ProgressDialog open={loading} />
            {sponsor && <>
                <Grid container spacing={laptop ? 5 : 2}>
                    <Grid item xs={12}>
                        <Paper elevation={0} square className={classes.rootPaper}
                            style={{ backgroundImage: "url(" + (sponsor.media ? FileService.url(sponsor.media.uri) : DEFAULT_EVENT_IMAGE) }}>
                            <Paper elevation={1} p={3} className="logo-box">
                                <img src={sponsor.image ? FileService.url(sponsor.image.uri) : ''} alt="Sponsor Logo" />
                            </Paper>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={laptop ? 10 : 2}>
                            <Grid item xs={12} md={7} lg={8} className={classes.infoItem}>
                                <Typography className="info-sponsor-title" variant="h3" gutterBottom>
                                    {value(sponsor.name)}
                                </Typography>
                                <Typography className={classes.preWrappedText} variant="body1"
                                    dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(value(sponsor.description))) }}>
                                </Typography>
                                {sponsor.video &&
                                    <Box className={classes.videoBox}>
                                        <video controls="controls" className="video-stream"
                                            src={FileService.url(sponsor.video.uri)} />
                                    </Box>}
                            </Grid>
                            <Grid item xs={12} md={5} lg={4}>
                                {sponsor.users && sponsor.users.length > 0 &&
                                    <Box>
                                        <Typography className={classes.sponsorTeamTitle} variant="h5" gutterBottom>{t('client.sponsor.labels.team')}</Typography>
                                        <div className={classes.teamBox}>
                                            <UserInfoList onSelect={onUserSelect} users={sponsor.users} />
                                        </div>
                                    </Box>}
                                {sponsor.files && sponsor.files.length > 0 &&
                                    <Box className={classes.infoBottomItem}>
                                        <Typography className="doc-title" variant="h5" gutterBottom>{t('client.sponsor.labels.documents')}</Typography>
                                        <FileList files={sponsor.files} />
                                    </Box>}
                                {<Box className={classes.sponsorLink}>
                                    <Typography className={classes.socialLabel} gutterBottom variant="h5">{t('client.sponsor.labels.website')}</Typography>
                                    <Box className="social-icons">
                                        {sponsor.url && <Link href={WindowUtil.normalize(sponsor.url)}
                                            rel="noreferrer" target="_blank"
                                            onClick={e => onUrlSelect(sponsor.url, e)}>
                                            <GlobeSocialIcon />
                                        </Link>}
                                        {sponsor.socialMedia[SocialMediaName.LINKEDIN]
                                            ? <Link href={WindowUtil.normalize(sponsor.socialMedia[SocialMediaName.LINKEDIN])}
                                                rel="noreferrer" target="_blank">
                                                <LinkedinIcon fontsize='small' />
                                            </Link>
                                            : null}
                                        {sponsor.socialMedia[SocialMediaName.FACEBOOK]
                                            ? <Link href={WindowUtil.normalize(sponsor.socialMedia[SocialMediaName.FACEBOOK])}
                                                rel="noreferrer" target="_blank">
                                                <FacebookIcon fontsize='small' />
                                            </Link>
                                            : null}
                                        {sponsor.socialMedia[SocialMediaName.INSTAGRAM]
                                            ? <Link href={WindowUtil.normalize(sponsor.socialMedia[SocialMediaName.INSTAGRAM])}
                                                rel="noreferrer" target="_blank">
                                                <InstagramIcon fontsize='small' />
                                            </Link>
                                            : null}
                                        {sponsor.socialMedia[SocialMediaName.TWITTER]
                                            ? <Link href={WindowUtil.normalize(sponsor.socialMedia[SocialMediaName.TWITTER])}
                                                rel="noreferrer" target="_blank">
                                                <TwitterIcon fontsize='small' />
                                            </Link>
                                            : null}
                                    </Box>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </main>)
}
