import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { theme } from '../styles/Theme';

/*
 * Linkedin icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function LinkedinIcon({ fontsize }) {
    return (
        <SvgIcon viewBox="0 0 19.25 19.25" fontSize={fontsize}>
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main} />
                    <stop offset="1" stopColor={theme.palette.primary.var3} />
                </linearGradient>
            </defs>
            <path id="Path_930" data-name="Path 930" d="M4.309,0V-12.852H.318V0Zm-2-14.605a2.331,2.331,0,0,0,2.311-2.333A2.312,2.312,0,0,0,2.311-19.25,2.312,2.312,0,0,0,0-16.938,2.33,2.33,0,0,0,2.311-14.605ZM19.246,0h0V-7.06c0-3.455-.743-6.114-4.782-6.114A4.194,4.194,0,0,0,10.692-11.1h-.056v-1.753H6.809V0H10.8V-6.364c0-1.676.318-3.3,2.393-3.3,2.045,0,2.075,1.912,2.075,3.4V0Z"
                transform="translate(0 19.25)" fill={theme.palette.primary.var3} />

        </SvgIcon>
    )
}
