import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Replay menu icon
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function SponsorsIcon() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32">
            <g id="Group_282" data-name="Group 282">
                <path id="Path_149" data-name="Path 149"
                      d="M220.069,354.181a.619.619,0,0,0-.444-.181.645.645,0,0,0-.444.181.619.619,0,0,0-.181.444.657.657,0,0,0,.181.444.619.619,0,0,0,.444.181.645.645,0,0,0,.444-.181.619.619,0,0,0,.181-.444A.645.645,0,0,0,220.069,354.181Z"
                      transform="translate(-205.313 -331.875)" fill={theme.palette.quaternary.var5}/>
                <path id="Path_150" data-name="Path 150"
                      d="M28,67.281a.627.627,0,0,0-.625.625v2.75H16.625V69.112a4.667,4.667,0,0,0-4.088-4.625,3.239,3.239,0,0,0,.725-2.05V58.044a.627.627,0,0,0-.625-.625H9.369a2.642,2.642,0,0,0-2.637,2.638v2.387a3.262,3.262,0,0,0,.725,2.05,4.671,4.671,0,0,0-4.088,4.625v1.538H1.25V55.637a.488.488,0,0,1,.487-.487H14.731a.625.625,0,1,0,0-1.25H1.737A1.746,1.746,0,0,0,0,55.637V74.169a1.742,1.742,0,0,0,1.737,1.738H11.25v2.887a3.137,3.137,0,0,0-2.812,3.113.627.627,0,0,0,.625.625h10.5a.627.627,0,0,0,.625-.625,3.132,3.132,0,0,0-2.812-3.113V75.906h9.512a1.742,1.742,0,0,0,1.738-1.738V67.906A.627.627,0,0,0,28,67.281ZM7.987,60.044a1.388,1.388,0,0,1,1.388-1.387h2.644v1a.9.9,0,0,1-.9.9H7.988v-.513Zm0,1.763h3.131a2.084,2.084,0,0,0,.9-.2v.825h0a2.012,2.012,0,0,1-4.025,0C7.987,62.431,7.987,61.806,7.987,61.806ZM10.731,65.7,10,66.425l-.731-.731,1.462.006ZM4.625,69.112a3.414,3.414,0,0,1,2.912-3.375l2.019,2.019a.614.614,0,0,0,.444.181.64.64,0,0,0,.444-.181l2.019-2.019a3.419,3.419,0,0,1,2.912,3.375v1.544H4.625ZM18.831,81.281H9.794a1.883,1.883,0,0,1,1.775-1.256h5.487A1.883,1.883,0,0,1,18.831,81.281ZM12.5,78.775V75.906h3.625v2.869Zm14.875-4.606a.488.488,0,0,1-.488.488H1.737a.488.488,0,0,1-.487-.488V71.906H27.375Z"
                      transform="translate(0 -50.531)" fill={theme.palette.quaternary.var5}/>
                <path id="Path_151" data-name="Path 151"
                      d="M439.069,230.581a.618.618,0,0,0-.444-.181.646.646,0,0,0-.444.181.619.619,0,0,0-.181.444.657.657,0,0,0,.181.444.619.619,0,0,0,.444.181.645.645,0,0,0,.444-.181.619.619,0,0,0,.181-.444A.634.634,0,0,0,439.069,230.581Z"
                      transform="translate(-410.625 -216)" fill={theme.palette.quaternary.var5}/>
                <path id="Path_152" data-name="Path 152"
                      d="M280.125,0h-9A3.126,3.126,0,0,0,268,3.125V9.813a3.126,3.126,0,0,0,2.769,3.106v1.625a.635.635,0,0,0,.625.625.629.629,0,0,0,.444-.181l2.044-2.044h6.244a3.126,3.126,0,0,0,3.125-3.125V3.125A3.126,3.126,0,0,0,280.125,0ZM282,9.813a1.881,1.881,0,0,1-1.875,1.875h-6.5a.645.645,0,0,0-.444.181l-1.163,1.163v-.719a.627.627,0,0,0-.625-.625h-.269a1.881,1.881,0,0,1-1.875-1.875V3.125a1.881,1.881,0,0,1,1.875-1.875h9A1.881,1.881,0,0,1,282,3.125Z"
                      transform="translate(-251.25)" fill={theme.palette.quaternary.var5}/>
                <path id="Path_153" data-name="Path 153"
                      d="M361.969,38.331a.931.931,0,1,1,.931-.931h.931a1.864,1.864,0,0,0-1.4-1.8v-1H361.5v1a1.864,1.864,0,0,0,.469,3.669.931.931,0,1,1-.931.931h-.931a1.864,1.864,0,0,0,1.4,1.8v1h.931V42a1.864,1.864,0,0,0-.469-3.669Z"
                      transform="translate(-337.594 -32.438)" fill={theme.palette.quaternary.var5}/>
            </g>
        </SvgIcon>
    )
}