import React, {useState} from "react";
import MaterialTable from "material-table";
import {tableIcons} from "../styles/TableIcons";
import Alert from "@material-ui/lab/Alert";
import {useHistory, useParams} from "react-router-dom";
import {AddBox, Edit} from "@material-ui/icons";
import PageService from "../services/PageService";
import {Container} from "@material-ui/core";
import useLanguage from "../data/Language";
import {useTranslation} from "react-i18next";
import {Path} from "../data/Constants";
import CompUtil from "../utils/CompUtil";

/**
 * The page listing component
 * @returns {JSX.Element}
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 */
export default function PagesListView() {

    const [errors, setErrors] = useState([])
    const history = useHistory()
    const {value} = useLanguage()
    const {t} = useTranslation()
    const {id} = useParams()
    const columns = [
        {title: t("admin.default.fields.id"), field: "id", hidden: true},
        {title: t("admin.default.fields.name"), field: "name", render: rowData => value(rowData.name, null, 30)},
        {title: t("admin.page.fields.label"), field: "label", render: rowData => value(rowData.label, null, 30)},
        {
            title: t("admin.default.fields.event"),
            field: "event.name",
            render: rowData => value(rowData.event.name, null, 30)
        },
    ]

    const handleRowDelete = (oldData, resolve) => {
        PageService.delete(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
                clearErrors()
                resolve()
            })
    }

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container maxWidth="lg" style={{width: '100%'}}>
            <MaterialTable
                title={t("admin.page.title")}
                columns={columns}
                icons={tableIcons}
                data={query => {
                    return new Promise((resolve, reject) => {
                        PageService.search(query)
                            .then(response => {
                                resolve({
                                    data: response.data.result,
                                    page: query.page,
                                    totalCount: response.data.totalCount,
                                })
                            })
                            .catch(error => {
                                console.log("got error", error, error.response)
                                setErrors([t("admin.default.errors.data-load")])
                            })
                    })
                }}
                actions={[
                    {
                        icon: () => <AddBox/>,
                        tooltip: t("admin.default.actions.add"),
                        position: "toolbar",
                        onClick: () => {
                            history.push(Path.ADMIN_PAGE_EDIT.replace(":id", "0"))
                        }
                    },
                    {
                        icon: () => <Edit/>,
                        tooltip: t("admin.default.actions.edit"),
                        onClick: (event, rowData) => {
                            history.push(Path.ADMIN_PAGE_EDIT.replace(":id", rowData.id))
                        }
                    }
                ]}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        })
                }}
                options={{
                    actionsColumnIndex: -1
                }}
                localization={CompUtil.localizeTable(t)}
            />
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>
                }
            </div>
        </Container>
    )
}
