/**
 * Function invocation utilities
 * @author dame.gjorgjievski
 */
export default class FuncUtil {

    /**
     * Debounce a function call after specified period of time. First function execution is after specified delay of time,
     * each call increases the delay offset
     * @param func function to debounce
     * @param interval debounce interval
     */
    static debounce(func: Function, interval: Number) {
        let debounce
        return function () {
            const ctx = this, args = arguments
            clearTimeout(debounce)
            debounce = setTimeout(func.apply(ctx, args), interval)
        }
    }

    /**
     * Throttles a function execution over specified period of time. Function fires then delays next execution on specified period
     * @param func function to throttle
     * @param interval throttling interval
     */
    static throttle(func: Function, interval: Number) {
        let throttle
        return function() {
            const ctx = this, args = arguments
            if(!throttle) {
                func.apply(ctx, args)
                throttle = setTimeout(() => {
                    func.apply(ctx, args)
                    clearTimeout(throttle)
                }, interval)
            }
        }
    }

}