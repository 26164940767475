import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import FileService from "../services/FileService";
import LinearProgressBar from "./LinearProgressBar";

const styles = makeStyles((theme) => ({
    root: {},
    file: {
        width: '100%',
        paddingTop: theme.spacing(1)
    },
    clear: {
        float: 'right',
        cursor: 'pointer'
    },
    wrapper: {
        display: 'block'
    },
    circular: {
        padding: '5px',
        display: 'flex',
        justifyContent: 'center'
    },
    error: {
        color: '#f44336'
    },
    errorWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '5px'
    }
}));
/**
 * File upload component
 * @param props component properties
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function FileUpload(props) {

    const classes = styles()
    const [file, setFile] = useState({
        file: null,
        progress: 0,
        name: ''
    })
    const [loading, setLoading] = useState(false)
    const [fileError, setFileError] = useState()
    const MAX_SIZE = 500_000_000

    const selectFile = (event) => {
        let data = event.target.files[0]
        if (data && data.size > MAX_SIZE) {
            setFileError(`File size should not exceed ${MAX_SIZE / 1_000_000} MB`)
            setFile({ ...file, name: '' })
        } else {
            setFileError(null)
            setLoading(true)
            let id = (props.update || false) && props.files && props.files.length > 0 ? props.files[0].id : null
            FileService.upload(data, (event) => {
                setLoading(false)
                let progress = Math.round((100 * event.loaded) / event.total)
                setFile({ progress: progress, file: data, name: data.name })
                if (progress === 100) setLoading(true)
            }, id).then(response => {
                props.onComplete(response)
                setLoading(false)
            }).catch(e => {
                console.error(e)
                setLoading(false)
            })
        }
    }

    return (
        <Fragment>
            {file.progress !== 0 && file.progress !== 100 ?
                <LinearProgressBar value={file.progress} /> : null}
            <div className={classes.wrapper}>
                <Button
                    fullWidth
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                >
                    {props.label}
                    <input type="file" key={file.name || ''} hidden accept={props.accept || '*'}
                        onChange={selectFile} />
                </Button>
            </div>

            {fileError
                ? <div className={classes.errorWrap}>
                    <div className={classes.error}>{fileError}</div>
                    <ClearIcon className={classes.clear} onClick={() => {
                        setFileError(null)
                    }} />
                </div>
                : <>
                    {loading
                        ? <div className={classes.circular}>
                            <CircularProgress size={15} />
                        </div>
                        : <div className={classes.wrapper}>
                            {props.files ? props.files.map((object, i) =>
                                <div className={classes.file} key={i}>{object.name}
                                    <ClearIcon className={classes.clear} onClick={() => {
                                        setFile({ ...file, name: '' })
                                        props.onRemove(object)
                                    }} /></div>) : null}
                        </div>
                    }
                </>
            }
        </Fragment>
    );
}

FileUpload.propTypes = {
    onComplete: PropTypes.func,
    onRemove: PropTypes.func,
    files: PropTypes.array,
    accept: PropTypes.string,
    update: PropTypes.bool,
    label: PropTypes.string,
};

FileUpload.defaultProps = {
    files: [],
    accept: "*",
    update: false,
    label: "Upload File"
}