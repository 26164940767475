import {Box, useTheme} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu";

/**
 * Page menu icon
 * @author petar.todorovski
 */
export default function PageIcon() {

    const theme = useTheme()

    return <Box display="flex" justifyContent="center" alignItems="center"
                style={{border: `1px solid ${theme.palette.quaternary.var5}`}}>
        <MenuIcon style={{color: theme.palette.quaternary.var5}}/>
    </Box>
}