/**
 * Browser window specific utility methods collection
 * @author dame.gjorgjievski
 */
export default class WindowUtil {

    /**
     * Opens a new window for the specified url
     * @param url destination url to open
     */
    static open(url: String) {
        const win = window.open(WindowUtil.normalize(url), '_blank', 'noopener,noreferrer')
        if(win) win.opener = null
    }

    /**
     * Normalize a given URL, checks and if not includes hypertext protocol on an URL
     * @param url non-normalized url
     * @return normalized url
     */
    static normalize(url: String): String {
        if(!url) return url
        if(!url.startsWith("http")) url = `https://${url}`
        return url
    }

}