import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    Paper,
    TextField,
    Typography,
    useTheme
} from '@material-ui/core'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import RoundTableBox from '../components/RoundTableBox'
import {Close} from "@material-ui/icons";
import {createStyles} from "@material-ui/core/styles";
import useEventContext from "../data/EventContext";
import {useParams} from "react-router-dom";
import ProgressDialog from "../components/ProgressDialog";
import EventService from "../services/EventService";
import {useAuth} from "../components/ProvideAuth";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setRoom} from "../data/Store";
import {SessionType} from "../data/Constants";
import useLanguage from "../data/Language";

export const styles = makeStyles((theme) => createStyles({
    root: {
        '& .root-description': {
            width: '100%',
            maxWidth: theme.spacing(127),
            padding: theme.spacing(5, 0),
            color: theme.palette.primary.main
        }
    },
    rootTitleWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .root-button': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    paper: {
        backgroundColor: 'transparent',
        width: 'auto',
        display: 'block',
        '& .root-title': {
            color: theme.palette.tertiary.var2
        }
    },
    grid: {
        marginTop: theme.spacing(1),
        flexGrow: 1
    }
}))

/**
 * Event round table rooms
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
export default function EventRoundTablesView() {

    const classes = styles()
    const auth = useAuth()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {event, loadEvent} = useEventContext()
    const {id} = useParams()
    const {t} = useTranslation()
    const {value} = useLanguage()

    useEffect(() => {
        if (!event) setLoading(true)
        loadEvent(parseInt(id)).then(() => setLoading(false))
        console.log("event round tables mount")
    }, [])

    useEffect(() => {
        if (!event) return
        if (event.room) dispatch(setRoom(event.room))
    }, [event])

    useLayoutEffect(() => () => {
        dispatch(setRoom(null))
        console.log("event round tables unmount")
    }, [])

    console.log(event, 'tukaa')
    return (
        <main className={classes.root}>
            <ProgressDialog open={loading}/>
            {event && EventService.hasAccess(auth.user, event) && <>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h3" className="root-title">
                        {t("client.event.labels.networking-title")}
                    </Typography>

                    {/* Event tables room description */}
                    <Typography variant="h5"
                                className="root-description">{t('client.event.labels.networking-description')}</Typography>

                    {/* Grid rooms */}
                    <Grid container spacing={4} className={classes.grid}>
                        {event && event.sessions.filter(s => SessionType.NETWORKING === s.type)
                            .sort((a, b) => value(a.name).localeCompare(value(b.name))).map((session, i) =>
                                <Grid item key={"session" + i} xs={12} sm={6} lg={4}>
                                    <RoundTableBox session={session}/>
                                </Grid>
                            )}
                    </Grid>
                </Paper>
                {/*<RoundTableCreateDialog open={open} onClose={() => setOpen(false)}/>*/}
            </>}
        </main>
    )
}

const dialogStyles = makeStyles((theme) => ({
    rootDialog: {
        zIndex: '10000 !important',
        backdropFilter: 'blur(1px)'
    },
    dialogTitle: {
        textAlign: 'right'
    },
    dialogContent: {
        width: '100%',
        maxWidth: theme.spacing(70),
        textAlign: 'center',
        alignSelf: 'center',
        '& .dialog-button-create': {
            backgroundColor: theme.palette.secondary.main,
            marginTop: theme.spacing(5)
        },
        '& .dialog-input-label': {
            color: theme.palette.primary.main,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(1)
        }
    }
}))

const RoundTableCreateDialog = (props) => {

    const classes = dialogStyles()
    const theme = useTheme()
    return (
        <Dialog
            className={classes.rootDialog}
            open={props.open}
            disableBackdropClick
            disableEscapeKeyDown
            hideBackdrop
            fullWidth
            PaperProps={{
                style: {
                    backgroundColor: theme.palette.common.white,
                    opacity: 0.85,
                    backdropFilter: 'blur(60px)',
                    boxShadow: '0px 7px 17px 17px rgba(170,170,170,0.1)',
                    height: '100%',
                    maxWidth: theme.spacing(100),
                    maxHeight: theme.spacing(52.5)
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <IconButton onClick={props.onClose}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h5">Create a new Roundtable Room</Typography>
                <InputLabel className="dialog-input-label">
                    Add a title to your room:
                </InputLabel>
                <TextField fullWidth variant="outlined" type="text" notched={false} InputLabelProps={{
                    shrink: true,
                }}/>
                <Button
                    className="dialog-button-create"
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={props.onClose}
                    disableElevation>
                    CREATE YOUR ROOM
                </Button>
            </DialogContent>
        </Dialog>
    )
}
