import './App.css'
import React, { useEffect, useState } from "react"
import 'fontsource-roboto'
import axios from "axios";
import AuthService from "./services/AuthService";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useAnalytics } from "./data/Analytics";
import { Callback, makeAuthenticator, makeUserManager } from "react-oidc";
import GAEvent from "./models/GAEvent";
import AuthView from "./views/AuthView";
import { Path } from "./data/Constants";
import EventIntroView from "./views/EventIntroView";
import LoginFAView from "./views/LoginFAView";
import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_LOGOUT_URL, OIDC_REDIRECT_URL } from "./data/Configuration";
import DateUtil from './utils/DateUtil';

/**
 * Main application file
 * @author dame.gjorgjievski
 */
export default function App() {


    const userManager = makeUserManager({
        authority: OIDC_AUTHORITY,
        client_id: OIDC_CLIENT_ID,
        redirect_uri: OIDC_REDIRECT_URL,
        scope: "openid email",
        response_type: "id_token token",
        post_logout_redirect_uri: OIDC_LOGOUT_URL
    })

    const AppWithAuth = makeAuthenticator({
        userManager: userManager,
        signinArgs: {
            state: {
                render: Path.EVENTS
            }
        }
    })(AuthView)

    const history = useHistory()
    const analytics = useAnalytics()
    let [user, setUser] = useState();

    useEffect(() => {
        if (!history) return
        analytics.set({ page: history.location.pathname })
        analytics.send(history.location.pathname)
        return history.listen(location => {
            console.debug("location", location)
            analytics.set({ page: location.pathname })
            analytics.send(location.pathname)
        })
    }, [history])
    useEffect(() => {
        const u = sessionStorage.getItem(`oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`)
        if (u != null) {
            axios.interceptors.request.use(function (config) {
                config.headers.Authorization = "Bearer " + JSON.parse(u).access_token;
                return config;
            }, function (error) {
                return Promise.reject(error);
            });
        }
    }, [])

    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, PUT, POST, DELETE, PATCH, OPTIONS'
    axios.defaults.headers.common['Content-Type'] = 'application/json'

    const hasValidUser = () => localStorage.getItem("user") != null && sessionStorage.getItem(`oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`) != null

    return <Switch>
        <Route path={Path.EVENT_INTRO}>
            <EventIntroView />
        </Route>
        <Route path="/login" render={({ location }) => hasValidUser() ? (
            <Redirect
                to={{
                    pathname: Path.EVENTS,
                    state: { from: location }
                }}
            />
        ) : (
            <LoginFAView userManager={userManager} />
        )}>
        </Route>
        <Route
            path="/callback"
            render={routeProps => (
                <Callback
                    onSuccess={u => {
                        axios.interceptors.request.use(function (config) {
                            config.headers.Authorization = "Bearer " + u.access_token;
                            return config;
                        }, function (error) {
                            return Promise.reject(error);
                        });
                        AuthService.authenticateFA().then(response => {
                            AuthService.user(response.data)
                            setUser(response.data)
                            analytics.set({ userId: response.data.email })
                            analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.LOGIN,
                                `name: ${response.data.name} surname: ${response.data.surname} date: ${DateUtil.getDateTimeNow()}`, response.data.id))
                        })
                        routeProps.history.push('/events')
                    }}
                    onError={error => {
                        console.error(error)
                    }}
                    userManager={userManager}
                />
            )}
        />
        <AppWithAuth />
    </Switch>
}