import { Box, ListItemText, Slide, Tooltip, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Apps, GroupAdd, Pages, Poll, Subject } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ChatIcon from "@material-ui/icons/Chat";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from "@material-ui/icons/People";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import AttendeesIcon from "../assets/AttendeesIcon";
import LobbyIcon from "../assets/LobbyIcon";
import PageIcon from "../assets/PageIcon";
import ReplayIcon from "../assets/ReplayIcon";
import RoundTableIcon from "../assets/RoundTableIcon";
import SessionsIcon from "../assets/SessionsIcon";
import SponsorsIcon from "../assets/SponsorsIcon";
import { OIDC_AUTHORITY, OIDC_CLIENT_ID } from '../data/Configuration';
import { Path, Role, SessionType } from "../data/Constants";
import useLanguage from "../data/Language";
import FileService from "../services/FileService";
import { styles } from "../styles/ApplicationViewStyles";
import { menuStyles } from "../styles/MenuStyle";
import { theme } from "../styles/Theme";
import DateUtil from "../utils/DateUtil";
import ChatBox from "./ChatBox";
import LanguageSwitcher from "./LanguageSwitcher";
import Notifications from "./Notifications";
import { useAuth } from "./ProvideAuth";

/**
 * Application bar & menu
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function ApplicationMenu(props) {

    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const auth = useAuth()
    const classes = styles()
    const menuClasses = menuStyles()
    const [open, setOpen] = useState(true)
    const [anchor, setAnchor] = useState(null);
    const event = useSelector(state => state.event)
    const { t } = useTranslation()
    const { value } = useLanguage()
    const openMenu = Boolean(anchor);

    const onDrawer = () => {
        setOpen(!open);
    }

    const onSignOut = () => {
        auth.signOut()
        window.open(`${OIDC_AUTHORITY}/oauth2/logout?client_id=${OIDC_CLIENT_ID}`, '_self')
    }

    const onMenuToggle = (toggle) => (event) => {
        if (toggle) setAnchor(event.currentTarget);
        else setAnchor(null)
    }

    return <Fragment>
        <AppBar position="absolute" className={classes.appBar} elevation={0}>
            <Toolbar className={classes.toolbar}>
                <IconButton edge="start" color="inherit" onClick={onDrawer}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}><MenuIcon /></IconButton>
                <Box className={classes.navigation}>
                    {event && event.logo ?
                        <NavLink to={Path.EVENT_LOBBY.replace(":id", event.id)} className={classes.adminMenuItem}>
                            <Box className={classes.logoWrap}>
                                <img src={FileService.url(event.logo.uri)} alt="Event Logo" />
                            </Box>
                        </NavLink> : <NavLink to={Path.EVENTS} className={classes.adminMenuItem}>
                            <Typography component="h1" variant="h6" noWrap
                                className={classes.title}>{t('client.menu.event')}</Typography>
                            <Typography component="h1" variant="h6" noWrap
                                className={classes.title}>{t('client.menu.room')}</Typography>
                        </NavLink>}
                    {auth.user && auth.hasRole(Role.ADMIN, Role.SUPER) &&
                        <Box className={classes.adminMenu}>
                            {isDesktop ? (
                                <>
                                    <NavLink to={Path.ADMIN_EVENTS} className={classes.adminMenuItem}>
                                        <DashboardIcon />
                                        <ListItemText disableTypography
                                            primary={<Typography variant="button" data-cy="events-menu-btn"
                                                component="span">{t('admin.menu.events')}</Typography>} />
                                    </NavLink>
                                    <NavLink to={Path.ADMIN_SESSIONS} className={classes.adminMenuItem}>
                                        <Apps />
                                        <ListItemText disableTypography primary={<Typography variant="button"
                                            component="span">{t('admin.menu.sessions')}</Typography>} />
                                    </NavLink>
                                    <NavLink to={Path.ADMIN_SPONSORS} className={classes.adminMenuItem}>
                                        <Poll />
                                        <ListItemText disableTypography primary={<Typography variant="button"
                                            component="span">{t('admin.menu.sponsors')}</Typography>} />
                                    </NavLink>
                                    <NavLink to={Path.ADMIN_POLLS} className={classes.adminMenuItem}>
                                        <Subject />
                                        <ListItemText disableTypography
                                            primary={<Typography variant="button"
                                                component="span">{t('admin.menu.polls')}</Typography>} />
                                    </NavLink>
                                    <NavLink to={Path.ADMIN_PAGES} className={classes.adminMenuItem}>
                                        <Pages />
                                        <ListItemText disableTypography primary={<Typography variant="button"
                                            component="span">{t('admin.menu.pages')}</Typography>} />
                                    </NavLink>
                                    {auth.hasRole(Role.SUPER) && <>
                                        <NavLink to={Path.ADMIN_GROUPS} className={classes.adminMenuItem}>
                                            <GroupAdd />
                                            <ListItemText disableTypography primary={<Typography variant="button"
                                                component="span">{t('admin.menu.groups')}</Typography>} />
                                        </NavLink>
                                        <NavLink to={Path.ADMIN_USERS} className={classes.adminMenuItem}>
                                            <PeopleIcon />
                                            <ListItemText disableTypography
                                                primary={<Typography variant="button"
                                                    component="span">{t('admin.menu.users')}</Typography>} />
                                        </NavLink>
                                    </>}
                                </>
                            ) : (
                                <div className={classes.root}>
                                    <Box position="static" style={{
                                        background: theme.palette.common.white,
                                        color: theme.palette.secondary.main,
                                        boxShadow: "none"
                                    }}>
                                        {/* <Toolbar> */}
                                        <IconButton color="inherit" disableFocusRipple aria-label="menu"
                                            onClick={onMenuToggle(true)}>
                                            <MenuIcon />
                                        </IconButton>

                                        <>
                                            <Menu id="menu-appbar" anchorEl={anchor}
                                                anchorOrigin={{ vertical: "top", horizontal: "right", }} keepMounted
                                                transformOrigin={{ vertical: "top", horizontal: "right", }}
                                                open={openMenu} onClose={onMenuToggle(false)}>
                                                <MenuItem onClick={onMenuToggle(false)}>
                                                    <NavLink to={Path.ADMIN_EVENTS} className={classes.adminMenuItem}>
                                                        <DashboardIcon style={{ color: theme.palette.secondary.main }} />
                                                        <ListItemText disableTypography primary={
                                                            <Typography variant="button" component="span"
                                                                style={{ color: theme.palette.secondary.main, }}>
                                                                {t("admin.menu.events")}
                                                            </Typography>}
                                                        />
                                                    </NavLink>
                                                </MenuItem>
                                                <MenuItem onClick={onMenuToggle(false)}>
                                                    <NavLink to={Path.ADMIN_SESSIONS} className={classes.adminMenuItem}>
                                                        <Apps style={{ color: theme.palette.secondary.main }} />
                                                        <ListItemText disableTypography primary={
                                                            <Typography variant="button" component="span"
                                                                style={{ color: theme.palette.secondary.main, }}>
                                                                {t("admin.menu.sessions")}
                                                            </Typography>} />
                                                    </NavLink>
                                                </MenuItem>
                                                <MenuItem onClick={onMenuToggle(false)}>
                                                    <NavLink to={Path.ADMIN_SPONSORS} className={classes.adminMenuItem}>
                                                        <Poll style={{ color: theme.palette.secondary.main }} />
                                                        <ListItemText disableTypography primary={
                                                            <Typography variant="button" component="span"
                                                                style={{ color: theme.palette.secondary.main }}>
                                                                {t("admin.menu.sponsors")}
                                                            </Typography>
                                                        }
                                                        />
                                                    </NavLink>
                                                </MenuItem>
                                                <MenuItem onClick={onMenuToggle(false)}>
                                                    <NavLink to={Path.ADMIN_POLLS} className={classes.adminMenuItem}>
                                                        <Subject style={{ color: theme.palette.secondary.main }} />
                                                        <ListItemText disableTypography primary={
                                                            <Typography variant="button" component="span"
                                                                style={{ color: theme.palette.secondary.main, }}>
                                                                {t("admin.menu.polls")}
                                                            </Typography>
                                                        }
                                                        />
                                                    </NavLink>
                                                </MenuItem>
                                                {auth.hasRole(Role.SUPER) && <div>
                                                    <MenuItem onClick={onMenuToggle(false)}>
                                                        <NavLink to={Path.ADMIN_GROUPS}
                                                            className={classes.adminMenuItem}>
                                                            <GroupAdd style={{ color: theme.palette.secondary.main }} />
                                                            <ListItemText disableTypography primary={
                                                                <Typography variant="button" component="span"
                                                                    style={{ color: theme.palette.secondary.main }}>
                                                                    {t("admin.menu.groups")}
                                                                </Typography>
                                                            } />
                                                        </NavLink>
                                                    </MenuItem>
                                                    <MenuItem onClick={onMenuToggle(false)}>
                                                        <NavLink to={Path.ADMIN_USERS}
                                                            className={classes.adminMenuItem}>
                                                            <PeopleIcon style={{ color: theme.palette.secondary.main }} />
                                                            <ListItemText disableTypography primary={
                                                                <Typography variant="button" component="span"
                                                                    style={{ color: theme.palette.secondary.main }}>
                                                                    {t("admin.menu.users")}
                                                                </Typography>
                                                            }
                                                            />
                                                        </NavLink>
                                                    </MenuItem>
                                                </div>}
                                            </Menu>
                                        </>
                                        {/* </Toolbar> */}
                                    </Box>
                                </div>)}
                        </Box>}
                    {auth.user && auth.hasRole(Role.SPONSOR) &&
                        <Box className={classes.adminMenu}>
                            <NavLink to={Path.SPONSORS} className={classes.adminMenuItem}>
                                <Poll />
                                <ListItemText disableTypography primary={
                                    <Typography variant="button"
                                        component="span">{t('admin.menu.sponsors')}
                                    </Typography>} />
                            </NavLink>
                        </Box>}
                </Box>
                {auth.user ? (
                    <Fragment>
                        <LanguageSwitcher />
                        <Notifications />
                        <Tooltip
                            className={classes.deviceTriggerChat}
                            title={t("client.menu.chat")}
                            placement="bottom"
                            TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                            <ChatIcon onClick={props.onOpenChat} />
                        </Tooltip>
                        <Link to={Path.PROFILE} className={classes.avatarIcon}>
                            <Tooltip title={t('client.menu.profile')} placement="bottom"
                                TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                                <Avatar alt={auth.user.className}
                                    src={auth.user.image ? FileService.url(auth.user.image.uri) : ''}>
                                    {!auth.user.image && auth.user.name.split("")[0].toUpperCase()}</Avatar>
                            </Tooltip>
                        </Link>
                        <Tooltip title={t('client.menu.log-out')} placement="bottom"
                            TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                            <ExitToAppIcon className={classes.exitIcon} onClick={onSignOut} data-cy="logout-btn" />
                        </Tooltip>
                    </Fragment>
                ) : (
                    <Fragment />
                )}
            </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}
            classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}>
            <div className={classes.toolbarIcon}>
                <IconButton onClick={onDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            {auth.user ? (
                <List>
                    {event && <div className={menuClasses.listWrap}>
                        <NavLink to={{ pathname: Path.EVENT_LOBBY.replace(":id", event.id) }}>
                            <LobbyIcon />
                            <ListItemText disableTypography className={classes.wrappedText}
                                primary={<Typography variant="button"
                                    component="span">{t('client.menu.lobby')}</Typography>} />
                        </NavLink>
                        {(event.sessions || []).filter(s => SessionType.NETWORKING !== s.type).length > 0 &&
                            <NavLink to={{ pathname: Path.EVENT_SESSIONS.replace(":id", event.id) }}>
                                <SessionsIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button"
                                        component="span">{t('client.menu.sessions')}</Typography>} />
                            </NavLink>}
                        {(event.pages || []).length > 0 &&
                            <NavLink
                                to={{ pathname: Path.EVENT_PAGE.replace(":id", event.id).replace(":pid", event.pages[0].id) }}>
                                <PageIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button" component="span">
                                        {value(event.pages[0].label)}</Typography>} />
                            </NavLink>}
                        {(event.sessions || []).filter(s => SessionType.NETWORKING === s.type).length > 0 &&
                            <NavLink to={{ pathname: Path.EVENT_ROUND_TABLES.replace(":id", event.id) }}>
                                <RoundTableIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button" component="span">
                                        {t('client.menu.networking')}</Typography>} />
                            </NavLink>}
                        {(event.sessions || []).filter(s => new Date(s.timeEnd) <= DateUtil.utc()).length > 0 &&
                            <NavLink to={{ pathname: Path.EVENT_REPLAY.replace(":id", event.id) }}>
                                <ReplayIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button"
                                        component="span">{t('client.menu.replay')}</Typography>} />
                            </NavLink>}
                        {(event.groups || []).filter(g => g.users.length > 0).length > 0 &&
                            <NavLink to={{ pathname: Path.EVENT_ATTENDEES.replace(":id", event.id) }}>
                                <AttendeesIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button"
                                        component="span">{t('client.menu.attendees')}</Typography>} />
                            </NavLink>}
                        {(event.sponsors || []).length > 0 &&
                            <NavLink to={{ pathname: Path.EVENT_SPONSORS.replace(":id", event.id) }}>
                                <SponsorsIcon />
                                <ListItemText disableTypography className={classes.wrappedText}
                                    primary={<Typography variant="button"
                                        component="span">{t('client.menu.sponsors')}</Typography>} />
                            </NavLink>}
                    </div>}
                </List>
            ) : (
                <List>
                    <div className={menuClasses.listWrap}>
                        <NavLink to={Path.LOGIN}>
                            <AssignmentIcon />
                            <ListItemText disableTypography
                                primary={<Typography variant="button"
                                    component="span">{t('client.menu.login')}</Typography>} />
                        </NavLink>
                        <NavLink to={Path.REGISTER}>
                            <AssignmentIcon />
                            <ListItemText disableTypography primary={<Typography variant="button"
                                component="span">{t('client.menu.register')}</Typography>} />
                        </NavLink>
                    </div>
                </List>
            )}
        </Drawer>
    </Fragment>
}

ApplicationMenu.propTypes = {
    onOpenChat: PropTypes.func
};

ChatBox.defaultProps = {
    onOpenChat: () => {
    }
}

