import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ChatMessages from './ChatMessages';
import {MessageType} from "../data/Constants";
import RoomViewers from "./RoomViewers";
import SessionPolls from "./SessionPolls";
import WordCloud from "./WordCloud";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {EventType, Visibility} from "../data/Constants";
import {apiFacade} from "../data/ApiFacade";

const styles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        right: -300,
        bottom: 0,
        backgroundColor: theme.palette.tertiary.var1,
        minWidth: '290px',
        maxWidth: '290px',
        padding: theme.spacing(3, 2, 2, 2),
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
        zIndex: 9,
        transition: 'all 1s',
        [theme.breakpoints.up('md')]: {
            position: 'static'
        },
    },
    openChat: {
        right: 0
    },
    rootBox: {
        height: '100%'
    },
    appBar: {
        backgroundColor: theme.palette.primary.var1,
        borderRadius: '20px',
        '& .tabs-root': {
            height: 30,
            width: '100%',
            '& .MuiTabs-flexContainer': {
                display: 'flex',
                justifyContent: 'space-between',
                '& .MuiTab-root': {
                    width: '100%',
                    flexShrink: 'initial'
                }
            }
        }
    },
    tabPanelCustom: {
        height: '100%',
        overflowY: 'auto'
    },
    indicator: {
        display: 'none'
    },
    appBarSpacer: theme.mixins.toolbar,
    tab: {
        minWidth: 0,
    }
}));

/**
 * Component displaying virtual room interactions such as room chat, q&a chat, viewers presence and polls
 * @param props component properties
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 * @author petar.todorovski
 */
export default function ChatBar(props) {

    const {t} = useTranslation()
    const classes = styles();
    const [tab, setTab] = useState(0);
    const knownChatBarFeatures = ["enableChat", "enableViewers", "enableQa", "enablePoll", "enableWordCloud"]

    useEffect(() => {
        if (!props.session || !props.session.id) setTab(0)
    }, [props.session])


    const getEnabledFeatures = () => {
        const featuresFiltered = []
        if (props.session && props.session.features) {
            Object.keys(props.session.features).forEach((k) => {
                const isFeatureEnabled = props.session.features[k]
                const isFeatureKnown = !!knownChatBarFeatures.find(f => f === k)
                if (isFeatureEnabled && isFeatureKnown) {
                    featuresFiltered.push(k)
                    if (k === "enableChat") {
                        featuresFiltered.push("enableViewers")
                    }
                }
            })
        }
        // if all features are disabled, add chat and viewers as default ones
        if (featuresFiltered.length <= 0) {
            featuresFiltered.push("enableChat")
            featuresFiltered.push("enableViewers")
        }
        return featuresFiltered
    }

    const getTranslation = (k) => {
        switch (k) {
            case "enableChat":
                return t("client.menu.chat")
            case "enableViewers":
                return t("client.menu.viewers")
            case "enableQa":
                return t("client.menu.qa")
            case "enablePoll":
                return t("client.menu.polls")
            case "enableWordCloud":
                return t("client.menu.word-cloud")
            default:
                return k
        }
    }

    const getComponent = (k) => {
        switch (k) {
            case "enableChat":
                return <ChatMessages room={props.room} type={MessageType.TOPIC}/>
            case "enableViewers":
                return <RoomViewers room={props.room}/>
            case "enableQa":
                return <ChatMessages room={props.room} type={MessageType.SUPPORT}/>
            case "enablePoll":
                return <SessionPolls session={props.session}/>
            case "enableWordCloud":
                return <WordCloud session={props.session}/>
            default:
                return k
        }
    }

    return (
        <div className={clsx(classes.root, props.open && classes.openChat)}>
            <div className={classes.appBarSpacer}/>
            <AppBar position="static" className={classes.appBar}>
                <Tabs classes={{indicator: classes.indicator}} className="tabs-root" value={tab}
                      onChange={(e, v) => setTab(v)} style={{height: 30}}>
                    {getEnabledFeatures()
                        .map((k, i) => <Tab key={"tab" + i} className={classes.tab}
                                            label={getTranslation(k)} {...tabProps(i)} />)}
                </Tabs>
            </AppBar>
            {getEnabledFeatures()
                .map((k, i) =>
                    <TabPanel className={classes.tabPanelCustom} value={tab} index={i} key={"tabPanel" + i}>
                        {getComponent(k)}
                    </TabPanel>
                )}
        </div>
    );
}

ChatBar.propTypes = {
    room: PropTypes.object,
    session: PropTypes.object,
    open: PropTypes.bool
}

ChatBar.defaultProps = {
    room: null,
    session: null,
    open: false
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const classes = styles();
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`chat-tab-${index}`}
            {...other}>
            <Box p={0} className={classes.rootBox}>
                {children}
            </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function tabProps(index) {
    return {
        id: `chat-tab-${index}`,
        'aria-controls': `chat-tabpanel-${index}`,
    };
}
