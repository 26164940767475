import React from "react"
import {useSelector} from "react-redux";
import {makeStyles, MenuItem, Select, useTheme} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import useLanguage from "../data/Language";
import {Languages} from "../data/Constants";

/**
 * Language switcher component, used for selection of current application language. Selected language is persisted with
 * react redux store upon selection.
 * @author dame.gjorgjievski
 */
 const styles = makeStyles((theme) => ({
    lang: {
        '& .lang-select': {
            display: "inline-block", 
            verticalAlign: 'middle', 
            color: theme.palette.tertiary.var2,
            "& .MuiOutlinedInput-notchedOutline": {
                border: 0
            },
            '& .MuiSelect-icon': {
                color: theme.palette.quaternary.var5
            }
        }
    }

}));

export default function LanguageSwitcher(props) {
  
    const classes = styles()
    const {lang, setLang} = useLanguage()
    const event = useSelector(state => state.event)
    const {t} = useTranslation()

    const onChange = (e) => {
        setLang(e.target.value)
    }

    return <div className={classes.lang}>
        <div className={props.lang}/>
        <Select
            className="lang-select"
            labelId="lang-select-label"
            variant="outlined"
            margin="none"
            id="language"
            name="language"
            autoComplete="language"
            value={lang()}
            onChange={onChange}>
            {event && event.languages.map((l, index) => {
                return <MenuItem key={index} value={l}>{l}</MenuItem>
            })}
            {!event && Languages.map((l, index) => {
                return <MenuItem key={index} value={l.code}>{l.code}</MenuItem>
            })}
        </Select>
    </div>
}
