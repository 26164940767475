import { Box, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Fullscreen, FullscreenExit, Pause, PlayArrow, VolumeOff, VolumeUp } from '@material-ui/icons';
import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import screenFull from 'screenfull';
import useLanguage from '../data/Language';
import { styles } from '../styles/SessionViewStyle';
import DateUtil from '../utils/DateUtil';

export default function SessionPlayer({ session }) {

    const { value } = useLanguage()
    const classes = styles('50%', '50%')
    const [mouseMove, setMouseMove] = useState(false)
    const [mouseOver, setMouseOver] = useState(false)
    const [state, setState] = useState({
        played: 0,
        playing: true,
        duration: 0,
        volume: 1,
        seeking: false,
        playedSeconds: 0,
        loadedSeconds: 0,
        loaded: 0,
        time: '00:00:00',
        fullScreen: false
    })
    let container = useRef(null)
    let videoControls = useRef()
    let player = null
    let timeout = useRef(null)

    const ref = p => {
        player = p
    }

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove)

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
        }
    }, [session])

    const onMouseMove = () => {
        setMouseMove(true)
        if (!timeout.current)
            timeout.current = setTimeout(() => {
                setMouseMove(false)
                clearTimeout(timeout.current)
                timeout.current = null
            }, 6000);
    }

    const handleSeekMouseDown = e => {
        setState({ ...state, seeking: true })
    }

    const handleSeekMouseUp = e => {
        setState({ ...state, seeking: false })
        player.seekTo(parseFloat(e.target.value))
    }

    const handleSeekChange = e => {
        setState({ ...state, played: parseFloat(e.target.value) })
    }

    const handleVolumeChange = e => {
        setState({ ...state, volume: parseFloat(e.target.value) })
    }

    const handleMute = () => {
        state.volume === 1 ? setState({ ...state, volume: 0 }) : setState({ ...state, volume: 1 })
    }

    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing })
    }

    const handleProgress = s => {
        // We only want to update time slider if we are not currently seeking
        if (!state.seeking) {
            setState({ ...state, ...s, time: DateUtil.display(s.playedSeconds) })
        }
    }

    const handleDuration = (duration) => {
        setState({ ...state, duration: duration })
    }

    const handleEnd = () => {
        setState({ ...state, playing: false })
    }

    const toggleFullScreen = () => {
        console.log("toggle fullscreen", player, !state.fullScreen)
        setState({ ...state, fullScreen: !state.fullScreen })
        screenFull.toggle(container.current)
    }

    return (
        <div ref={container} onMouseOver={e => setMouseOver(true)} onMouseOut={e => setMouseOver(false)}
            onMouseMove={onMouseMove} className={classes.playerWrapper}>
            {/* player */}
            <ReactPlayer url={value(session.url)} width={'100%'} height={'100%'} playsinline={false}
                playing={state.playing} pip={false} onEnded={handleEnd}
                className={classes.player} ref={ref} volume={state.volume} controls={false}
                onProgress={handleProgress} onDuration={handleDuration}
                config={{
                    youtube: {
                        playerVars: {
                            showinfo: 0,
                            autoplay: 1,
                            controls: 0,
                            modestbranding: 1,
                            enablejsapi: 1,
                            iv_load_policy: 3,
                            rel: 0,
                            playsinline: 0,
                            widgetId: 15,
                            loop: 0
                        }
                    }
                }} />
            {/* controls */}
            <div ref={videoControls} className={classes.controlWrapper}
                style={{ visibility: ((mouseMove && state.fullScreen) || (mouseMove && mouseOver)) ? 'visible' : 'hidden' }}>
                <Box mx={2} mb={4} className={classes.controls}>
                    <Box>
                        <IconButton onClick={handlePlayPause} className={classes.control}>
                            {state.playing ? <Pause /> : <PlayArrow />}
                        </IconButton>
                    </Box>
                    <Box style={{ width: '50%' }}>
                        <input className={classes.track}
                            type='range' min={0} max={0.999999} step='any'
                            value={state.played}
                            onMouseDown={handleSeekMouseDown}
                            onChange={handleSeekChange}
                            onMouseUp={handleSeekMouseUp} />
                    </Box>
                    <Box px={1}>
                        <Typography className={classes.time} variant="button">{state.time}</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleMute} className={classes.control}>
                            {state.volume === 1 ? <VolumeUp /> : <VolumeOff />}
                        </IconButton>
                    </Box>
                    <Box style={{ width: '15%' }}>
                        <input type='range' min={0} max={1} className={classes.volume} step='any'
                            value={state.volume} onChange={handleVolumeChange} />
                    </Box>
                    <Box onClick={toggleFullScreen}>
                        {!state.fullScreen ?
                            <Fullscreen fontSize="large" className={classes.control} /> :
                            <FullscreenExit fontSize="large" className={classes.control} />}
                    </Box>
                </Box>
            </div>
        </div>
    )
}