import { Avatar, Box, Slide, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import { useAnalytics } from '../data/Analytics';
import { Path, Visibility } from "../data/Constants";
import useLanguage from "../data/Language";
import FileService from "../services/FileService";
import RoomService from "../services/RoomService";
import UserService from '../services/UserService';

/**
 * Round table box component, used to display round table session teaser info and attending users
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
const position = 25
const styles = makeStyles((theme) => ({
    roomBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: theme.spacing(52),
        padding: theme.spacing(6, 4.3),
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.palette.common.white,
        boxShadow: '2px 4px 40px 8px rgba(233,233,233,1)',
        '& .room-table': {
            width: '100%',
            height: '100%',
            position: 'relative',
            margin: position,
            maxWidth: theme.spacing(22.5),
            maxHeight: theme.spacing(17.5),
            '& .room-table-cover': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: theme.palette.common.white,
                border: `2px solid ${theme.palette.primary.var3}`,
                borderRadius: theme.spacing(3),
                zIndex: 1
            },
            '& .room-bench': {
                position: 'absolute',
                display: 'flex',
                justifyContent: 'space-around',
                '& .MuiAvatar-root': {
                    backgroundColor: theme.palette.secondary.var2,
                    border: `1px solid ${theme.palette.primary.var1}`,
                    color: theme.palette.common.black,
                    cursor: 'pointer'
                },
                '& .MuiSvgIcon-root': {
                    display: 'none'
                },
                '& .seat': {
                    zIndex: 3
                }
            }
        }
    },
    roomInfo: {
        maxWidth: theme.spacing(35),
        textAlign: 'center',
        '& .room-title': {
            padding: theme.spacing(4, 0),
            fontWeight: 700,
            color: theme.palette.tertiary.var2
        },
        '& .room-action': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.secondary.main,
            '& .arrow-icon': {
                marginLeft: theme.spacing(1)
            }
        }
    }
}))

export default function RoundTableBox(props) {

    const classes = styles();
    const { t } = useTranslation()
    const { value } = useLanguage()
    const [users, setUsers] = useState([])
    let interval = useRef(0)
    const analytics = useAnalytics()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!props.session) return
        if (props.session.room) {
            load()
            interval.current = setInterval(load, 30000)
        }
        return () => clearInterval(interval.current)
    }, [props.session])

    const max = () => (props.session && props.session.features && props.session.features.maxUsers) || 8
    const user = (idx) => (users.length >= (idx + 1) && users[idx]) || {}
    const load = () => {
        RoomService.findRoomUsers(props.session.room.name).then(response => {
            setUsers(response.data)
        })
    }

    const onUserSelect = (user, event) => {
        if (user.visibility === Visibility.VISIBLE) {
            UserService.viewProfile(dispatch, analytics, user)
        }
    }

    const maskWithOrElse = (user, mask, orelse) => {
        return user.visibility !== Visibility.VISIBLE ? mask : orelse
    }

    const tooltip = (user, idx) => <Tooltip
        title={(user(idx).name ? maskWithOrElse(user(idx), t('client.user.labels.anonymous'), user(idx).name) : '') + ' ' + (user(idx).surname ? maskWithOrElse(user(idx), '', user(idx).surname) : '')}
        placement="bottom" TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
        <Avatar className={clsx({ seat: user(idx).id })}
            onClick={(e) => onUserSelect(user(idx), e)}
            src={user(idx).image ? FileService.url(user(idx).image.uri) : ''}>
            {`${(!user(idx).image && user(idx).name) ? maskWithOrElse(user(idx), 'A', user(idx).name[0].toUpperCase()) : ''} 
            ${(!user(idx).image && user(idx).surname) ? maskWithOrElse(user(idx), 'A', user(idx).surname[0].toUpperCase()) : ''}`}
        </Avatar>
    </Tooltip>

    return (props.session &&
        <Box className={classes.roomBox}>
            <Box className="room-table">
                <Box className="room-table-cover" component="div" />

                {/* Top table seats */}
                <Box className="room-bench" top={-position} width="100%" px={4}>
                    {max() >= 1 && tooltip(user, 0)}
                    {max() >= 5 && tooltip(user, 4)}
                </Box>

                {/* Bottom table seats */}
                <Box className="room-bench" bottom={-position} width="100%" px={4}>
                    {max() >= 2 && tooltip(user, 1)}
                    {max() >= 6 && tooltip(user, 5)}
                </Box>

                {/* Left table seats */}
                <Box className="room-bench" flexDirection="column" height="100%" left={-position} py={2}>
                    {max() >= 3 && tooltip(user, 2)}
                    {max() >= 7 && tooltip(user, 6)}
                </Box>

                {/* Right table seats */}
                <Box className="room-bench" flexDirection="column" height="100%" right={-position} py={2}>
                    {max() >= 4 && tooltip(user, 3)}
                    {max() >= 8 && tooltip(user, 7)}
                </Box>
            </Box>

            {/* Room info */}
            <Box className={classes.roomInfo}>
                <Typography variant="body1" className="room-title">
                    {value(props.session.name, null, 50)}
                </Typography>
                {users && users.length < max() &&
                    <NavLink style={{ textDecoration: 'none' }}
                        to={Path.EVENT_SESSION.replace(":id", props.session.event.id).replace(":sid", props.session.id)}>
                        <Typography className="room-action" variant="button" component="span">
                            {t("client.session.actions.take-your-seat")}
                            <TrendingFlatIcon className="arrow-icon" />
                        </Typography>
                    </NavLink>}
            </Box>
        </Box>)
}

RoundTableBox.propTypes = {
    session: PropTypes.object
};

RoundTableBox.defaultProps = {
    session: null
}