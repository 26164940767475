import React, {useState} from "react";
import MaterialTable from "material-table";
import {tableIcons} from "../styles/TableIcons";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router-dom";
import {AddBox, Edit} from "@material-ui/icons";
import {Container} from "@material-ui/core";
import PollService from "../services/PollService";
import useLanguage from "../data/Language";
import {useTranslation} from "react-i18next";
import CompUtil from "../utils/CompUtil";

/**
 * The polls listing component, lists all polls managed by an admin user
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function PollsListView() {

    const {l, value} = useLanguage()
    const {t} = useTranslation()
    const columns = [
        {title: t("admin.default.fields.id"), field: "id", hidden: true},
        {title: t("admin.default.fields.name"), field: `name`, render: rowData => value(rowData.name, null, 30)},
        {title: t("admin.default.fields.session"), field: `sessionName`, render: rowData => value(rowData.sessionName, null, 30)},
        {title: t("admin.poll.fields.question"), field: `content`, render: rowData => value(rowData.content, null, 30)},
        {title: t("admin.poll.fields.options"), field: "", render: rowData => rowData.options.length},
        {
            title: t("admin.default.fields.time-created"), field: "timeCreated",
            render: rowData => rowData.timeCreated.replace("T", " ")
        },
        {title: t("admin.poll.fields.votes"), field: "count", sorting: false},
        {title: t("admin.default.fields.enabled"), field: "enabled"},
        {title: t("admin.default.fields.order"), field: "order"}
    ]

    const [errors, setErrors] = useState([])
    const history = useHistory()

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container maxWidth="lg" style={{width: '100%'}}>
            <MaterialTable
                title={t("admin.poll.title")}
                columns={columns}
                icons={tableIcons}
                data={query => {
                    return new Promise((resolve, reject) => {
                        PollService.search(query)
                            .then(response => {
                                resolve({
                                    data: response.data.result,
                                    page: query.page,
                                    totalCount: response.data.totalCount
                                })
                            })
                            .catch(error => {
                                console.log("got error", error, error.response)
                                setErrors([t("admin.default.errors.data-load")])
                            })
                    })
                }}
                actions={[
                    {
                        icon: () => <AddBox/>,
                        tooltip: t("admin.default.actions.add"),
                        position: "toolbar",
                        onClick: () => {
                            history.push("/admin/poll/edit/0")
                        }
                    },
                    {
                        icon: () => <Edit/>,
                        tooltip: t("admin.default.actions.edit"),
                        onClick: (event, rowData) => {
                            history.push("/admin/poll/edit/" + rowData.id)
                        }
                    }
                ]}
                editable={{
                    deleteTooltip: (rowData) => t("poll.delete"),
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            PollService.delete(oldData.id)
                                .then(() => {
                                    resolve()
                                })
                                .catch(error => {
                                    setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
                                    clearErrors()
                                    resolve()
                                })
                        })
                }}
                options={{
                    actionsColumnIndex: -1,
                }}
                localization={CompUtil.localizeTable(t)}
            />
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>}
            </div>
        </Container>
    )
}
