import { Slide, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import PropTypes from "prop-types";
import React from "react";
import { Visibility } from "../data/Constants";
import FileService from "../services/FileService";

/**
 * Component that displays a list of user avatars
 * @param props
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function UserAvatarList(props) {

    const classes = makeStyles((theme) => createStyles({
        speakers: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            '& a': {
                textDecoration: 'none'
            },
            '& .MuiAvatarGroup-avatar': {
                width: props.large ? theme.spacing(5) : theme.spacing(5),
                height: props.large ? theme.spacing(5) : theme.spacing(5),
                marginRight: props.condensed ? theme.spacing(props.large ? -1 : -1) : theme.spacing(2),
                margin: props.fromDialog ? '10px' : null,
                textDecoration: 'none',
                display: 'inline-flex',
                border: `2px solid ${theme.palette.secondary.main}`,
                backgroundColor: theme.palette.primary.var1,
                cursor: 'pointer !important',
                [theme.breakpoints.up('md')]: {
                    width: props.large ? theme.spacing(9) : theme.spacing(5),
                    height: props.large ? theme.spacing(9) : theme.spacing(5),
                    marginRight: props.condensed ? theme.spacing(props.large ? -2 : -1) : theme.spacing(2),
                },
                '&:last-child': {
                    cursor: props.users.length > props.max ? 'default' : 'pointer',
                    backgroundColor: props.users.length > 5 ? theme.palette.secondary.main : theme.palette.primary.var1,
                    marginRight: 0,
                    zIndex: '3 !important'
                },
                '& .MuiAvatar-img': {
                    borderRadius: '50%'
                }
            },
        }
    }))()

    return <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <AvatarGroup className={classes.speakers} max={props.max}
            onClick={() => props.onAvatarGroupSelect && props.onAvatarGroupSelect(props.tabId)}>
            {props.users.filter(u => u.visibility === Visibility.VISIBLE).map((user, i) =>
                <Tooltip key={"user-avatar-" + i} title={user.name + ' ' + user.surname} placement="top"
                    TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                    <Avatar onClick={(e) => props.onSelect(user, e)} key={'user' + i}
                        alt={user.name + ' ' + user.surname}
                        about={user.name + ' ' + user.surname}
                        src={user.image ? FileService.url(user.image.uri) : ''}
                        style={{
                            zIndex: i,
                            width: props.userWidth ? props.userWidth : null,
                            height: props.userHeight ? props.userHeight : null
                        }}>
                        {!user.image && user.name.split("")[0].toUpperCase()}
                    </Avatar>
                </Tooltip>
            )}
        </AvatarGroup>
    </div>
}

UserAvatarList.propTypes = {
    users: PropTypes.array,
    max: PropTypes.number,
    condensed: PropTypes.bool,
    reverse: PropTypes.bool,
    large: PropTypes.bool,
    onSelect: PropTypes.func
};

UserAvatarList.defaultProps = {
    users: [],
    max: 5,
    condensed: false,
    large: false,
    onSelect: function (user) {
    }
}