import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Pdf icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function PdfIcon() {
    return (
        <SvgIcon viewBox="0 0 57.758 77.025">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor={theme.palette.secondary.main}/>
                <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_738" data-name="Path 738" d="M55.637-20.268,43.017-32.887a7.22,7.22,0,0,0-5.1-2.121H7.22A7.244,7.244,0,0,0,0-27.773V34.8a7.222,7.222,0,0,0,7.22,7.22H50.538a7.222,7.222,0,0,0,7.22-7.22V-15.154A7.255,7.255,0,0,0,55.637-20.268Zm-3.4,3.414a2.367,2.367,0,0,1,.632,1.113H38.505V-30.1a2.367,2.367,0,0,1,1.113.632ZM50.538,37.2H7.22A2.414,2.414,0,0,1,4.813,34.8V-27.773A2.414,2.414,0,0,1,7.22-30.18H33.692v15.643a3.6,3.6,0,0,0,3.61,3.61H52.944V34.8A2.414,2.414,0,0,1,50.538,37.2Zm-3.309-25.75c-2.031-2-8.273-1.384-11.085-1.008A15.743,15.743,0,0,1,29.36,1.933c.647-2.707,1.8-7.1.963-9.762-.662-4.226-5.971-3.715-6.708-1.023-.752,2.752-.045,6.678,1.264,11.7a144.8,144.8,0,0,1-6.332,13.326c-3.129,1.609-8.137,4.407-8.844,7.881-.526,2.527,3.444,5.926,7.987.963A40.242,40.242,0,0,0,22.4,18.177c4.016-1.324,8.438-2.978,12.334-3.61,3.294,1.8,7.159,2.993,9.716,2.993C48.613,17.576,48.793,13.018,47.229,11.454ZM12.755,24.84c.887-2.391,4.3-5.174,5.34-6.137C14.77,24.013,12.755,24.946,12.755,24.84ZM27.074-8.6c1.309,0,1.173,5.64.316,7.159C26.608-3.888,26.638-8.6,27.074-8.6ZM22.8,15.365a70.551,70.551,0,0,0,4.332-9.581,19.592,19.592,0,0,0,5.279,6.242C28.758,12.732,25.585,14.3,22.8,15.365Zm23.073-.887S45,15.53,39.332,13.108C45.5,12.657,46.507,14.071,45.875,14.477Z" transform="translate(0 35.008)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
