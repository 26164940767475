import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from "prop-types";

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  large: {
    height: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.var1}`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white
  },
  default: {

  }
}));

export default function LinearProgressBar(props) {

  const classes = styles();
  const [progress, setProgress] = useState(props.value);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= props.value ? 100 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgressLine className={classes[props.className]} value={props.value} />
    </div>
  );
}

function LinearProgressLine(props) {
  const classes = styles();
  return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1} className={classes.linearProgressWrap}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="button">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
  );
}

LinearProgressBar.propTypes = {
  className: PropTypes.string
};

LinearProgressBar.defaultProps = {
  className: 'default'
}
