import React from 'react';
import {Box, Button, Grid, Typography} from '@material-ui/core';
import PropTypes from "prop-types";
import DateUtil from "../utils/DateUtil";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import UserAvatarList from "../components/UserAvatarList";
import {SessionStatusFlag} from "./SessionStatus";
import {Path, SessionType} from "../data/Constants";
import {useTranslation} from "react-i18next";
import useLanguage from "../data/Language";
import WindowUtil from "../utils/WindowUtil";
import { theme } from '../styles/Theme';

const styles = makeStyles((theme) => createStyles({
    sessionInfoBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        padding: theme.spacing(2.5),
        color: theme.palette.primary.main,
        borderRadius: '10px',
        [theme.breakpoints.up('sm')]:{
            padding: theme.spacing(3),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(5),
        },
        '& .info-top': {
            '& .date': {
                marginLeft: theme.spacing(1.5)
            }
        },
        '& .info-box-title': {
            wordBreak: "break-word",
            margin: theme.spacing(2, 0, 1),
            display: "-webkit-box",
            boxOrient: "vertical",
            lineClamp: 3,
            overflow: "hidden",
            color: theme.palette.tertiary.var2,
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(2, 0),
            },
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(3, 0),
            },
        },
        '& .info-bottom': {
            display: 'flex',
            
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                alignItems: 'center'
            },
            '& button': {
                maxWidth: 210,
                color: theme.palette.quaternary.var3,
                backgroundColor: theme.palette.quaternary.var1,
                textTransform: 'uppercase',
                marginTop: theme.spacing(2),
                '&:hover': {
                    backgroundColor: theme.palette.quaternary.var2,
                    color: theme.palette.quaternary.var4,
                },
                [theme.breakpoints.up('sm')]: {
                    marginTop: 0
                },
            }
        }
    },
}))

/**
 * Session headline component used for display of popular session information
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
export default function SessionHeadline(props) {

    const {value} = useLanguage()
    const history = useHistory()
    const classes = styles()
    const {t} = useTranslation()

    const ts = DateUtil.fromUtc(new Date(props.session.timeStart))

    const navigate = (e) => {
        e.preventDefault()
        switch(props.session.type) {
            case SessionType.EXTERNAL:
                WindowUtil.open(value(props.session.url))
                break;
            default:
                history.push(Path.EVENT_SESSION.replace(":id", props.event.id).replace(":sid", props.session.id))
                break;
        }
    }

    return (
        <Grid item xs={12} lg={6}>
            <Box bgcolor={theme.palette.common.white} className={classes.sessionInfoBox}>
                <Box display="flex" alignItems="center" className="info-top">
                    <SessionStatusFlag session={props.session} />
                    <Typography className="date" variant="h6">{DateUtil.format(ts, DateUtil.FORMAT_FULL)}</Typography>
                </Box>
                <Typography variant="h5" component="p" className="info-box-title" title={value(props.session.name)}>
                    {value(props.session.name)}
                </Typography>
                <Box className="info-bottom">
                    <Box pr={1}>
                        {props.session.speakers.length > 0 && <UserAvatarList onSelect={props.onSelect} users={props.session.speakers} max={4}/>}
                    </Box>
                    <Button size="large" variant="contained" disableElevation fullWidth onClick={navigate}>
                        {t('client.session.actions.join')}
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
}

SessionHeadline.propTypes = {
    session: PropTypes.object,
    event: PropTypes.object
}
