import PropTypes from "prop-types";
import {
    Avatar,
    CardActions,
    createStyles,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    useTheme
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {DEFAULT_USER_IMAGE} from "../data/Configuration";
import LetterIcon from "../assets/LetterIcon";
import GlobeIcon from "../assets/GlobeIcon";
import MessageIcon from "../assets/MessageIcon";
import React from "react";
import StarIcon from "../assets/StarIcon";
import {UserAction} from "../data/Constants";
import {useAuth} from "./ProvideAuth";
import UserService from "../services/UserService";
import FileService from "../services/FileService";
import {useTranslation} from "react-i18next";
import LinkedinIcon from "../assets/LinkedinIcon";
import FacebookIcon from "../assets/FacebookIcon";
import InstagramIcon from "../assets/InstagramIcon";
import TwitterIcon from "../assets/TwitterIcon";
import { SocialMediaName } from '../data/Constants';

const styles = makeStyles((theme) => createStyles({
    root: {
        zIndex: '10001 !important',
        backdropFilter: 'blur(1px)'
    },
    content: {
        minHeight: 300
    },
    close: {
        float: 'right',
        marginRight: -theme.spacing(2),
        marginTop: -theme.spacing(2),
    },
    avatar: {
        width: 207,
        height: 207,
        margin: theme.spacing(3),
        marginTop: 0
    },
    title: {
        color: theme.palette.primary.var1
    },
    subtitle: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold'
    },
    actions: {
        padding: 0,
        marginLeft: -theme.spacing(1),
        marginTop: theme.spacing(1.5),
        [theme.breakpoints.up("xs")]: {
            display: "flex",
            justifyContent: "center"
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start",
        }
    },
    about: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    tag: {
        display: 'inline-block',
        marginRight: theme.spacing(2),
        fontWeight: 'bold'
    },
    userProfilePopUpCard: {
        [theme.breakpoints.up('xs')]: {
            display: "grid"
        },
        [theme.breakpoints.up('sm')]: {
            display: "flex"
        }
    },
    userProfilePopUpCardInfo: {
        [theme.breakpoints.up('xs')]: {
            textAlign: "center",
            display: "grid",
            minWidth: "100%"
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
            minWidth: "1%",
            height: "100%"
        }
    }
}))


/**
 * Component displaying a user profile
 * @author dame.gjorgjievski
 */
export default function UserProfile(props) {

    const auth = useAuth()
    const classes = styles()
    const theme = useTheme()
    const {t} = useTranslation()
    const position = props.user && props.user.function ? props.user.function + ' | ' +
        (props.user.organization ? props.user.organization : ('')) : ('')
    const onAction = (action) => (e) => UserService.onAction(action, props.user, e)

    return <Dialog className={classes.root}
                   disableBackdropClick
                   disableEscapeKeyDown
                   hideBackdrop
                   fullWidth
                   aria-labelledby="user-profile-dialog-title"
                   open={!!props.user && !!props.user.id}
                   PaperProps={{
                       style: {
                           backgroundColor: theme.palette.common.white,
                           backdropFilter: 'blur(60px)',
                           boxShadow: '0px 7px 17px 17px rgba(170,170,170,0.1)',
                           maxWidth: 700
                       },
                   }}>
        <DialogContent className={classes.content}>
            <IconButton onClick={props.onClose} className={classes.close}>
                <Close/>
            </IconButton>
            {props.user && props.user.id &&
            <Grid container spacing={0} className={classes.userProfilePopUpCard}>
                <Grid item xs={5}>
                    <Avatar className={classes.avatar}
                            src={props.user.image ? FileService.url(props.user.image.uri) : DEFAULT_USER_IMAGE}/>
                </Grid>
                <Grid item xs={7} className={classes.userProfilePopUpCardInfo}>
                    <Typography className={classes.title} variant="h3">
                        {props.user.name + ' ' + props.user.surname}
                    </Typography>
                    <Typography className={classes.subtitle} variant="body1">
                        {position}
                    </Typography>
                    <CardActions className={classes.actions}>
                        {props.event && props.event.features && props.event.features.showAttendeeEmail ? <IconButton onClick={onAction(UserAction.MAIL)} className={classes.icon}>
                            <LetterIcon/>
                        </IconButton>:<div/>}
                        {/* <IconButton onClick={onAction(UserAction.LINK)} className={classes.icon}>
                            <GlobeIcon/>
                        </IconButton> */}


                        {props.user.socialMedia[SocialMediaName.LINKEDIN]
                            ? <IconButton name={SocialMediaName.LINKEDIN}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <LinkedinIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.FACEBOOK]
                            ? <IconButton name={SocialMediaName.FACEBOOK}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <FacebookIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.INSTAGRAM]
                            ? <IconButton name={SocialMediaName.INSTAGRAM}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <InstagramIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.TWITTER]
                            ? <IconButton name={SocialMediaName.TWITTER}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <TwitterIcon fontsize='small' />
                            </IconButton>
                            : null
                        }
                        {props.user.socialMedia[SocialMediaName.WEBSITE]
                            ? <IconButton name={SocialMediaName.WEBSITE}
                                onClick={e => UserService.onAction(UserAction.LINK, props.user, e)}
                                className={classes.icon}>
                                <GlobeIcon fontsize='small' />
                            </IconButton>
                            : null
                        }


                        {props.user && props.user.id !== auth.user.id &&
                        <IconButton onClick={onAction(UserAction.MESSAGE)} className={classes.icon}>
                            <MessageIcon/>
                        </IconButton>}
                        {/*                         {props.user.id !== auth.user.id && */}
                        {/*                         <IconButton onClick={onAction(UserAction.CALL)} className={classes.icon}> */}
                        {/*                             <CameraIcon/> */}
                        {/*                         </IconButton>} */}
                        {props.user && props.user.id !== auth.user.id &&
                        <IconButton onClick={onAction(UserAction.STAR)} className={classes.icon}>
                            <StarIcon/>
                        </IconButton>}
                    </CardActions>
                    {props.user.description && props.user.description.trim().length > 0 && <div>
                        <Typography className={classes.about} variant="h6">{t('client.user.labels.about')}:</Typography>
                        <Typography className={classes.description}
                                    variant="body1">{props.user.description}</Typography>
                    </div>}
                    {props.user.tags && props.user.tags.length > 0 &&
                    <div style={{marginBottom: theme.spacing(4)}}>
                        <Typography className={classes.about} variant="h6">{t('client.user.labels.tags')}:</Typography>
                        <div>
                        {props.user.tags.map((t, i) =>
                            <Typography key={"tag" + i} className={classes.tag} component="span"
                                        variant="body1">#{t}</Typography>
                        )}
                        </div>
                    </div>}
                </Grid>
            </Grid>}
        </DialogContent>
    </Dialog>
}

UserProfile.propTypes = {
    user: PropTypes.object,
    onClose: PropTypes.func
}

UserProfile.defaultProps = {
    user: null,
    onClose: null
}
