import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {styles} from "../styles/FormStyles"
import {useAuth} from "../components/ProvideAuth"
import {emailValidation, requiredValidation} from "../utils/Validators"
import Alert from '@material-ui/lab/Alert';
import {Path} from "../data/Constants";
import GAEvent from "../models/GAEvent";
import {useAnalytics} from "../data/Analytics";
import {useTranslation} from "react-i18next";
import StringUtil from "../utils/StringUtil";

/**
 * Displays an user login view
 * @author dame.gjorgjievski
 */
export default function LoginView() {

    const {t} = useTranslation()
    const classes = styles()
    const [state, setState] = useState({
        username: "",
        password: "",
        code: null
    })
    let [errorz, setErrorz] = useState({
        username: "",
        password: "",
        code: ""
    })
    const [errors, setErrors] = useState([])
    const auth = useAuth()
    const analytics = useAnalytics()
    const history = useHistory()

    const submit = (e) => {
        e.preventDefault()
        errorz = {...errorz, username: requiredValidation("Username", state.username)};
        if (errorz.username.length === 0)
            errorz = {...errorz, username: emailValidation(state.username)};
        errorz = {...errorz, password: requiredValidation("Password", state.password)};
        if(state.code !== null)
            errorz = {...errorz, code: requiredValidation("Code", state.code)};
        setErrorz(errorz)
        if (errorz.username.length > 0 || errorz.password.length > 0 || errorz.code.length > 0) return;

        if (state.code == null) accessCode()
        else login(state.username, state.password, state.code)
    }

    const login = (username, password, code) => {
        auth.signIn(username, password, code).then(() => {
            history.push(Path.EVENTS)
        }).catch(error => {
            if (error.response) {
                analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.LOGIN_ERROR, error.response.data.message))
                setErrors([error.response.data.message])
            }
            clearErrors()
        })
    }

    const accessCode = () => {
        auth.accessCode(state.username, state.password).then(response => {
            const code = response.data
            // if access code is returned proceed to login, else wait on user input
            if(StringUtil.isEmpty(code + "")) setState({...state, code: response.data})
            else login(state.username, state.password, response.data)
        }).catch(error => {
            if (error.response) {
                if (error.response.data.errors.length > 0) setErrors(error.response.data.errors)
                else setErrors([error.response.data.message])
                analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.LOGIN_ERROR, error.response.data.message))
            }
            clearErrors()
        })
    }

    const handleChange = (field, e) => {
        switch (field) {
            case "username":
                setState({...state, username: e.target.value})
                errorz = {...errorz, username: requiredValidation("Username", e.target.value)}
                if (errorz.username.length === 0)
                    errorz = {...errorz, username: emailValidation(e.target.value)};
                setErrorz(errorz)
                break;
            case "password":
                setState({...state, password: e.target.value})
                setErrorz({...errorz, password: requiredValidation("Password", e.target.value)})
                break;
            case "code":
                setState({...state, code: e.target.value})
                if(!StringUtil.isEmpty(e.target.value))
                    setErrorz({...errorz, code: requiredValidation("Code", e.target.value)})
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">{t('client.user.labels.login-title')}</Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('client.user.labels.username')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={state.username}
                        onChange={(e) => handleChange("username", e)}
                        error={errorz.username !== ""}
                        helperText={errorz.username}
                        data-cy="login-username-text-field"
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('client.user.labels.password')}
                        type="password"
                        id="password"
                        autoComplete="password"
                        value={state.password}
                        onChange={(e) => handleChange("password", e)}
                        error={errorz.password !== ""}
                        helperText={errorz.password}
                        data-cy="login-password-text-field"
                    />
                    {state.code != null && <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="code"
                        label={t('client.user.labels.access-code')}
                        type="number"
                        id="code"
                        autoComplete="code"
                        value={state.code}
                        onChange={(e) => handleChange("code", e)}
                        error={errorz.code !== ""}
                        helperText={errorz.code}
                    />}
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label={t('client.user.labels.remember-me')}
                        data-cy="login-rememberme-checkbox"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={submit}
                        data-cy="login-btn-submit"
                    >{t('client.user.actions.sign-in')}</Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to={Path.RESET} variant="body2" data-cy="login-forgot-password-link">{t('client.user.labels.forgot-password')}</Link>
                        </Grid>
                        <Grid item>
                            <Link to={Path.REGISTER} variant="body2" data-cy="login-signup-link">{t('client.user.labels.no-account-signup')}</Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 && <Alert severity="error">
                    {errors.map((msg, i) =>
                        <div key={'err' + i}>{msg}</div>
                    )}
                </Alert>}
            </div>
        </Container>
    );
}
