import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Star button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function StarIcon({className}) {
    return (
        <SvgIcon className={`${className}`} width="20.898" height="20.002" viewBox="0 0 20.898 20.002">
            <path id="Path_752" data-name="Path 752"
                  d="M20.629-10.8l-5.707-.832L12.371-16.8a1.251,1.251,0,0,0-2.242,0L7.578-11.633,1.871-10.8A1.251,1.251,0,0,0,1.18-8.668L5.309-4.645,4.332,1.039A1.249,1.249,0,0,0,6.145,2.355L11.25-.328l5.105,2.684a1.25,1.25,0,0,0,1.812-1.316l-.977-5.684L21.32-8.668A1.251,1.251,0,0,0,20.629-10.8ZM15.852-5.082l1.086,6.328L11.25-1.738,5.566,1.25,6.652-5.078l-4.6-4.488,6.355-.922L11.25-16.25l2.844,5.762,6.355.922Z"
                  transform="translate(-0.801 17.5)" fill={theme.palette.secondary.main}/>
        </SvgIcon>
    );
}