import {Avatar, Box, Typography} from "@material-ui/core";
import DateUtil from "../utils/DateUtil";
import FileService from "../services/FileService";
import React from "react";
import {useAuth} from "./ProvideAuth";
import PropTypes from "prop-types";
import {styles} from '../styles/ChatStyle'
import {Role, Visibility} from "../data/Constants";
import {useTranslation} from "react-i18next";
import {useAnalytics} from "../data/Analytics";
import UserService from "../services/UserService";
import {useDispatch} from "react-redux";
import CloseIcon from '@material-ui/icons/Close';

/**
 * Component displaying a single room message entry, used to populate children of ChatMessages component
 * @author dame.gjorgjievski
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const MessageBox = React.forwardRef((props, ref) => {

    const classes = styles()
    const auth = useAuth()
    const {t} = useTranslation()
    const analytics = useAnalytics()
    const dispatch = useDispatch()

    const onUserSelect = (user, event) => {
        UserService.viewProfile(dispatch, analytics, user)
    }

    const username = props.message.user.visibility === Visibility.ANONYMOUS ?
        t('client.user.labels.anonymous') :
        props.message.user.name + ' ' + props.message.user.surname

    return (props.message.user.id === auth.user.id ?
            <Box pt={2} display="flex" justifyContent="flex-end" ref={ref}>
                <Box className={classes.attendeeMessageWrap} display="flex" flexDirection="column"
                     alignItems="flex-end">
                    <Typography variant="button" style={{fontSize: '0.9rem'}}>
                        {username}</Typography>
                    <Typography variant="body1" component="span" className={classes.date}>
                        {DateUtil.format(DateUtil.fromUtc(new Date(props.message.timeCreated)), DateUtil.FORMAT_FULL)}
                    </Typography>
                    <Typography variant="body2" className={classes.attendeeMessage} style={{textAlign: 'right'}}>
                        {props.message.text}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {props.avatars &&
                    <Avatar onClick={(e) => onUserSelect(props.message.user, e)} className={classes.attendeeAvatar}
                            src={props.message.user.image ? FileService.url(props.message.user.image.uri) : null}>
                        {!props.message.user.image && props.message.user.name.split("")[0].toUpperCase()}
                    </Avatar>}
                    {auth.hasRole(Role.ADMIN, Role.SUPER) &&
                    <CloseIcon className={classes.delete} onClick={props.onDelete}/>}
                </Box>
            </Box>
            :
            <Box pt={2} ref={ref} display="flex" justifyContent="flex-end" flexDirection="row-reverse">
                <Box className={classes.speakerMessageWrap} display="flex" flexDirection="column"
                     alignItems="flex-start">
                    <Typography variant="button" style={{fontSize: '0.9rem'}}>
                        {username}</Typography>
                    <Typography variant="body1" component="span" className={classes.date}>
                        {DateUtil.format(DateUtil.fromUtc(new Date(props.message.timeCreated)), DateUtil.FORMAT_FULL)}
                    </Typography>
                    <Typography variant="body2" className={classes.speakerMessage}>
                        {props.message.text}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column"  justifyContent="center" alignItems="center">
                    {props.avatars &&
                    <Avatar onClick={(e) => onUserSelect(props.message.user, e)} className={classes.userAvatar}
                            src={props.message.user.image && props.message.user.visibility === Visibility.VISIBLE ? FileService.url(props.message.user.image.uri) : null}>
                        {(!props.message.user.image || props.message.user.visibility !== Visibility.VISIBLE) && (props.message.user.visibility === Visibility.VISIBLE? props.message.user.name.split("")[0].toUpperCase(): 'A')}
                    </Avatar>}
                    {auth.hasRole(Role.ADMIN, Role.SUPER) &&
                    <CloseIcon className={classes.delete} onClick={props.onDelete}/>}
                </Box>
            </Box>
    )
})

MessageBox.propTypes = {
    message: PropTypes.object,
    avatars: PropTypes.bool,
    onDelete: PropTypes.func
};

MessageBox.defaultProps = {
    message: {},
    avatars: true,
    onDelete: (message) => {
        console.log("delete message placeholder ", message)
    }
}

export default MessageBox