import {ENDPOINT_ROOMS} from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";

/**
 * Virtual room service, manages CRUD operations for room data
 * @author dame.gjorgjievski
 **/
const RoomService = new ApiService(ENDPOINT_ROOMS, [])

/**
 * Provides a room for u2u chat between current user and destination user
 * @param userId destination user
 * @returns {Promise<AxiosResponse<any>>}
 */
RoomService.provideChatRoom = async function (userId) {
    return await axios.get(this.endpoint + "chat/" + userId)
}

/**
 * Provides a room for system notifications for current user
 * @returns {Promise<AxiosResponse<any>>}
 */
RoomService.provideNotificationRoom = async function () {
    return await axios.get(this.endpoint + "notifications")
}

/**
 * Updates the seen status for all message in specified room for current user
 * @param roomId room identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
RoomService.updateSeenStatus = async function(roomId) {
    return await axios.post(this.endpoint + roomId + "/seen")
}

/**
 * Updates the seen status for one message in specified room for current user
 * @param roomId room identifier
 * @param messageId message identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
 RoomService.updateMessageSeenStatus = async function(roomId, messageId) {
    return await axios.post(this.endpoint + roomId + "/seen/" + messageId)
}

/**
 * Finds the currently active room users
 * @param name room name
 * @returns {Promise<AxiosResponse<any>>}
 */
RoomService.findRoomUsers = async function(name) {
    return await axios.get(this.endpoint + name + "/users")
}

export default RoomService