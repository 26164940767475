import {
    Box,
    CardContent,
    CardHeader, IconButton, makeStyles, Tooltip, Typography, Slide
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Role } from '../data/Constants';
import DateUtil from '../utils/DateUtil';
import { useAuth } from './ProvideAuth';

const styles = makeStyles((theme) => ({
    wrapper: {
        cursor: 'pointer',
        backgroundColor: props => !props.isSingle && !props.message.seen ? '#ecffff' : 'white',
        color: 'black',
        width: '100%',
        "& .MuiBox-root": {
            padding: theme.spacing(1, 2),
        },
        border: '1px solid ' + theme.palette.secondary.main,
    },
    text: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.9rem',
        fontWeight: 'bold'
    },
    singleText: {
        width: '100%',
        fontSize: '0.9rem',
        fontWeight: 'bold'
    },
    textHeader: {
        fontSize: '0.8rem'
    },
    header: {
        width: '100%',
        padding: '15px 10px 0 50px',
        color: 'gray'
    },
    body: {
        padding: props => props.isEmpty ? '15px 10px 0 15px' : '15px 10px 0 50px',
        textAlign: props => props.isEmpty ? 'center' : 'initial'
    },
    singleNotification: {
        borderRadius: '10px',
        backgroundColor: 'white',
        color: 'black',
        width: '100%',
        "& .MuiBox-root": {
            padding: theme.spacing(1, 2),
        },
        border: '1px solid ' + theme.palette.secondary.main,
    }
}))

/**
 * Notification message box display component
 * @author dame.gjorgjievski
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const NotificationBox = React.forwardRef((props, ref) => {

    const classes = styles(props)
    const crossRef = useRef()
    const auth = useAuth()

    return <Box pt={2}
        onClick={props.isSingle ? null : (event) => props.click(event, crossRef, props.message)}
        justifyContent="flex-end"
        ref={ref}
        className={props.isSingle ? classes.singleNotification : classes.wrapper}>
        <CardHeader className={classes.header}
            component="div"
            title={<>
                <Typography component="p" className={classes.textHeader}>
                    {!props.isEmpty &&
                        DateUtil.format(DateUtil.fromUtc(new Date(props.message.timeCreated)), DateUtil.FORMAT_FULL)}
                </Typography>
                <Typography component="p" className={classes.textHeader}>
                    {!props.isEmpty && `from ${props.message.user.name} ${props.message.user.surname}`}
                </Typography>
            </>}
            titleTypographyProps={{ variant: 'body1' }}
            action={auth.hasRole(Role.SUPER)
                ? props.isSingle
                    ? null
                    : <Tooltip title="Remove message for all users" placement="bottom" ransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                        <IconButton ref={crossRef} size="small" onClick={props.onDelete}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                : null
            }
        />
        <CardContent className={classes.body}>
            <Typography component="p" className={props.isSingle ? classes.singleText : classes.text}>
                {!props.isEmpty ? props.message.text : "No New Notifications."}
            </Typography>
        </CardContent>
    </Box>
})

NotificationBox.propTypes = {
    message: PropTypes.object,
    avatars: PropTypes.bool
};

NotificationBox.defaultProps = {
    message: {},
    avatars: true
}

export default NotificationBox