import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Xls icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function XlsIcon() {
    return (
        <SvgIcon viewBox="0 0 57.758 77.01">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor={theme.palette.secondary.main}/>
                <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_927" data-name="Path 927" d="M55.637-20.263,43.02-32.879A7.219,7.219,0,0,0,37.923-35H7.218A7.243,7.243,0,0,0,0-27.767V34.792A7.221,7.221,0,0,0,7.218,42.01H50.539a7.221,7.221,0,0,0,7.218-7.218V-15.15A7.254,7.254,0,0,0,55.637-20.263ZM38.51-30.1a2.367,2.367,0,0,1,1.113.632L52.239-16.85a2.365,2.365,0,0,1,.632,1.113H38.51Zm14.436,64.89A2.414,2.414,0,0,1,50.539,37.2H7.218a2.414,2.414,0,0,1-2.407-2.407V-27.767a2.425,2.425,0,0,1,2.407-2.42H33.7v15.653a3.592,3.592,0,0,0,3.61,3.6h15.64ZM9.626-3.715V29.977a2.406,2.406,0,0,0,2.407,2.407H45.725a2.406,2.406,0,0,0,2.407-2.407V-3.715a2.406,2.406,0,0,0-2.407-2.407H12.033A2.406,2.406,0,0,0,9.626-3.715ZM43.318,27.571H31.285v-7.22H43.318Zm0-12.033H31.285V8.318H43.318ZM14.439-1.308H43.318V3.505H14.439Zm0,9.626H26.472v7.22H14.439Zm0,12.033H26.472v7.22H14.439Z" transform="translate(0 35)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
