import {createStyles, makeStyles} from "@material-ui/core/styles";

export const styles = makeStyles((theme) => createStyles({
    title: {
        color: theme.palette.tertiary.var2,
        fontWeight: 'bold',
        display: 'inline-box',
        verticalAlign: 'middle'
    },
    titleFlag: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontWeight: 'bold',
        borderRadius: theme.spacing(0.5),
        display: 'inline-box',
        padding: theme.spacing(0.5, 1),
        verticalAlign: 'middle',
        marginLeft: theme.spacing(2)
    },
    titleWrap: {
        marginBottom: theme.spacing(3)
    },
    wrapper: {
        maxWidth: 1500
    },
    player: {
        position: 'absolute',
        top: 0,
        left: 0
    },
    iframeWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        paddingTop: '51%',
        background: 'black',
        '& iframe':{
            position:"absolute",
            top:0,
            left:0,
            width:"100%",
            height:"100%"
        }
    },
    playerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        paddingTop: '51%',
        background: 'black',
        '& iframe':{
            position:"absolute",
            top:0,
            left:0,
            width:"100%",
            height:"100%",
            pointerEvents: 'none'
        }
    },
    controls: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.var2,
        opacity: 0.9,
        borderRadius: 20,
        height: 48,
    },
    controlsGrid: {
        marginTop: -theme.spacing(10)
    },
    track: {
        width: '100%'
    },
    controlWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        maxWidth: theme.spacing(100),
        justifyContent: 'center',
    },
    control: {
        color: theme.palette.tertiary.var2,
        height: '100%',
        padding: 0,
        cursor: 'pointer',
        verticalAlign: 'middle'
    },
    time: {
        color: theme.palette.tertiary.var2,
        fontSize: '1.1rem'
    },
    timeWrapper: {
        display:'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    description: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    volume: {
        width: '100%'
    },
    area: {
        backgroundColor: theme.palette.primary.var2,
        borderRadius: 20,
        display: 'inline-flex',
        alignItems: 'center',
        minHeight: theme.spacing(8),
        padding: theme.spacing(0, 1),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 2)
        },
        '&:last-child': {
            marginTop: theme.spacing(1),
            [theme.breakpoints.up(1024)]: {
                marginTop: 0
            }
        }
    },
    areaWrapper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        flexDirection: 'column',
        [theme.breakpoints.up(1024)]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    },
    label: {
        display: "inline-block",
        verticalAlign: 'middle',
        margin: '0 10px 0 0',
        fontWeight: 'bold',
        color: theme.palette.tertiary.var2,
        [theme.breakpoints.up('md')]: {
            margin: '0 10px',
        }
    },
    sponsorsWrap: {
        margin: theme.spacing(-2),
        '& .sponsor': {
            width: '100px',
            display: 'inline-flex',
            padding: theme.spacing(2),
            '& img': {
                width: '100%',
                objectFit: 'contain'
            }
        }
    },
    sessionRoomHead: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sessionNavBtn: {
        display: 'flex'
    },
    button: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
        marginLeft: '10px',
        color: 'white',
        width: '170px',
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: theme.palette.quaternary.var2,
            color: theme.palette.quaternary.var4,
        },
        'span': {
            pointerEvents: 'none'
        }
    },
}));