import ChatMessages from "./ChatMessages"
import PropTypes from "prop-types"
import React, {useEffect, useRef, useState} from "react"
import {
    Avatar,
    Badge,
    Box,
    CardContent,
    CardHeader,
    Collapse,
    Divider,
    IconButton,
    makeStyles,
    TextField,
    Typography,
    useTheme
} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import {useDispatch} from "react-redux"
import {addRoom, removeRoom} from "../data/Store"
import {Autocomplete} from "@material-ui/lab"
import UserService from "../services/UserService"
import RoomService from "../services/RoomService";
import {useAuth} from "./ProvideAuth";
import {EventType, MessageType} from "../data/Constants";
import FileService from "../services/FileService";
import {useTranslation} from "react-i18next";
import {apiFacade} from "../data/ApiFacade";
import FuncUtil from "../utils/FuncUtil";

const styles = makeStyles((theme) => ({
    chatBox: {
        width: 270,
        background: theme.palette.common.white
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.var1,
    },
    header: {
        padding: theme.spacing(0.5),
        cursor: 'pointer',
        '& .MuiCardHeader-action': {
            marginTop: 0,
            marginLeft: 0
        },
        '& .MuiBadge-root': {
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            '& .MuiBadge-anchorOriginTopRightRectangle': {
                backgroundColor: theme.palette.secondary.main,
            }
        }
    },
    title: {
        fontWeight: 'bold'
    },
    content: {
        paddingBottom: theme.spacing(2),
        paddingTop: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        '& .MuiAutocomplete-popper': {
            marginTop: 50,
            fontWeight: 'bold',
            color: 'black'
        }
    },
    option: {
        zIndex: 1000001
    }
}))

/**
 * Collapsable chat box component. Provides open and close feature, as well as target user display
 * and pending messages badge
 * @author dame.gjorgjievski
 */
export default function ChatBox(props) {

    const DEBOUNCE_SEEN = 5000
    const {t} = useTranslation()
    const auth = useAuth()
    const theme = useTheme()
    const classes = styles()
    const dispatch = useDispatch()
    const [users, setUsers] = useState([])
    const [open, setOpen] = useState(props.room ? props.room.open : false)
    const openRef = useRef(open)
    const [count, setCount] = useState(0)
    const countRef = useRef(count)

    useEffect(() => {
        setCount(props.room.count)
        countRef.current = props.room.count
        apiFacade.on(EventType.MESSAGE, onMessageEvent)
        return () => {
            apiFacade.off(EventType.MESSAGE, onMessageEvent)
        }
    }, [props.room])

    const onMessageEvent = (e) => {
        let m = JSON.parse(e.data.message)
        if (!props.room || props.room.id !== m.roomId) return
        if (openRef.current) FuncUtil.debounce(updateSeenStatus, DEBOUNCE_SEEN)(props.room.id)
        else {
            let count = (countRef.current || 0) + 1
            setCount(count)
            countRef.current = count
            console.log("setting msg count", count)
        }
    }

    const onClose = (e) => {
        dispatch(removeRoom(props.room))
    }

    const toggle = () => {
        props.room.open = !open
        setOpen(props.room.open)
        openRef.current = props.room.open
        if (props.room.open) FuncUtil.debounce(updateSeenStatus, DEBOUNCE_SEEN)(props.room.id)
    }

    const updateSeenStatus = (roomId) => () => {
        RoomService.updateSeenStatus(roomId)
        setCount(0)
        countRef.current = 0
    }

    const queryUsers = (query) => () => {
        UserService.query(query).then(response => {
            setUsers(response.data)
        })
    }

    return <Box className={classes.chatBox} border={1} borderColor="background.shadow">
        <CardHeader className={classes.header} component="div" onClick={toggle}
                    title={
                        <Typography variant="subtitle2" component="p" style={{maxHeight: 20, overflow: 'hidden'}}>
                            {props.room.users[0] ? (props.room.users[0].name + ' ' + props.room.users[0].surname) :
                                t("client.chat.labels.new-conversation")}
                        </Typography>
                    }
                    action={
                        <IconButton onClick={onClose}><Close/></IconButton>
                    }
                    titleTypographyProps={{variant: 'body1'}}
                    avatar={<Badge badgeContent={count}>
                        <Avatar className={classes.avatar} src={props.room.users[0] && props.room.users[0].image ?
                            FileService.url(props.room.users[0].image.uri) : ''}>
                            {props.room.users[0] && props.room.users[0].name.split("")[0].toUpperCase()}
                        </Avatar>
                    </Badge>}>
        </CardHeader>
        <Divider/>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent className={classes.content} style={{height: props.room.users[0] ? 310 : 180}}>
                {!props.room.users[0] &&
                <Autocomplete
                    style={{marginTop: theme.spacing(2)}}
                    value={props.room.users[0]}
                    autoComplete
                    disablePortal
                    options={users}
                    filterOptions={(x) => x}
                    onInputChange={(event, value) => {
                        FuncUtil.debounce(queryUsers, 2000)(value)
                    }}
                    onChange={(e, value) => {
                        if (value.id === auth.user.id) {
                            props.room.users[0] = null
                            return
                        }
                        RoomService.provideChatRoom(value.id).then(response => {
                            dispatch(removeRoom(props.room))
                            dispatch(addRoom({...response.data, open: true}))
                            console.log("retrieved chat room", response.data)
                        })
                        console.log("User change event", e)
                    }}
                    getOptionLabel={(option) => option.name + " " + option.surname}
                    renderInput={(params) => (
                        <TextField autoFocus {...params} label={t('client.chat.actions.select-user')} variant="outlined" fullWidth/>
                    )}
                    renderOption={(option, state) => {
                        return <div className={classes.option}><Typography variant="body1" color="textSecondary">
                            {option.name + ' ' + option.surname}
                        </Typography></div>
                    }}
                />}
                <ChatMessages type={MessageType.CHAT} room={props.room} avatars={false}/>
            </CardContent>
        </Collapse>
    </Box>
}

ChatBox.propTypes = {
    room: PropTypes.object
};

ChatBox.defaultProps = {
    room: {}
}
