/**
 * String manipulation utility class
 * @author dame.gjorgjievski
 */
export default class StringUtil {

    static ENTITIES = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ]

    /**
     * Checks if a given string is null or empty
     * @param string specified string to check
     * @returns {boolean} true if string is empty, orherwise false
     */
    static isEmpty(string): Boolean {
        return string == null || string.trim().length === 0
    }

    /**
     * Decodes (unescapes) encoded (escaped) string symbols
     * @param string escaped string
     * @return result as decoded / unescaped string
     */
    static unescape(string): String {
        let result = string
        this.ENTITIES.forEach(e => {
            result = result.replace(new RegExp('&'+e[0]+';', 'g'), e[1]);
        })
        return result
    }

}