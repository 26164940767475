import { ENDPOINT_USERS } from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";
import { UserAction, Visibility, SocialMediaName } from "../data/Constants";
import RoomService from "./RoomService";
import { addRoom, setUser, store } from "../data/Store";
import GAEvent from "../models/GAEvent";

/**
 * Authentication service, manages CRUD operations for user data
 * @author dame.gjorgjievski
 **/
const UserService = new ApiService(ENDPOINT_USERS, ["name", "surname", "email", "phone"])

/**
 * User registry
 * @type {*[]}
 */
UserService.registry = {}

/**
 * Updates user profile
 * @param user profile information
 * @returns {Promise<AxiosResponse<any>>}
 */
UserService.updateProfile = function (user) {
    return axios.put(this.endpoint + "profile", user)
}

/**
 * Searches available users by keyword
 * @param keyword query string
 * @returns {Promise<AxiosResponse<any>>}
 */
UserService.query = function (keyword) {
    let filters = []
    if (keyword && keyword.trim().length >= 2) {
        const wrapper = { operator: 101, value: [] }
        this.properties.forEach(prop => {
            wrapper.value.push({ property: prop, operator: 7, value: "%" + keyword.trim() + "%" })
        })
        filters.push(wrapper)
        return this.find(0, 1000, filters, [])
    } else return new Promise((resolve, reject) => {
        resolve({ data: [] })
    })
}

/**
 * On user UI context action
 * @param action context action
 * @param user target user
 * @param event native event
 */
UserService.onAction = function (action, user, event) {

    event.preventDefault()
    console.log("action", action, event)
    switch (action) {
        case UserAction.MAIL:
            window.open('mailto:' + user.email, 'mail')
            break;
        case UserAction.MESSAGE:
            RoomService.provideChatRoom(user.id).then(response => {
                let room = response.data
                room.users[0] = user
                store.dispatch(addRoom({ ...room, open: true }))
                console.log("retrieved chat room", room)
            })
            break;
        case UserAction.LINK:
            switch (event.currentTarget.name) {
                case SocialMediaName.LINKEDIN:
                    window.open(user.socialMedia[SocialMediaName.LINKEDIN], '_blank')
                    break;
                case SocialMediaName.FACEBOOK:
                    window.open(user.socialMedia[SocialMediaName.FACEBOOK], '_blank')
                    break;
                case SocialMediaName.INSTAGRAM:
                    window.open(user.socialMedia[SocialMediaName.INSTAGRAM], '_blank')
                    break;
                case SocialMediaName.TWITTER:
                    window.open(user.socialMedia[SocialMediaName.TWITTER], '_blank')
                    break;
                case SocialMediaName.WEBSITE:
                    window.open(user.socialMedia[SocialMediaName.WEBSITE], '_blank')
                    break;
                default:
                    break
            }
            // window.open("http://twitter.com/saigowthamr");
            break;
        default:
            break;
    }
}

/**
 * Finds users by email
 * @param email query address
 * @returns {Promise<AxiosResponse<any>>}
 */
UserService.findByEmail = function (email) {
    if (this.registry[email]) return new Promise((resolve, reject) => {
        resolve({ data: [this.registry[email]] })
    })
    this.registry[email] = {}
    let filters = [{ property: "email", operator: 0, value: email }]
    let result = this.find(0, 1000, filters, [])
    let that = this
    result.then(response => {
        that.registry[email] = response.data[0]
    })
    return result
}

/**
 * Triggers an action to view an user profile
 * @param dispatch state dispatcher
 * @param analytics instance of analytics
 * @param user target user to display profile for
 */
UserService.viewProfile = function (dispatch, analytics, user) {
    if (!user || user.visibility !== Visibility.VISIBLE) return
    dispatch(setUser(user))
    analytics.event(new GAEvent(GAEvent.Category.NAV, GAEvent.Action.PROFILE_VIEW,
        user.name + ' ' + user.surname, user.id))
}

UserService.deleteUserImage = function (userId) {
    return axios.delete(this.endpoint + "userImage/" + userId)
}

UserService.saveUserImage = function (userId, image) {
    return axios.put(this.endpoint + "userImage/" + userId, image)
}

UserService.getUserById = function (userId) {
    return axios.get(this.endpoint + userId)
}

export default UserService