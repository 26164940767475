import PropTypes from "prop-types";
import ReactWordcloud from "react-wordcloud";
import React, {useEffect, useState} from "react";
import SessionService from "../services/SessionService";
import StringUtil from "../utils/StringUtil";
import {Box, TextField} from "@material-ui/core";
import {styles} from '../styles/ChatStyle'
import {useTranslation} from "react-i18next";

/**
 * Word cloud component wrapper, wraps configuration data and text parsing for display
 * @author dame.gjorgjievski
 */
export default function WordCloud(props) {

    const {t} = useTranslation()
    const LIMIT = 100
    const [words, setWords] = useState([])
    const [text, setText] = useState("")
    const classes = styles()
    const options = {
        colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"], transitionDuration: 0,
        enableTooltip: false, deterministic: true, fontFamily: "ProximaNova", fontSizes: [20, 45],
        fontStyle: "normal", fontWeight: "normal", padding: 1, rotations: 3, rotationAngles: [0, 90],
        scale: "sqrt", spiral: "archimedean", randomSeed: "ark3#zxy", enableOptimizations: false
    }

    useEffect(() => {
        if (!props.session || !props.session.id) return
        SessionService.loadWordCloud(props.session.id).then(response => {
            handleResponse(response)
        }).catch(e => {
            setWords(parse(props.text))
        })
    }, [props.session])

    const parse = (text) => {
        let terms = [], weights = [], words = [], parts = text.split(/[\s,]+/)
        for (let i in parts) {
            let part = parts[i]
            let idx = terms.indexOf(part)
            if (idx === -1) {
                terms.push(part)
                weights.push(1)
            } else weights[idx] = weights[idx] + 1
        }
        for (let i in terms) {
            words.push({text: terms[i], value: weights[i]})
        }
        return words
    }

    const onTextChange = (e) => {
        setText(e.target.value.substr(0, Math.min(e.target.value.length, LIMIT)))
    }

    const onKeyPress = (e) => {
        // handle enter key
        if (e.charCode === 13 && props.session.id != null) {
            e.preventDefault()
            SessionService.addWordCloud(props.session.id, text).then(response => {
                handleResponse(response)
            })
        }
    }

    const handleResponse = (response) => {
        console.log("word cloud response", response)
        if (StringUtil.isEmpty(response.data.content)) {
            if (!StringUtil.isEmpty(props.session.description) && props.session.description.length > 100)
                setWords(parse(props.session.description))
            else setWords(parse(props.text))
        } else setWords(parse(response.data.content))
        if (response.data.input) setText(response.data.input.content)
    }

    return <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <Box display="flex" flexDirection="column">
            <ReactWordcloud words={words} options={options} minSize={[200, 450]}/>
        </Box>
        <Box>
            <TextField size="medium" margin="none" className={classes.inputChat} variant="filled"
                       style={{padding: 0}} placeholder={t('client.session.labels.word-cloud-hint')} value={text}
                       multiline onKeyPress={onKeyPress} onChange={onTextChange}
                       InputProps={{disableUnderline: true}}/>
        </Box>
    </Box>
}

WordCloud.propTypes = {
    text: PropTypes.string,
    session: PropTypes.object
}

WordCloud.defaultProps = {
    text: "The quick brown fox jumps over the yellow fence",
    session: null
}
