import {createStyles, makeStyles} from '@material-ui/core/styles';

/**
 * Event sponsor page view style
 * @type {(props?: any) => ClassNameMap<"videoBox" | "teamAvatar" | "infoBottomItem" | "teamItem" | "infoItem" | "rootPaper" | "actionIcons">}
 * @author petar.todorovski
 */
export const styles = makeStyles((theme) => createStyles({
    root: {
        display:'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 5),
        },
    },
    rootPaper: {
        minHeight: 350,
        padding: theme.spacing(5, 0),
        backgroundColor: theme.palette.primary.var1,
        borderRadius: 10,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        '& .logo-box': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing(2),
            overflow: 'hidden',
            maxWidth: 200,
            height: 130,
            marginLeft: theme.spacing(-1.25),
            borderRadius: 10,
            backgroundColor: theme.palette.common.white,
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(-2.5),
                maxWidth: 300
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(-5),
            },
            // [theme.breakpoints.up('lg')]: {
            //     marginLeft: theme.spacing(-5),
            // },
        },
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    },
    infoItem: {
        color: theme.palette.primary.main,
        '& .info-sponsor-title': {
            color: theme.palette.tertiary.var2
        }
    },
    sponsorTeamTitle: {
        color: theme.palette.tertiary.var2
    },
    videoBox: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            minHeight: 440,
            paddingTop: theme.spacing(5)
        },
        '& video': {
            width: '100%',
            height: '100%',
            borderRadius: 10
        }
    },
    teamBox: {
        borderRadius: 10,
        backgroundColor: theme.palette.primary.var2,
        padding: theme.spacing(1, 2)
    },
    infoBottomItem: {
        marginTop: theme.spacing(3),
        '& .doc-title': {
            color: theme.palette.tertiary.var2
        },
        '& .document-box': {
            width: '100%',
            maxWidth: 220,
            borderRadius: 10,
            marginBottom: theme.spacing(5),
            '& span': {
                marginLeft: theme.spacing(2)
            }
        }
    },
    sponsorLink: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(5),
        '& .social-icons': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: theme.spacing(22),
            zIndex: '10001',
            '& a': {
                cursor: 'pointer'
            }
        }
    },
    socialLabel: {
        color: theme.palette.tertiary.var2
    },
    preWrappedText: {
        whiteSpace: 'pre-wrap'
    }
}))
