import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { theme } from '../styles/Theme';

/**
 * Instagram icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function InstagramIcon({ fontsize }) {
    return (
        <SvgIcon viewBox="0 0 19.261 19.256" fontSize={fontsize}>
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main} />
                    <stop offset="1" stopColor={theme.palette.primary.var3} />
                </linearGradient>
            </defs>
            <path id="Path_933" data-name="Path 933" d="M9.629-13.191A4.929,4.929,0,0,0,4.692-8.254,4.929,4.929,0,0,0,9.629-3.317a4.929,4.929,0,0,0,4.937-4.937A4.929,4.929,0,0,0,9.629-13.191Zm0,8.147a3.216,3.216,0,0,1-3.21-3.21,3.213,3.213,0,0,1,3.21-3.21,3.213,3.213,0,0,1,3.21,3.21A3.216,3.216,0,0,1,9.629-5.045Zm6.291-8.349a1.152,1.152,0,0,0-1.152-1.152,1.152,1.152,0,0,0-1.152,1.152,1.149,1.149,0,0,0,1.152,1.152A1.149,1.149,0,0,0,15.92-13.393Zm3.27,1.169a5.7,5.7,0,0,0-1.555-4.035A5.736,5.736,0,0,0,13.6-17.815c-1.59-.09-6.355-.09-7.945,0A5.728,5.728,0,0,0,1.62-16.264,5.717,5.717,0,0,0,.064-12.229c-.09,1.59-.09,6.355,0,7.945A5.7,5.7,0,0,0,1.62-.249,5.744,5.744,0,0,0,5.655,1.306c1.59.09,6.355.09,7.945,0A5.7,5.7,0,0,0,17.634-.249,5.736,5.736,0,0,0,19.19-4.284C19.28-5.874,19.28-10.635,19.19-12.225ZM17.136-2.578a3.249,3.249,0,0,1-1.83,1.83c-1.268.5-4.275.387-5.676.387S5.216-.249,3.953-.748a3.249,3.249,0,0,1-1.83-1.83c-.5-1.268-.387-4.275-.387-5.676s-.112-4.413.387-5.676a3.249,3.249,0,0,1,1.83-1.83c1.268-.5,4.275-.387,5.676-.387s4.413-.112,5.676.387a3.249,3.249,0,0,1,1.83,1.83c.5,1.268.387,4.275.387,5.676S17.639-3.841,17.136-2.578Z"
                transform="translate(0.003 17.883)" fill={theme.palette.primary.var3} />
        </SvgIcon>
    )
}

