import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Globe button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function GlobeIcon({className, fontsize}) {
    return (
        <SvgIcon className={`${className}`} width="19.375" height="19.375" viewBox="0 0 19.375 19.375" fontSize={fontsize}>
            <path id="Path_749" data-name="Path 749"
                  d="M9.688-17.187A9.686,9.686,0,0,0,0-7.5,9.686,9.686,0,0,0,9.688,2.188,9.686,9.686,0,0,0,19.375-7.5,9.686,9.686,0,0,0,9.688-17.187Zm7.547,5.938H14.012a11.368,11.368,0,0,0-1.688-4.262A8.474,8.474,0,0,1,17.234-11.25ZM13.125-7.5A18.461,18.461,0,0,1,12.957-5H6.418A18.461,18.461,0,0,1,6.25-7.5,18.461,18.461,0,0,1,6.418-10h6.539A18.461,18.461,0,0,1,13.125-7.5ZM9.688-15.937c1.051,0,2.4,1.723,3.051,4.688h-6.1C7.289-14.215,8.637-15.937,9.688-15.937Zm-2.637.426A11.379,11.379,0,0,0,5.363-11.25H2.141A8.474,8.474,0,0,1,7.051-15.512ZM1.25-7.5A8.385,8.385,0,0,1,1.629-10H5.164A19.805,19.805,0,0,0,5-7.5,19.105,19.105,0,0,0,5.164-5H1.629A8.385,8.385,0,0,1,1.25-7.5Zm.891,3.75H5.363A11.368,11.368,0,0,0,7.051.512,8.474,8.474,0,0,1,2.141-3.75ZM9.688.938c-1.051,0-2.4-1.723-3.051-4.687h6.1C12.086-.785,10.738.938,9.688.938ZM12.324.512A11.379,11.379,0,0,0,14.012-3.75h3.223A8.474,8.474,0,0,1,12.324.512ZM14.211-5a19.805,19.805,0,0,0,.164-2.5,19.105,19.105,0,0,0-.164-2.5h3.535a8.385,8.385,0,0,1,.379,2.5A8.385,8.385,0,0,1,17.746-5Z"
                  transform="translate(0 17.188)" fill={theme.palette.primary.var3}/>
        </SvgIcon>
    )
}