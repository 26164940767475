import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Slide, Tab, Tabs, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Close } from '@material-ui/icons';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import BadFeedBackIcon from "../assets/BadFeedBackIcon";
import GoodFeedBackIcon from "../assets/GoodFeedBackIcon";
import PositiveFeedBackIcon from "../assets/PositiveFeedBackIcon";
import UserAvatarList from '../components/UserAvatarList';
import { useAnalytics } from '../data/Analytics';
import { Visibility } from '../data/Constants';
import SessionService from '../services/SessionService';
import UserService from '../services/UserService';

/**
 * Displays a session feedback component
 * @param props component properties
 * @author dame.gjorgjievski
 */
export default function SessionFeedback(props) {

    const [openDialog, setOpenDialog] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [state, setState] = useState({ "0": [], "1": [], "2": [] });
    const [loading, setLoading] = useState(false);
    const [openPositiveTooltip, setOpenPositiveTooltip] = useState(false);
    const [openGoodTooltip, setOpenGoodTooltip] = useState(false);
    const [openBadTooltip, setOpenBadTooltip] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const classes = makeStyles((theme) => createStyles({
        icon: {
            width: 30,
            height: 30,
            cursor: 'pointer',
            [theme.breakpoints.up('sm')]: {
                width: 45,
                height: 45,
            }
        },
        label: {
            display: "inline-block",
            verticalAlign: 'middle',
            margin: '0 10px',
            fontWeight: 800,
            color: theme.palette.tertiary.var2,
            [theme.breakpoints.up('md')]: {
                margin: '0 5px'
            },
            '&:first-child': {
                margin: '0 10px 0 0',
                [theme.breakpoints.up('md')]: {
                    margin: '0 10px'
                },
            }
        },
        tooltip: {
            pointerEvents: 'all'
        },
        dialogTab: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        dialogHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    }))();


    useEffect(() => {
        SessionService.loadFeedback(props.id).then(result => {
            setState(result.data)
        })
    }, [])

    const addFeedback = (event, type) => {
        event.preventDefault()
        SessionService.addFeedback(props.id, type).then(result => {
            setState(result.data)
        })
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handeleAvatarGroupSelect = tabId => {
        setOpenDialog(true);
        setTabValue(tabId);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleUserSelect = user => {
        setLoading(true)
        UserService.getUserById(user.userId).then(result => {
            const user = result.data
            setLoading(false)
            if (user.visibility === Visibility.VISIBLE) {
                UserService.viewProfile(dispatch, analytics, user)
            }
        }).catch((e) => {
            setLoading(false)
            console.error(e)
        })
    };

    const handleTooltipClose = stateId => {
        switch (stateId) {
            case 0:
                setOpenPositiveTooltip(false);
                break;
            case 1:
                setOpenGoodTooltip(false);
                break;
            case 2:
                setOpenBadTooltip(false);
                break;
            default:
                break;
        }
    };

    const handleTooltipOpen = (state, stateId) => {
        if (state && state.length > 0) {
            switch (stateId) {
                case 0:
                    setOpenPositiveTooltip(true);
                    break;
                case 1:
                    setOpenGoodTooltip(true);
                    break;
                case 2:
                    setOpenBadTooltip(true);
                    break;
                default:
                    break;
            }
        }
    };

    return <Box display="flex" alignItems="center">
        <Typography variant="body1" component="span" className={classes.label}>{t('client.session.labels.feedback')}</Typography>

        <Tooltip open={openPositiveTooltip}
            onOpen={() => handleTooltipOpen(state['0'], 0)}
            onClose={() => handleTooltipClose(0)}
            interactive placement="bottom" classes={{ tooltip: classes.tooltip }}
            TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}
            title={
                <React.Fragment>
                    <div className={classes.tooltipAvatar}>
                        <UserAvatarList
                            onAvatarGroupSelect={handeleAvatarGroupSelect}
                            condensed max={3}
                            users={state['0']}
                            tabId={0} />
                    </div>
                </React.Fragment>
            }
        >
            <div className={classes.icon} onClick={(e) => addFeedback(e, 0)}>
                <PositiveFeedBackIcon />
            </div>
        </Tooltip>
        <div className={classes.label}>{state['0'] && state['0'].length}</div>

        <Tooltip open={openGoodTooltip}
            onOpen={() => handleTooltipOpen(state['1'], 1)}
            onClose={() => handleTooltipClose(1)}
            interactive placement="bottom" classes={{ tooltip: classes.tooltip }}
            TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}
            title={
                <React.Fragment>
                    <div className={classes.tooltipAvatar}>
                        <UserAvatarList
                            onAvatarGroupSelect={handeleAvatarGroupSelect}
                            condensed max={3}
                            users={state['1']}
                            tabId={1} />
                    </div>
                </React.Fragment>
            }
        >
            <div className={classes.icon} onClick={(e) => addFeedback(e, 1)}>
                <GoodFeedBackIcon />
            </div>
        </Tooltip>
        <div className={classes.label}>{state['1'] && state['1'].length}</div>

        <Tooltip open={openBadTooltip}
            onOpen={() => handleTooltipOpen(state['2'], 2)}
            onClose={() => handleTooltipClose(2)}
            interactive placement="bottom" classes={{ tooltip: classes.tooltip }}
            TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}
            title={
                <React.Fragment>
                    <div className={classes.tooltipAvatar}>
                        <UserAvatarList
                            onAvatarGroupSelect={handeleAvatarGroupSelect}
                            condensed max={3}
                            users={state['2']}
                            tabId={2} />
                    </div>
                </React.Fragment>
            }
        >
            <div className={classes.icon} onClick={(e) => addFeedback(e, 2)}>
                <BadFeedBackIcon />
            </div>
        </Tooltip>
        <div className={classes.label}>{state['2'] && state['2'].length}</div>

        <Dialog
            onClose={handleDialogClose}
            aria-labelledby="user-feedback"
            open={openDialog}
            fullWidth
            maxWidth={'sm'} >
            <DialogTitle id="registration-title">
                <div className={classes.dialogHeader}>
                    <div>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="user-feedbacks-tabs">
                            {state['0'] && state['0'].length > 0
                                ? <Tab label={
                                    <div className={classes.dialogTab}>
                                        <PositiveFeedBackIcon width='25' height='25' />
                                        <div className={classes.label}>{state['0'].length}</div>
                                    </div>} value={0} />
                                : null
                            }
                            {state['1'] && state['1'].length > 0
                                ? <Tab label={
                                    <div className={classes.dialogTab}>
                                        <GoodFeedBackIcon width='25' height='25' />
                                        <div className={classes.label}>{state['1'].length}</div>
                                    </div>} value={1} />
                                : null
                            }
                            {state['2'] && state['2'].length > 0
                                ? <Tab label={
                                    <div className={classes.dialogTab}>
                                        <BadFeedBackIcon width='25' height='25' />
                                        <div className={classes.label}>{state['2'].length}</div>
                                    </div>} value={2} />
                                : null
                            }
                        </Tabs>
                    </div>
                    {loading
                        ? <CircularProgress size={18} style={{ marginRight: '15px' }} />
                        : <IconButton onClick={() => setOpenDialog(false)}>
                            <Close />
                        </IconButton>
                    }
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <TabPanel value={tabValue} index={0}>
                    <UserAvatarList onSelect={handleUserSelect} fromDialog
                        users={state['0']} userWidth='100px' userHeight='100px' max={state['0'] && state['0'].length + 1} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <UserAvatarList onSelect={handleUserSelect} fromDialog
                        users={state['1']} userWidth='100px' userHeight='100px' max={state['1'] && state['1'].length + 1} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <UserAvatarList onSelect={handleUserSelect} fromDialog
                        users={state['2']} userWidth='100px' userHeight='100px' max={state['2'] && state['2'].length + 1} />
                </TabPanel>
            </DialogContent>
        </Dialog>
    </Box>
}

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

SessionFeedback.propTypes = {
    id: PropTypes.number
}
SessionFeedback.defaultProps = {
    id: 0
}
