import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Globe social icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function GlobeSocialIcon() {
    return (
        <SvgIcon viewBox="0 0 25.188 25.188">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main}/>
                    <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_914" data-name="Path 914" d="M12.594-22.344A12.592,12.592,0,0,0,0-9.75,12.592,12.592,0,0,0,12.594,2.844,12.592,12.592,0,0,0,25.188-9.75,12.592,12.592,0,0,0,12.594-22.344ZM22.4-14.625H18.215a14.778,14.778,0,0,0-2.194-5.54A11.016,11.016,0,0,1,22.4-14.625ZM17.063-9.75a24,24,0,0,1-.218,3.25h-8.5a24,24,0,0,1-.218-3.25A24,24,0,0,1,8.343-13h8.5A24,24,0,0,1,17.063-9.75ZM12.594-20.719c1.366,0,3.118,2.239,3.966,6.094H8.628C9.476-18.479,11.228-20.719,12.594-20.719Zm-3.428.554a14.792,14.792,0,0,0-2.194,5.54H2.783A11.016,11.016,0,0,1,9.166-20.165ZM1.625-9.75A10.9,10.9,0,0,1,2.118-13h4.6A25.747,25.747,0,0,0,6.5-9.75,24.837,24.837,0,0,0,6.713-6.5h-4.6A10.9,10.9,0,0,1,1.625-9.75ZM2.783-4.875H6.972A14.778,14.778,0,0,0,9.166.665,11.016,11.016,0,0,1,2.783-4.875Zm9.811,6.094c-1.366,0-3.118-2.239-3.966-6.094H16.56C15.712-1.021,13.96,1.219,12.594,1.219ZM16.021.665a14.792,14.792,0,0,0,2.194-5.54H22.4A11.016,11.016,0,0,1,16.021.665ZM18.474-6.5a25.747,25.747,0,0,0,.213-3.25A24.837,24.837,0,0,0,18.474-13h4.6a10.9,10.9,0,0,1,.493,3.25A10.9,10.9,0,0,1,23.07-6.5Z" transform="translate(0 22.344)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
