import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Replay menu icon
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function ReplayIcon() {
    return <SvgIcon width="32" height="26.231" viewBox="0 0 32 26.231">
        <g id="Group_285" data-name="Group 285" transform="translate(0 -0.001)">
            <path id="Path_142" data-name="Path 142"
                  d="M31.375,68.2H30.292a2.118,2.118,0,0,0,.192-.882V51.275a2.134,2.134,0,0,0-2.132-2.132H24.466a3.159,3.159,0,0,0-3.151-2.994H18.779a.625.625,0,1,0,0,1.25h2.535A1.908,1.908,0,0,1,23.22,49.3v7.814a1.908,1.908,0,0,1-1.906,1.906H15.8a.625.625,0,0,0-.4.141L13,61.123V59.649a.625.625,0,0,0-.625-.625H10.7a1.908,1.908,0,0,1-1.905-1.906V49.3A1.908,1.908,0,0,1,10.7,47.4h2.58a.625.625,0,1,0,0-1.25H10.7a3.159,3.159,0,0,0-3.151,2.994h-3.9a2.134,2.134,0,0,0-2.132,2.132V67.323a2.119,2.119,0,0,0,.192.882H.625A.625.625,0,0,0,0,68.83V70.45a1.932,1.932,0,0,0,1.93,1.93H30.07A1.932,1.932,0,0,0,32,70.45V68.83a.625.625,0,0,0-.625-.625ZM11.753,60.274v2.167a.631.631,0,0,0,1.021.484l3.245-2.651h5.3a3.159,3.159,0,0,0,3.155-3.156V53.038h1.974V65.56H5.555V53.038H7.543v4.081A3.159,3.159,0,0,0,10.7,60.274h1.054ZM2.766,67.323V51.275a.883.883,0,0,1,.882-.882h3.9v1.394H4.93a.625.625,0,0,0-.625.625V66.185a.625.625,0,0,0,.625.625H27.069a.625.625,0,0,0,.625-.625V52.413a.625.625,0,0,0-.625-.625h-2.6V50.393h3.882a.883.883,0,0,1,.882.882V67.323a.883.883,0,0,1-.881.882H3.647a.883.883,0,0,1-.881-.882ZM1.25,70.45v-1H11.693v.806a2.107,2.107,0,0,0,.187.87H1.93a.681.681,0,0,1-.68-.68Zm12.563.68a.871.871,0,0,1-.87-.87v-.806h6.115v.806a.871.871,0,0,1-.87.87Zm16.938-.68a.681.681,0,0,1-.68.68H20.12a2.106,2.106,0,0,0,.187-.87v-.806H30.75Z"
                  transform="translate(0 -46.148)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_143" data-name="Path 143"
                  d="M210.392,101.172v5.566a1.016,1.016,0,0,0,1.524.88l4.82-2.783a1.016,1.016,0,0,0,0-1.76l-4.82-2.783a1.016,1.016,0,0,0-1.524.88Zm1.25.405,4.118,2.378-4.118,2.378Z"
                  transform="translate(-197.242 -96.781)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_144" data-name="Path 144"
                  d="M247.2,47.036a.625.625,0,1,0,.338-.816A.63.63,0,0,0,247.2,47.036Z"
                  transform="translate(-231.707 -46.172)" fill={theme.palette.quaternary.var5}/>
        </g>
    </SvgIcon>
}