import DocIcon from "../assets/DocIcon"
import PdfIcon from "../assets/PdfIcon"
import XlsIcon from "../assets/XlsIcon"
import VideoIcon from "../assets/VideoIcon"
import TxtIcon from "../assets/TxtIcon"

/**
 * Get icon for file extension
 * @param className FileUtil
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default class FileUtil {

    /**
     * Retrieve a file icon based on file extension
     * @param file target file to extract icon for
     * @returns {JSX.Element}
     */
    static getIcon(file) {

        switch (file.ext) {
            case 'doc':
            case 'docx':
                return <DocIcon/>
            case 'txt':
                return <TxtIcon/>
            case 'xls':
            case 'xlsx':
                return <XlsIcon/>
            case 'pdf':
                return <PdfIcon/>
            case 'mov':
            case 'mp4':
            case 'avi':
                return <VideoIcon/>
            default:
                return <TxtIcon/>
        }
    }
}
