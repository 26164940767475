import PropTypes from "prop-types";
import {Avatar, Box, Typography} from "@material-ui/core";
import MessageIcon from "../assets/MessageIcon";
import CameraIcon from "../assets/CameraIcon";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {UserAction} from "../data/Constants";
import UserService from "../services/UserService";
import {useAuth} from "./ProvideAuth";
import {useTranslation} from "react-i18next";
import FileService from "../services/FileService";

export const styles = makeStyles((theme) => createStyles({
    actionIcons: {
        width: '100%',
        maxWidth: 60
    },
    avatar: {
        width: 45,
        height: 45,
        marginRight: theme.spacing(2.5),
        border: `2px solid ${theme.palette.secondary.main}`,
        cursor: 'pointer'
    },
    icon: {
        cursor: 'pointer'
    }
}))

/**
 * Component displaying a vertical list of user information
 * @author dame.gjorgjievski
 */
export default function UserInfoList(props) {

    const classes = styles()
    const auth = useAuth()
    const {t} = useTranslation()
    const getUserInfo = (user) => {
        let info = ""
        if (user.function) {
            info = user.function + (user.organization ? ' | ' + user.organization : '')
        } else info = t("")
        return info
    }

    return <div>
        {props.users.map((user, i) =>
            <Box key={"user" + i} py={1} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Avatar src={user.image ? FileService.url(user.image.uri) : ''} onClick={(e)=>props.onSelect(user, e)} className={classes.avatar}/>
                    <Box>
                        <Typography gutterBottom variant="body1" style={{fontWeight: 'bold'}}>
                            {user.name + ' ' + user.surname}</Typography>
                        <Typography variant="body2">{getUserInfo(user)}</Typography>
                    </Box>
                </Box>
                {auth.user.id !== user.id && <Box className={classes.actionIcons} display="flex" alignItems="center" justifyContent="space-between">
                    <MessageIcon className={classes.icon}
                                 onClick={e => UserService.onAction(UserAction.MESSAGE, user, e)}/>
{/*                     <CameraIcon className={classes.icon} onClick={e => UserService.onAction(UserAction.CALL, user, e)}/> */}
                </Box>}
            </Box>
        )}
    </div>
}

UserInfoList.propTypes = {
    users: PropTypes.array
}

UserInfoList.defaultProps = {
    users: []
}