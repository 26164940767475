import { ENDPOINT_EVENTS } from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";
import { Access } from "../data/Constants";

/**
 * Event service, manages CRUD operations for event data
 * @author dame.gjorgjievski
 **/
const EventService = new ApiService(ENDPOINT_EVENTS, ["name.en", "description.en"])

/**
 * Find all events for the current user
 * @returns {Promise<AxiosResponse<any>>}
 */
EventService.findUserEvents = function () {
    return axios.post(this.endpoint + "user", {
        filters: [],
        sorts: [{ property: "timeStart", desc: false }],
        page: 0,
        size: 5000
    })
}

/**
 * Initiates email sending to all event users
 * @param id event identifier
 * @param type mail type
 * @returns {Promise<AxiosResponse<any>>}
 */
EventService.sendMail = function (id, type, subject, message) {
    return axios.post(this.endpoint + id + "/mail/" + type, { messageSubject: subject, messageBody: message })
}

/**
 * Initiates send of notification message for an event
 * @param id event identifier
 * @param message notification message
 * @returns {Promise<AxiosResponse<any>>}
 */
EventService.sendNotification = function (id, message) {
    return axios.post(this.endpoint + id + "/notify", { message: message })
}

/**
 * Checks if user has access in an event
 * @param event what event
 * @param user which user
 * @returns {boolean} if user has access to an event, otherwise false
 */
EventService.hasAccess = function (user, event) {
    if (!user) return false
    if (Access.PUBLIC === event.access || event.user.id === user.id) return true
    const eventGroupUsers = [].concat.apply([], ((event.groups && event.groups.filter(g => g.eventId === event.id)) || []).map(g => g.users))
    return !!eventGroupUsers
}

EventService.getMessageBody = function (id) {
    return axios.get(this.endpoint + id + '/message')
}

EventService.saveMessageBody = function (id, message) {
    return axios.post(this.endpoint + id + "/message", { message: message })
}

export default EventService
