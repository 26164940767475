import PropTypes from "prop-types";
import {CircularProgress, Dialog, DialogContent, Slide} from "@material-ui/core";

/**
 * Modal progress component
 * @param props component properties
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function ProgressDialog(props) {

    return <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        disablePortal
        hideBackdrop
        TransitionComponent={Slide}
        TransitionProps={{timeout: 0}}
        maxWidth="xs"
        closeAfterTransition
        aria-modal="false"
        transitionDuration={0}
        aria-labelledby="progress-dialog"
        open={props.open}>
        <DialogContent dividers>
            <CircularProgress/>
        </DialogContent>
    </Dialog>
}

ProgressDialog.propTypes = {
    open: PropTypes.bool
}

ProgressDialog.defaultProps = {
    open: false
}