import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import {
    Avatar,
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import {styles} from "../styles/FormStyles"
import {requiredTranslation, requiredValidation} from "../utils/Validators"
import Alert from '@material-ui/lab/Alert';
import EventService from "../services/EventService";
import PageService from "../services/PageService";
import ProgressDialog from "../components/ProgressDialog";
import {Language, Path} from "../data/Constants";
import {useTranslation} from "react-i18next";
import EditInLanguageSelector from "../components/EditInLanguageSelector";

/**
 * Displays an event pages edit view
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 */
export default function PageEditView() {

    const {t} = useTranslation()
    const classes = styles()
    const history = useHistory()
    const [state, setState] = useState({
        name: {
            [Language.DEFAULT]: "New Page"
        },
        description: {
            [Language.DEFAULT]: "New Page Description"
        },
        label: {
            [Language.DEFAULT]: "New Page Label"
        },
        data: "",
        event: {id: 0}
    })
    let [errorz, setErrorz] = useState({
        name: "",
        description: "",
        label: "",
        data: "",
        event: ""
    })
    const [language, setLanguage] = useState(Language.current())
    const [errors, setErrors] = useState([])
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    let timeout = useRef(0)

    useEffect(() => {
        setLoading(parseInt(id) > 0)
        PageService.load(id).then((result) => {
            setState(result.data)
            setLoading(false)
        })
        EventService.find(0, 5000).then((result) => {
            setEvents(result.data)
        })
        return () => clearInterval(timeout.current)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        errorz = {...errorz, name: requiredTranslation("Page name", state.name)}
        errorz = {...errorz, description: requiredTranslation("Page description", state.description)}
        errorz = {...errorz, label: requiredTranslation("Page label", state.label)}
        errorz = {...errorz, data: requiredValidation("Page data", state.data)}
        if (state.event.id === 0) errorz = {...errorz, event: "Please select event"}
        setErrorz(errorz)
        clearErrors()
        let props = ["name", "description", "label", "data", "event"]
        for (let i in props) if (errorz[props[i]] && errorz[props[i]].length > 0) return

        setLoading(true)
        PageService.save(state).then((result) => {
            setLoading(false)
            history.push(Path.ADMIN_PAGES)
        }).catch((error) => {
            setLoading(false)
            setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearErrors()
        })
    }

    const handleChange = (field, e, value) => {
        switch (field) {
            case "name":
                const name = {...state.name, [language]: e.target.value}
                setState({...state, name})
                setErrorz({...errorz, name: requiredTranslation("Name", name)})
                break;
            case "description":
                const description = {...state.description, [language]: e.target.value}
                setState({...state, description})
                setErrorz({...errorz, description: requiredTranslation("Description", description)})
                break;
            case "label":
                const label = {...state.label, [language]: e.target.value}
                setState({...state, label})
                setErrorz({...errorz, label: requiredTranslation("Label", label)})
                break;
            case "data":
                setState({...state, [field]: e.target.value})
                setErrorz({...errorz, [field]: requiredValidation("Data", e.target.value)})
                break;
            case "event":
                let event = events.filter(ev => ev.id === e.target.value)[0] || {id: 0}
                setState({...state, event: event})
                setErrorz({...errorz, event: event.id === 0 ? "Please select event" : ""})
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        timeout.current = setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ProgressDialog open={loading}/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddBoxOutlined/>
                </Avatar>
                <Grid spacing={2} container direction="row" justify="center" alignItems="center">
                    <Grid item><Typography component="h1" variant="h5">{t('admin.page.title-edit')}</Typography></Grid>
                    <Grid item><EditInLanguageSelector language={language} onChange={e => {
                        setLanguage(e.target.value)
                    }}/></Grid>
                </Grid>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField className={classes.input}
                                           variant="outlined"
                                           margin="none"
                                           fullWidth
                                           required
                                           id="name"
                                           label={t('admin.default.fields.name') + " (" + language + ")"}
                                           name="name"
                                           autoComplete="name"
                                           autoFocus
                                           value={state.name[language] || ""}
                                           onChange={(e) => handleChange('name', e)}
                                           error={errorz.name !== ''}
                                           helperText={errorz.name}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField className={classes.input}
                                           variant="outlined"
                                           margin="none"
                                           fullWidth
                                           required
                                           id="description"
                                           label={t('admin.default.fields.description') + " (" + language + ")"}
                                           name="description"
                                           autoComplete="description"
                                           autoFocus
                                           value={state.description[language] || ""}
                                           onChange={(e) => handleChange('description', e)}
                                           error={errorz.description !== ''}
                                           helperText={errorz.description}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField className={classes.input}
                                           variant="outlined"
                                           margin="none"
                                           fullWidth
                                           required
                                           id="label"
                                           label={t('admin.page.fields.label') + " (" + language + ")"}
                                           name="label"
                                           autoComplete="label"
                                           autoFocus
                                           value={state.label[language] || ""}
                                           onChange={(e) => handleChange('label', e)}
                                           error={errorz.label !== ''}
                                           helperText={errorz.label}
                                />
                            </FormControl>
                            <FormControl fullWidth error={errorz.event !== ''} className={classes.formControl}>
                                <InputLabel id="event-select-label" className={classes.formLabel}>Event *</InputLabel>
                                <Select
                                    labelId="event-select-label"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="event"
                                    label={t('admin.default.fields.event')}
                                    name="event"
                                    autoComplete="event"
                                    value={state.event.id}
                                    onChange={(e) => handleChange('event', e)}
                                    error={errorz.event !== ""}
                                >
                                    <MenuItem value="0"><em>{t('admin.default.labels.none')}</em></MenuItem>
                                    {events.filter(e => state.event.id === e.id || !e.page).map((e, index) =>
                                        <MenuItem key={index}
                                                  value={e.id}>{e.name[language] ? e.name[language] : e.name[Language.DEFAULT]}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText error={errorz.event !== ""}
                                                className={classes.helperText}>{errorz.event}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField className={classes.input}
                                           variant="outlined"
                                           margin="none"
                                           fullWidth
                                           multiline
                                           required
                                           id="data"
                                           label={t('admin.page.fields.content')}
                                           autoComplete="data"
                                           value={state.data}
                                           onChange={(e) => handleChange('data', e)}
                                           error={errorz.data !== ''}
                                           helperText={errorz.data}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={e => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>{t('admin.default.actions.cancel')}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={submit}>{t('admin.default.actions.submit')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>
                }
            </div>
        </Container>
    );
}
