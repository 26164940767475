import DailyIframe from "@daily-co/daily-js";
import { useAnalytics } from '../data/Analytics';
import GAEvent from '../models/GAEvent';
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import DateUtil from '../utils/DateUtil';
import StringUtil from "../utils/StringUtil";
import { emailValidation } from "../utils/Validators";
import { EventType } from "./Constants";

/**
 * Sequential stack based API facade, executes a number of async functions in sequential order
 * @author dame.gjorgjievski
 */
class ApiFacade {

    queue = []
    current = null
    object = DailyIframe.createCallObject()
    frame = null
    room = null
    listeners = {
        [EventType.MESSAGE]: [],
        [EventType.USER_JOIN]: [],
        [EventType.USER_UPDATE]: [],
        [EventType.USER_LEAVE]: []
    }
    listens = false
    timeInRoom = null

    /**
     * Daily API instance accessor, switches access to frame or call object based API instance depending on the context
     * @returns {null|DailyCall} daily API call instance
     */
    api() {
        return this.frame ? this.frame : this.object
    }

    /**
     * Execute method in a sequential chaining process
     * @param func method to invoke
     * @returns {Promise}
     */
    async execute(func) {
        console.log("execute", func, this.queue.length, this.current)
        if (func) this.queue.push(func)
        if (this.current == null) {
            let next = this.queue.splice(0, 1),
                call = next.length > 0 ? next[0].apply(this, []) : null
            if (call) this.current = call.then(() => {
                this.current = null
                return this.execute(null)
            })
        }
        return this.current
    }

    /**
     * Joins a specified virtual room
     * @param room target room to join
     */
    async joinRoom(room, roomName) {
        if (this.room) return
        this.room = room
        console.log("Joining room ", this.room)
        return await this.execute(function () {
            let user = AuthService.user()
            if (!user) return
            return this.api().join({ url: room.url, token: user.apiToken }).then(response => {
                if (response) {
                    this.enableListeners(true)
                    for (const [key, value] of Object.entries(response)) {
                        this.onEvent({ action: EventType.USER_JOIN, participant: value })
                    }

                    if (room.type === 'SESSION') {
                        this.timeInRoom = DateUtil.toUtc(new Date());
                        useAnalytics().event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.JOIN_SESSION,
                            `user: ${response.local.user_name} sessionName: ${roomName} dateJoin: ${DateUtil.getDateTimeNow()}`))
                    }
                }
                console.log("room join response", response, room)
            }).catch((e) => {
                console.log("room join error", e)
            })
        });
    }

    /**
     * Leaves the current joined room, sets this.room property to null
     */
    async leaveRoom(roomName) {
        return await this.execute(function () {
            if (this.room != null) this.room.users = []
            this.onEvent({ action: EventType.USER_LEAVE, participant: { user_name: JSON.stringify(AuthService.user()) } })
            const roomType = this.room.type;
            this.room = null;
            return this.api().leave().then(result => {
                this.enableListeners(false)
                const user = AuthService.user()
                if (roomType === 'SESSION') {
                    const timeInSession = DateUtil.toUtc(new Date(DateUtil.toUtc(new Date()) - this.timeInRoom));
                    const strTimeInSession = `${timeInSession.getHours()}h ${timeInSession.getMinutes()}m ${timeInSession.getSeconds()}s`;

                    useAnalytics().event(new GAEvent(GAEvent.Category.DATA, GAEvent.Action.LEAVE_SESSION,
                        `user: ${user.name} ${user.surname} sessionName: ${roomName} dateLeave: ${DateUtil.getDateTimeNow()} totalTimeInSession: ${strTimeInSession}`));

                    this.timeInRoom = null;
                }

                console.log("left room", result)
            }).catch(e => {
                this.enableListeners(false)
                console.log("room leave error", e)
            })
        })
    }

    /**
     * Broadcasts the message
     * @param message instance of message to send
     * @
     * @returns API response promise
     */
    async sendMessage(message) {
        if (!this.room) return
        message.roomId = this.room.id
        return this.execute(function () {
            this.api().sendAppMessage({ message: JSON.stringify(message) }, '*')
            console.log("broadcasted message", message)
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        })
    }

    /**
     * Sets username for the current user
     */
    async setUsername() {
        return await this.execute(function () {
            let user = {}
            Object.assign(user, AuthService.user())
            user.password = undefined
            user.token = undefined
            user.apiToken = undefined
            this.api().setUserName(JSON.stringify(user), { thisMeetingOnly: false }).then(response => {
                console.log("set username response", response)
            })
        })
    }

    /**
     * General API event callback method, invokes all listener functions attached to the corresponding event
     * @param event source event
     */
    onEvent(event) {
        // console.log("event", event)
        let room = this.room
        switch (event.action) {
            case EventType.USER_JOIN:
                let uid = event.participant.user_id, valid = emailValidation(uid).length === 0
                if (!room || StringUtil.isEmpty(uid) || !valid) break
                let existing = room.users.find(u => u.email === uid)
                if (existing) break
                UserService.findByEmail(uid).then(response => {
                    if (room.users.findIndex(u => u.email === uid) === -1) room.users.push(response.data[0])
                }).then(res => this.onEvent({ action: EventType.USER_UPDATE, participant: res }) )
                break
            case EventType.USER_LEAVE:
                if (!room || !event.participant.user_id) break
                let email = event.participant.user_id
                let idx = room.users.findIndex(u => u.email === email)
                if (idx > -1) room.users.splice(idx, 1)
                break
            case EventType.USER_UPDATE:
                // if (!room || !event.participant.user_id) break
                // UserService.findByEmail(event.participant.user_id).then(response => {
                //     idx = room.users.findIndex(u => u.email === event.participant.user_id)
                //     if (idx > -1) room.users.splice(idx, 1, response.data[0])
                //     else room.users.push(response.data[0])
                // })
                break
            default:
                break
        }

        // invoke attached listeners
        let listeners = this.listeners[event.action] || []
        if (listeners && listeners.length > 0) {
            for (let i in listeners) {
                let listener = listeners[i]
                if (listener) listener(event)
            }
        }
    }

    /**
     * Attaches event listener callback method to specific event type
     * @param event type of event to listen
     * @param method callback listener method
     */
    on(event, method) {
        if (this.listeners[event] === null) this.listeners[event] = []
        this.listeners[event].push(method)
    }

    /**
     * Removes an attached listener method from listening to event type
     * @param event type of event to stop listening
     * @param method callback listener method to remove
     */
    off(event, method) {
        if (this.listeners[event] === null) return
        let idx = this.listeners[event].indexOf(method)
        if (idx > -1) this.listeners[event].splice(idx, 1)
    }

    /**
     * Attaches or detaches the daily API event listeners
     * @param on listener switch flag
     */
    enableListeners(on) {
        if (this.listens === on) return
        this.listens = on
        let callback = this.onEvent.bind(this)
        if (on) {
            this.api().on(EventType.MESSAGE, callback)
            this.api().on(EventType.USER_JOIN, callback)
            this.api().on(EventType.USER_UPDATE, callback)
            this.api().on(EventType.USER_LEAVE, callback)
        } else {
            this.api().off(EventType.MESSAGE, callback)
            this.api().off(EventType.USER_JOIN, callback)
            this.api().off(EventType.USER_UPDATE, callback)
            this.api().off(EventType.USER_LEAVE, callback)
        }
    }
}

export const apiFacade = new ApiFacade()