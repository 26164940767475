import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import {
    Avatar,
    Button,
    Chip,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme
} from '@material-ui/core';
import { styles } from "../styles/FormStyles"
import { emailValidation, phoneValidation, requiredValidation } from "../utils/Validators"
import Alert from '@material-ui/lab/Alert';
import UserService from "../services/UserService";
import FileUpload from "../components/FileUpload";
import { Autocomplete } from "@material-ui/lab";
import ProgressDialog from "../components/ProgressDialog";
import { Path, Role, Visibility, TwoFactorType, SocialMediaName } from "../data/Constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
import AuthService from '../services/AuthService';
import FileService from '../services/FileService';

/**
 * Displays an event edit view
 * @author dame.gjorgjievski
 */
export default function UserEditView() {

    const { t } = useTranslation()
    const classes = styles()
    const history = useHistory()
    const theme = useTheme()
    const [state, setState] = useState({
        name: "John",
        surname: "Doe",
        email: "john@doe.com",
        phone: "",
        password: '***************',
        description: "",
        organization: "",
        function: "",
        tags: [],
        image: null,
        roles: [Role.VIEWER],
        twoFactor: "",
        visibility: Visibility.VISIBLE,
        socialMedia: {}
    })
    const [changedFields, setChangedFields] = useState({})
    let [errorz, setErrorz] = useState({
        name: "",
        surname: "",
        password: "",
        email: "",
        phone: "",
        roles: ""
    })
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    let timeout = useRef(0)

    const onUploadComplete = (response) => {
        setState({ ...state, image: response.data })
        setChangedFields({ ...changedFields, image: response.data })
    }

    const onRemoveFile = () => {
        changedFields.image !== null
            ? FileService.delete(changedFields.image.id).then(() => {
                setState({ ...state, image: null })
                setChangedFields({ ...changedFields, image: null })
            })
            : UserService.deleteUserImage(id).then(response => {
                setState({ ...state, image: null })
                if (AuthService.user().id === response.data.id) {
                    AuthService.user(response.data)
                }
            })
    }

    useEffect(() => {
        setLoading(parseInt(id) > 0)
        UserService.load(id).then((result) => {
            result.data.password = '***************'
            setState(result.data)
            setChangedFields({ ...changedFields, id: result.data.id })
            setLoading(false)
        })
        return () => clearInterval(timeout.current)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        errorz = { ...errorz, name: requiredValidation("First name", state.name) }
        errorz = { ...errorz, surname: requiredValidation("Last name", state.surname) }
        errorz = { ...errorz, password: requiredValidation("Password", state.password) }
        errorz = { ...errorz, email: emailValidation(state.email) }
        errorz = { ...errorz, phone: phoneValidation(state.phone) }
        errorz = { ...errorz, roles: requiredValidation("Event start date", state.roles[0]) }
        setErrorz(errorz)
        clearErrors()
        let props = ["name", "surname", "email", "phone", "roles"]
        for (let i in props) {
            if (errorz[props[i]] && errorz[props[i]].length > 0) return
        }
        setLoading(true)
        let fields = Object.keys(changedFields).length === 0 ? state : changedFields
        UserService.save(fields).then((result) => {
            if (AuthService.user().id === result.data.id) {
                AuthService.user(result.data)
            }
            setLoading(false)
            history.push(Path.ADMIN_USERS)
        }).catch((error) => {
            setLoading(false)
            console.log("submit user error", error.response)
            if (error.response.data.error.indexOf("DataIntegrityViolationException") > -1) setErrors(["User already exists"])
            else setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearErrors()
        })
    }

    const handleChange = (field, e, value) => {
        switch (field) {
            case "name":
                setChangedFields({ ...changedFields, name: e.target.value })
                setState({ ...state, name: e.target.value })
                setErrorz({ ...errorz, name: requiredValidation("Name", e.target.value) })
                break;
            case "surname":
                setChangedFields({ ...changedFields, surname: e.target.value })
                setState({ ...state, surname: e.target.value })
                setErrorz({ ...errorz, surname: requiredValidation("Surname", e.target.value) })
                break;
            case "email":
                setChangedFields({ ...changedFields, email: e.target.value })
                setState({ ...state, email: e.target.value })
                setErrorz({ ...errorz, email: requiredValidation("Email", e.target.value) })
                break;
            case "password":
                setChangedFields({ ...changedFields, password: e.target.value })
                setState({ ...state, password: e.target.value })
                setErrorz({ ...errorz, password: requiredValidation("Password", e.target.value) })
                break;
            case "roles":
                setChangedFields({ ...changedFields, roles: [e.target.value] })
                setState({ ...state, roles: [e.target.value] })
                setErrorz({ ...errorz, roles: requiredValidation("Type", e.target.value) })
                break;
            case "description":
                setChangedFields({ ...changedFields, description: e.target.value })
                setState({ ...state, description: e.target.value })
                break;
            case "function":
                setChangedFields({ ...changedFields, function: e.target.value })
                setState({ ...state, function: e.target.value })
                break;
            case "organization":
                setChangedFields({ ...changedFields, organization: e.target.value })
                setState({ ...state, organization: e.target.value })
                break;
            case "tags":
                setChangedFields({ ...changedFields, tags: value })
                setState({ ...state, tags: value })
                break;
            case "visibility":
                setChangedFields({ ...changedFields, visibility: value ? Visibility.VISIBLE : Visibility.HIDDEN })
                setState({ ...state, visibility: value ? Visibility.VISIBLE : Visibility.HIDDEN })
                break;
            case "two-factor":
                setChangedFields({ ...changedFields, twoFactor: e.target.value })
                setState({ ...state, twoFactor: e.target.value })
                break;
            case "phone":
                setChangedFields({ ...changedFields, phone: e.target.value })
                setState({ ...state, phone: e.target.value })
                break;
            case 'socialMedia':
                const newSocialMedia = state.socialMedia
                newSocialMedia[e.target.name] = e.target.value
                setChangedFields({ ...changedFields, socialMedia: newSocialMedia })
                setState({ ...state, socialMedia: newSocialMedia })
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        timeout.current = setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <ProgressDialog open={loading} />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddBoxOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">{t('admin.user.title-edit')}</Typography>
                <form className={classes.form}>
                    <Grid container>
                        <Grid item xs={6} style={{ padding: theme.spacing(2) }}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                    required
                                    id="name"
                                    label={t('admin.default.fields.name')}
                                    name="name"
                                    autoComplete="name"
                                    value={state.name}
                                    onChange={(e) => handleChange('name', e)}
                                    error={errorz.name !== ''}
                                    helperText={errorz.name}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="surname"
                                    label={t('admin.user.fields.surname')}
                                    name="surname"
                                    autoComplete="surname"
                                    value={state.surname}
                                    onChange={(e) => handleChange('surname', e)}
                                    error={errorz.surname !== ''}
                                    helperText={errorz.surname}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    id="email"
                                    label={t('admin.user.fields.email')}
                                    name="email"
                                    autoComplete="email"
                                    value={state.email}
                                    onChange={(e) => handleChange('email', e)}
                                    error={errorz.email !== ''}
                                    helperText={errorz.email}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    type="password"
                                    id="password"
                                    label={t('admin.user.fields.password')}
                                    name="password"
                                    autoComplete="password"
                                    value={state.password}
                                    onChange={(e) => handleChange('password', e)}
                                    error={errorz.password !== ''}
                                    helperText={errorz.password}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="phone"
                                    label={t('admin.user.fields.phone')}
                                    name="phone"
                                    autoComplete="phone"
                                    value={state.phone}
                                    onChange={(e) => handleChange('phone', e)}
                                    error={errorz.phone !== ''}
                                    helperText={errorz.phone}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth error={errorz.roles !== ""} className={classes.formControl}>
                                <InputLabel id="role-select-label" className={classes.formLabel}>Type *</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="roles"
                                    label={t('admin.default.fields.type')}
                                    name="roles"
                                    autoComplete="roles"
                                    value={state.roles[0]}
                                    onChange={(e) => handleChange('roles', e)}
                                    error={errorz.roles !== ""}
                                    className={classes.input}
                                >
                                    <MenuItem value=""><em>{t('admin.default.labels.none')}</em></MenuItem>
                                    <MenuItem value={Role.SUPER}>{t('data.user-type.super')}</MenuItem>
                                    <MenuItem value={Role.ADMIN}>{t('data.user-type.admin')}</MenuItem>
                                    <MenuItem value={Role.VIEWER}>{t('data.user-type.viewer')}</MenuItem>
                                    <MenuItem value={Role.SPEAKER}>{t('data.user-type.speaker')}</MenuItem>
                                    <MenuItem value={Role.SPONSOR}>{t('data.user-type.sponsor')}</MenuItem>
                                </Select>
                                <FormHelperText className={classes.helperText}>{errorz.access}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id="2fa-select-label" className={classes.formLabel}>Two-Factor authentication</InputLabel>
                                <Select
                                    labelId="2fa-select-label"
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    id="two-factor"
                                    label={t('admin.default.fields.type')}
                                    name="two-factor"
                                    autoComplete="two-factor"
                                    value={state.twoFactor}
                                    onChange={(e) => handleChange('two-factor', e)}
                                    className={classes.input}
                                >
                                    <MenuItem value=""><em>{t('admin.default.labels.none')}</em></MenuItem>
                                    <MenuItem value={TwoFactorType.EMAIL}>Email</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FileUpload onComplete={onUploadComplete} files={state.image ? [state.image] : []}
                                    update label={t('admin.default.fields.image')}
                                    accept={".jpg, .jpeg, .gif, .png"}
                                    onRemove={onRemoveFile} />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <FormControlLabel
                                    control={<Checkbox checked={state.visibility === Visibility.VISIBLE} color="primary" />}
                                    onChange={(e, value) => handleChange("visibility", e, value)}
                                    label={t('admin.user.fields.visibility')}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ padding: theme.spacing(2) }}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="function"
                                    label={t('admin.user.fields.function')}
                                    name="function"
                                    autoComplete="function"
                                    value={state.function || ""}
                                    onChange={(e) => handleChange('function', e)}
                                    helperText={errorz.function}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="organization"
                                    label={t('admin.user.fields.organization')}
                                    name="organization"
                                    autoComplete="organization"
                                    value={state.organization || ""}
                                    onChange={(e) => handleChange('organization', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    multiline
                                    fullWidth
                                    name="description"
                                    label={t('admin.default.fields.description')}
                                    type="description"
                                    id="description"
                                    autoComplete="description"
                                    value={state.description || ""}
                                    onChange={(e) => handleChange('description', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    freeSolo
                                    options={[]}
                                    value={state.tags}
                                    onChange={(e, value) => handleChange("tags", e, value)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={t('admin.user.fields.tags')}
                                            placeholder={t('admin.user.fields.tags')} />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="linkedIn"
                                    label={t('client.user.labels.linkedIn')}
                                    name={SocialMediaName.LINKEDIN}
                                    autoComplete={SocialMediaName.LINKEDIN}
                                    value={state.socialMedia[SocialMediaName.LINKEDIN] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="facebook"
                                    label={t('client.user.labels.facebook')}
                                    name={SocialMediaName.FACEBOOK}
                                    autoComplete={SocialMediaName.FACEBOOK}
                                    value={state.socialMedia[SocialMediaName.FACEBOOK] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="instagram"
                                    label={t('client.user.labels.instagram')}
                                    name={SocialMediaName.INSTAGRAM}
                                    autoComplete={SocialMediaName.INSTAGRAM}
                                    value={state.socialMedia[SocialMediaName.INSTAGRAM] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="twitter"
                                    label={t('client.user.labels.twitter')}
                                    name={SocialMediaName.TWITTER}
                                    autoComplete={SocialMediaName.TWITTER}
                                    value={state.socialMedia[SocialMediaName.TWITTER] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="website"
                                    label={t('client.user.labels.website')}
                                    name={SocialMediaName.WEBSITE}
                                    autoComplete={SocialMediaName.WEBSITE}
                                    value={state.socialMedia[SocialMediaName.WEBSITE] || ''}
                                    onChange={(e) => handleChange('socialMedia', e)}
                                    className={classes.input}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ width: '50%' }}
                                className={classes.submit}
                                onClick={e => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>{t('admin.default.actions.cancel')}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ width: '50%' }}
                                className={classes.submit}
                                onClick={submit}>{t('admin.default.actions.submit')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{ height: '30px' }} />
            <div>
                {errors.length > 0 &&
                    <Alert severity="error">
                        {errors.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                        })}
                    </Alert>
                }
            </div>
        </Container>
    )
}
