import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Mov icon
 * @returns {JSX.Element}
 * @author petar.todorovski
 */

export default function TxtIcon() {
    return (
        <SvgIcon viewBox="0 0 57.758 77.025">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17"
                                gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main}/>
                    <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_928" data-name="Path 928"
                  d="M55.637-20.268,43.017-32.887a7.22,7.22,0,0,0-5.1-2.121H7.22A7.244,7.244,0,0,0,0-27.773V34.8a7.222,7.222,0,0,0,7.22,7.22H50.538a7.222,7.222,0,0,0,7.22-7.22V-15.154A7.255,7.255,0,0,0,55.637-20.268Zm-3.4,3.414a2.367,2.367,0,0,1,.632,1.113H38.505V-30.1a2.367,2.367,0,0,1,1.113.632ZM50.538,37.2H7.22A2.414,2.414,0,0,1,4.813,34.8V-27.773A2.414,2.414,0,0,1,7.22-30.18H33.692v15.643a3.6,3.6,0,0,0,3.61,3.61H52.944V34.8A2.414,2.414,0,0,1,50.538,37.2ZM43.318.5a1.81,1.81,0,0,0-1.8-1.8H16.244a1.81,1.81,0,0,0-1.8,1.8v1.2a1.81,1.81,0,0,0,1.8,1.8H41.513a1.81,1.81,0,0,0,1.8-1.8Zm0,9.626a1.81,1.81,0,0,0-1.8-1.8H16.244a1.81,1.81,0,0,0-1.8,1.8v1.2a1.81,1.81,0,0,0,1.8,1.8H41.513a1.81,1.81,0,0,0,1.8-1.8Zm0,9.626a1.81,1.81,0,0,0-1.8-1.8H16.244a1.81,1.81,0,0,0-1.8,1.8v1.2a1.81,1.81,0,0,0,1.8,1.8H41.513a1.81,1.81,0,0,0,1.8-1.8Z"
                  transform="translate(0 35.008)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
