import {ENDPOINT_POLLS} from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";

/**
 * Poll service, manages CRUD operations for session poll data
 * @author dame.gjorgjievski
 **/
const PollService = new ApiService(ENDPOINT_POLLS, ["name.en", "content.en", "sessionName.en"])

/**
 * Performs a vote on specified poll with specified vote option
 * @param pollId poll identifier
 * @param optionId poll vote option
 * @returns {Promise<AxiosResponse<any>>}
 */
PollService.vote = function (pollId, optionId) {
    return axios.post(this.endpoint + pollId + "/vote/" + optionId)
}

/**
 * Find polls that are assigned to a session
 * @param sessionId session identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
PollService.findForSession = function (sessionId) {
    return axios.get(this.endpoint + "session/" + sessionId)
}

export default PollService