import i18n from "i18next";
import { Language } from "../data/Constants"

/**
 * Date utilities class
 * @author dame.gjorgjievski
 */
export default class DateUtil {

    static FORMAT_READABLE = "readable"
    static FORMAT_REGULAR = "regular"
    static FORMAT_FULL = "full"
    static FORMAT = "yyyy-MM-dd HH:mm:ss"
    static WEEKDAYS = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
    static MONTHS = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]

    /**
     * Get current UTC datetime
     * @returns {Date} utc datetime
     */
    static utc() {
        return this.toUtc(new Date())
    }

    /**
     * Convert a local date to utc date
     * @param date actual local date to convert
     * @returns {Date} utc datetime
     */
    static toUtc(date) {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let offset = date.getTimezoneOffset() / 60;
        newDate.setHours(date.getHours() + offset);
        return newDate;
    }

    /**
     * Convert a date from utc to local date
     * @param date actual date to convert
     * @returns {Date} converted date to local date
     */
    static fromUtc(date) {
        let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
        let offset = date.getTimezoneOffset() / 60;
        newDate.setHours(date.getHours() - offset);
        return newDate;
    }

    /**
     * Convert a date to string representation in yyyy-mm-ddThh:mm format
     * @param date actual date to convert
     * @return {String} date string representation
     */
    static toString(date) {
        let month = ((date.getMonth() + 1) + "").length > 1 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)
        let day = (date.getDate() + "").length > 1 ? date.getDate() : '0' + date.getDate()
        let hours = (date.getHours() + "").length > 1 ? date.getHours() : '0' + date.getHours()
        let minutes = (date.getMinutes() + "").length > 1 ? date.getMinutes() : '0' + date.getMinutes()
        let seconds = (date.getSeconds() + "").length > 1 ? date.getSeconds() : '0' + date.getSeconds()
        return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds;
    }

    /**
     * Extracts the weekday from a specified date
     * @param date input date
     * @returns {string} day of the week from the specified date
     */
    static getWeekDay(date) {
        return i18n.t(`data.days.${this.WEEKDAYS[date.getDay()]}`)
    }

    /**
     * Extracts the month from a specified date
     * @param date input date
     * @return {string} month from the specified date
     */
    static getMonth(date) {
        return i18n.t(`data.months.${this.MONTHS[date.getMonth()]}`)
    }

    /**
     * Extracts the date from a specified date prefixed with zero
     * @param date input date
     * @return {string} date from the specified date
     */
    static getDate(date) {
        let day = date.getDate() + ""
        return day.length === 1 ? '0' + day : day
    }

    /**
     * Extracts the hours from a specified date prefixed with zero
     * @param date input date
     * @returns {string} hours from the specified date
     */
    static getHours(date) {
        let hours = date.getHours() + ""
        return hours.length === 1 ? '0' + hours : hours
    }

    /**
     * Extracts the minutes from a specified date prefixed with zero
     * @param date input date
     * @returns {string} minutes from the specified date
     */
    static getMinutes(date) {
        let hours = date.getMinutes() + ""
        return hours.length === 1 ? '0' + hours : hours
    }

    /**
     * Format date with static format
     * @param date input date
     * @param format target format
     * @returns {string} formatted date string
     */
    static format(date, format) {
        let z = (n) => (n < 10 ? '0' : '') + n;
        const dot = i18n.language === Language.DE ? '.' : ''
        const dateOnly = `${this.getDate(date)}${dot} ${this.getMonth(date)} ${date.getFullYear()}`
        switch (format) {
            case this.FORMAT_READABLE:
                return dateOnly
            case this.FORMAT_FULL:
                return `${dateOnly} ${z(date.getHours())}:${z(date.getMinutes())}`
            case this.FORMAT_REGULAR:
                return this.getDate(date) + " / " + z(date.getMonth() + 1) + " / " + date.getFullYear()
            default:
                return date.toString()
        }
    }

    /**
     * Extrapolate discrete one day dates from array of dates
     * @param dates input dates array
     * @returns {[]} array of discrete dates
     */
    static extrapolate(dates) {
        let result = []
        dates.forEach(date => {
            let d = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            let idx = result.indexOf(d.toDateString())
            if (idx === -1) result.push(d.toDateString())
        })
        return result.map((d) => new Date(d)).sort((a, b) => a - b)
    }

    /**
     * Converts time amount in seconds to HH:MM:SS format
     * @param date target date
     * @returns {string} HH:MM:SS formatted time string
     */
    static hms(date) {
        let z = (n) => (n < 10 ? '0' : '') + n;
        const total = Math.abs(date.getTime() - new Date().getTime());
        const seconds = z(Math.floor((total / 1000) % 60));
        const minutes = z(Math.floor((total / 1000 / 60) % 60));
        const hours = z(Math.floor((total / (1000 * 60 * 60)) % 24));
        const days = Math.floor(total / (1000 * 60 * 60 * 24));
        return (days > 0 ? days + ' days ' : '') + hours + ":" + minutes + ":" + seconds
    }

    /**
     * Convert seconds to display time
     * @returns {String} timestamp
     */
    static display(seconds) {
        let z = (n) => (n < 10 ? '0' : '') + n;
        let hours = Math.floor(seconds / 3600);
        seconds = seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds = parseInt(seconds % 60);
        return z(hours) + ":" + z(minutes) + ":" + z(seconds)
    }


    /**
     * Returns a string representation of difference between two dates
     * @param start period start date
     * @param end period end date
     */
    static difference(start: Date, end: Date): String {
        let result = ""
        let dot = i18n.language === Language.DE ? "." : ""
        if (start.getFullYear() !== end.getFullYear()) { // different year
            result = `${this.getDate(start)}${dot} ${this.getMonth(start)} ${start.getFullYear()}- 
                ${this.getDate(end)}${dot} ${this.getMonth(end)} ${end.getFullYear()}`
        } else {
            if (start.getMonth() !== end.getMonth()) { // same year but not same month
                result = `${this.getDate(start)}${dot} ${this.getMonth(start)} - 
                ${this.getDate(end)}${dot} ${this.getMonth(end)} ${start.getFullYear()}`
            } else {
                if (start.getDate() === end.getDate()) { // same month and same day
                    result = `${this.getDate(start)}${dot} ${this.getMonth(start)} ${start.getFullYear()}`
                } else {
                    result = `${this.getDate(start)}${dot} - ${this.getDate(end)}${dot} 
                ${this.getMonth(start)} ${start.getFullYear()}`
                }
            }
        }
        return result
    }

    static getDateTimeNow() {
        const date = new Date()
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
}

