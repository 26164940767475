import { ENDPOINT_MESSAGES } from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";
import { MessageType } from "../data/Constants";

/**
 * Message service, manages CRUD operations for room data
 * @author dame.gjorgjievski
 **/
const MessageService = new ApiService(ENDPOINT_MESSAGES, [])

/**
 * Assembles a set of filtering and sorting criteria to query messages for given room. Used for chat pagination to
 * retrieve messages before datetime checkpoint or after a datetime checkpoint
 * @param id room identifier
 * @param size page size
 * @param type messages type
 * @param before time before
 * @param after time after
 * @returns {Promise<ApiService>} find room messages response
 */
MessageService.findForRoom = async function (id, size, type, before, after) {
    let filters = [], sorts = []
    filters.push({ property: "roomId", operator: 0, value: id })
    if (type) filters.push({ property: "type", operator: 0, value: type })
    if (before) filters.push({ property: "timeCreated", operator: 2, value: before })
    if (after) filters.push({ property: "timeCreated", operator: 3, value: after })
    sorts.push({ property: "timeCreated", desc: type === MessageType.NOTIFICATION ? 0 : 1 })
    return this.find(0, size, filters, sorts)
}

/**
 * Sends a new chat message to specified room
 * @param room target room
 * @param text message text
 * @param type message type
 * @param to target user id
 * @returns {Promise<ApiService>} sent message response
 */
MessageService.sendMessage = async function (room, text, type) {
    console.log("about to send message", room, text, type)
    let seen = room.type !== MessageType.CHAT
    let toId = room.type === MessageType.CHAT ? room.users[0].id : null
    return this.create({ text: text, roomId: room.id, type: type, toId: toId, seen: seen })
}

/**
 * Retrieves unseen messages for the current user
 * @returns {Promise<AxiosResponse<any>>}
 */
MessageService.findUnseen = async function () {
    return await axios.get(this.endpoint + "unseen")
}
export default MessageService