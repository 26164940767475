import {createStyles, makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        '& .MuiPopover-paper' : {
            minWidth: 100,
            top: 56,
            left: 0
        }
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.common.white,
        '& .MuiBadge-root': {
            '& .MuiBadge-anchorOriginTopRightRectangle': {
                backgroundColor: theme.palette.secondary.main,
            }
        }
    },
    appBarShift: {
        marginLeft: 45,
        width: `calc(100% - 45px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            marginLeft: 110,
            width: `calc(100% - 110px)`,
        }
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        display: "inline-block",
        verticalAlign: 'middle',
        color: theme.palette.primary.var1,
        '&:first-of-type': {
            color: theme.palette.secondary.main
        }
    },
    navigation: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1
    },
    adminMenu: {
        display: "inline-block",
        verticalAlign: 'middle',
    },
    adminMenuItem: {
        display: "inline-block",
        color: theme.palette.tertiary.var2,
        verticalAlign: 'middle',
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(4),
        },
        '& .MuiSvgIcon-root': {
            display: "inline-block",
            verticalAlign: 'middle',
            marginRight: theme.spacing(1),
            color: theme.palette.quaternary.var5
        },
        '& .MuiListItemText-root': {
            display: "inline-block",
            verticalAlign: 'middle'
        }
    },
    logoWrap: {
        height: theme.spacing(4.5),
        display: 'inline-block',
        overflow: 'hidden',
        verticalAlign: 'middle',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            height: theme.spacing(6.25)
        },
        '& img': {
            width: 'auto',
            height: '100%',
            objectFit: 'contain',
            objectPosition: 'left'
        }
    },
    drawerPaper: {
        position: 'relative',
        borderRight: '0',
        overflow: 'hidden',
        paddingLeft: '5px',
        whiteSpace: 'nowrap',
        width: 45,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('sm')]: {
            width: 110,
        }
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& ::-webkit-scrollbar': {
            display: 'none'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        height: '100%',
        overflowX: 'hidden'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    avatarIcon: {
        marginLeft: 15,
        marginRight: 15,
        textDecoration: 'none',
        '& .MuiAvatar-root': {
            border: `2px solid ${theme.palette.secondary.main}`
        }
    },
    exitIcon: {
        cursor: 'pointer',
        color: theme.palette.quaternary.var5
    },
    wrappedText: {
        whiteSpace: 'normal',
        textAlign: 'center',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display:'block'
        }
    },
    deviceTriggerChat: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            cursor: 'pointer',
            color: theme.palette.secondary.main
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }
}));
