import ReactGA from 'react-ga4';
import {TRACKING_ID} from "./Configuration";
import AuthService from "../services/AuthService";
import {createRef} from "react";

/**
 * Analytics provider hook based on Google Analytics (GA)
 * @author dame.gjorgjievski
 */
const initialized = createRef()

export function useAnalytics() {
    if(!initialized.current) {
        console.log("init analytics")
        ReactGA.initialize('G-BHEYC7Q3CW', {
            debug: true,
            name: 'newsroom-event-tracker',
            titleCase: true,
            testMode: false,
            cookieDomain: 'auto',
            gaOptions: {
                userId: AuthService.user() ? AuthService.user().email : null
            }
        })
        initialized.current = true
    }
    return ReactGA
}
