import { makeStyles, createStyles } from '@material-ui/core/styles';

export const menuStyles = makeStyles((theme) => createStyles({
    listWrap:{
        paddingTop: theme.spacing(3),
        '& .MuiListItem-root': {
            flexDirection: 'column',
            '& .MuiListItemIcon-root': {
                justifyContent: 'center'
            },
        },
        '& .MuiListItemText-root': {
            color: theme.palette.tertiary.var2
        },
        '& a': {
            display:'flex',
            flexDirection:'column',
            alignItems: 'center',
            padding: theme.spacing(2.5),
            textDecoration: 'none',
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1),
            },
            '&.active': {
                position: 'relative',
                backgroundColor: theme.palette.secondary.var2,
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                '&::before, &::after':{
                    content: '""',
                    width: '40px',
                    height: '30px',
                    border: `0px solid ${theme.palette.secondary.var2}`,
                    position: 'absolute',
                    right:'0',
                },
                '&::before': {
                    top:'-29px',
                    borderTop: '0',
                    borderRadius: '0 0 20px 0',
                    boxShadow:`16px 0px ${theme.palette.secondary.var2}`
                },
                '&::after': {
                    bottom:'-29px',
                    borderTop: '0',
                    borderRadius: '0 20px 0 0',
                    boxShadow:`16px 0px ${theme.palette.secondary.var2}`
                }
            }
        }
    },
}));