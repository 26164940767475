import {Language} from "../data/Constants";

/**
 * Email validation method
 * @param email target email to validate
 * @returns {string|null} error that occurred during validation
 */
export const emailValidation = email => {
    if (email == null || email.trim() === '') return 'Email is required';
    if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) return "";
    return 'Please enter a valid email';
};

/**
 * Required field validation
 * @param fieldName name of field
 * @param fieldValue value of field
 * @returns {string|null} error that occurred during validation
 */
export const requiredValidation = (fieldName, fieldValue) => {
    if (fieldValue == null || fieldValue.trim().length === 0) return `${fieldName} is required`;
    if (fieldValue.trim().length < 2) return `${fieldName} needs to be at least two characters`
    return ""
}

/**
 * Field size constraint validation
 * @param fieldName name of field
 * @param fieldValue value of field
 * @param min minimum value
 * @param max maximum value
 * @returns {string} error that occurred during validation
 */
export const sizeValidation = (fieldName, fieldValue, min, max) => {
    if (fieldValue == null) return `${fieldName} is required`;
    if (min > fieldValue || max < fieldValue) return `${fieldName} must be in range [${min}, ${max}]`
    return ""
}

/**
 * Required translation field validation
 * @param fieldName name of field
 * @param fieldValue value of field
 * @returns {string|null} error that occurred during validation
 */
export const requiredTranslation = (fieldName, fieldValue) => {
    if (fieldValue == null || !fieldValue[Language.DEFAULT] || fieldValue[Language.DEFAULT].trim().length === 0)
        return `${fieldName} is required`;
    if (fieldValue[Language.DEFAULT].trim().length < 2) return `${fieldName} needs to be at least two characters`
    return "";
};

/**
 * Validates a phone number format
 * @param phone target phone
 * @returns {string}
 */
export const phoneValidation = phone => {
    if (phone == null || phone.trim() === '') return "";
    if (/^\+[1-9]\d{1,14}$/.test(phone)) return "";
    return getPhoneErrorMessage(phone);
}

const getPhoneErrorMessage = phone => {
    const phoneArr = phone.split('+');
    let phoneMsg = "Invalid phone number. ";
    phoneMsg += phoneArr[0] === ''
        ? phoneArr[1].length > 15
            ? 'The maximum number of the phone must be 15 characters.' : ''
        : ''
    return phoneMsg
}

/**
 * Validate if an url is in correct format
 * @param url target url to validate
 * @returns {string}
 */
export const urlValidation = url => {
    if (url == null || url.length === 0) return ""
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    if (pattern.test(url)) {
        return "";
    }
    return "Invalid URL format";
}
