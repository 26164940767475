import {useDispatch, useSelector} from "react-redux";
import {setEvent, setEvents} from "./Store";
import EventService from "../services/EventService";
import useLanguage from "./Language";

/**
 * Hook providing event context for management of the events data in local store
 * @author dame.gjorgjievski
 * @returns {{default: (function(): string), l, lang: (function(*=): string), value: ((function(*, *=): (null|*))|*)}}
 */
export default function useEventContext() {

    const events = useSelector(state => state.events)
    const event = useSelector(state => state.event)
    const dispatch = useDispatch()
    const {lang, setLang} = useLanguage()
    const setLanguage = (e) => {
        if (e && (e.languages || []).length > 0 && !e.languages.find(l => l === lang()))
            setLang(e.languages[0])
    }

    /**
     * Load an event by identifier from state if available, otherwise load remote data
     * @param id event identifier
     * @param force force loading event
     * @returns {Promise<unknown>}
     */
    const loadEvent = function (id, force = false) {
        return new Promise((resolve, reject) => {
            const set = (e) => {
                dispatch(setEvent(e))
                setLanguage(e)
                resolve(e)
            }
            let e = (events || []).find(e => e.id === parseInt(id))
            if (!force && !event && e) set(e)
            else if (force || !event || event.id !== parseInt(id)) {
                EventService.load(id).then(result => set(result.data))
            } else {
                if (event) setLanguage(event)
                resolve(null)
            }
        })
    }

    /**
     * Loads all user events from remote API and sets the current event if found in context. Sets the event and events
     * redux store
     * @return promise axios response promise
     */
    const loadEvents = function () {
        return EventService.findUserEvents().then(result => {
            dispatch(setEvents(result.data))
            if (event) {
                let ex = result.data.find(e => e.id === event.id)
                if (ex) {
                    dispatch(setEvent(ex))
                    setLanguage(ex)
                }
            } else if (result.data && result.data.length === 1) {
                dispatch(setEvent(result.data[0]))
                setLanguage(result.data[0])
            }
            return result
        })
    }

    return {
        event: event,
        events: events,
        loadEvent: loadEvent,
        loadEvents: loadEvents
    }
}
