import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import {
    Avatar,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import {styles} from "../styles/FormStyles"
import {requiredTranslation} from "../utils/Validators"
import Alert from '@material-ui/lab/Alert';
import ProgressDialog from "../components/ProgressDialog";
import {Language, Path} from "../data/Constants";
import PollService from "../services/PollService";
import SessionService from "../services/SessionService";
import {MoreHoriz, Remove} from "@material-ui/icons";
import StringUtil from "../utils/StringUtil";
import {useTranslation} from "react-i18next";
import EditInLanguageSelector from "../components/EditInLanguageSelector";

/**
 * Displays a poll edit page
 * @author dame.gjorgjievski
 */
export default function PollEditView() {

    const {t} = useTranslation()
    const theme = useTheme()
    const classes = styles()
    const history = useHistory();
    const [state, setState] = useState({
        name: {
            [Language.DEFAULT]: "Poll name"
        },
        content: {
            [Language.DEFAULT]: "Poll question"
        },
        sessionId: "",
        enabled: true,
        options: [],
        count: 0,
        order: 0
    })
    let [errorz, setErrorz] = useState({
        name: "",
        content: "",
        sessionId: "",
        options: [],
        order: ""
    })
    const [language, setLanguage] = useState(Language.current())
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [sessions, setSessions] = useState([])
    const {id} = useParams()
    let timeout = useRef(0)

    useEffect(() => {
        setLoading(parseInt(id) > 0)
        PollService.load(id).then((result) => {
            setState(result.data)
            setLoading(false)
        })
        SessionService.find(0, 500).then(response => {
            setSessions(response.data.filter(s => s.room != null))
        })
        return () => clearInterval(timeout.current)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        errorz = {...errorz, name: requiredTranslation("Poll Name", state.name)}
        errorz = {...errorz, content: requiredTranslation("Poll Question", state.content)}
        errorz = {...errorz, sessionId: StringUtil.isEmpty(state.sessionId + "") ? "Poll session is required" : ""}
        errorz = {...errorz, order: state.order < 0 ? "x >= 0" : ""}
        setErrorz(errorz)
        clearErrors()
        let props = ["name", "content", "sessionId", "order"]
        for (let i in props) {
            if (errorz[props[i]] && errorz[props[i]].length > 0) return
        }
        for (let i in state.options) {
            if (errorz.options[i] && errorz.options[i].length > 0) return
        }
        setLoading(true)
        PollService.save(state).then((result) => {
            setLoading(false)
            history.push(Path.ADMIN_POLLS)
        }).catch((error) => {
            setLoading(false)
            console.log("submit poll error", error.response)
            setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearErrors()
        })
    }

    const handleChange = (field, e, value, index) => {
        switch (field) {
            case "name":
                const name = {...state.name, [language]: e.target.value}
                setState({...state, name})
                setErrorz({...errorz, [field]: requiredTranslation("Name", name)})
                break;
            case "content":
                const content = {...state.content, [language]: e.target.value}
                setState({...state, content})
                setErrorz({...errorz, [field]: requiredTranslation("Content", content)})
                break;
            case "session":
                setState({...state, sessionId: value.props.value})
                setErrorz({
                    ...errorz, sessionId: StringUtil.isEmpty(value.props.value + "") ?
                        "Poll session is required" : ""
                })
                break;
            case "enabled":
                setState({...state, [field]: value})
                break;
            case "order":
                setState({...state, [field]: e.target.value})
                setErrorz({...errorz, [field]: e.target.value < 0 ? "x >= 0" : ""})
                break;
            case "options":
                state[field][index].content[language] = value
                setState({...state, [field]: [...state[[field]]]})
                errorz[field][index] = requiredTranslation("Options", state[field][index].content)
                setErrorz({...errorz, [field]: [...errorz[[field]]]})
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        timeout.current = setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    const onAddOption = (e) => {
        e.preventDefault()
        let len = state.options.length
        state.options.push({
            id: null,
            content: {[Language.DEFAULT]: "Option Content " + (len + 1)},
            order: len + 1,
            count: 0,
            percent: 0
        })
        setState({...state})
    }

    const onRemoveOption = (idx) => (e) => {
        state.options.splice(idx, 1)
        setState({...state})
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ProgressDialog open={loading}/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddBoxOutlined/>
                </Avatar>
                <Grid spacing={2} container direction="row" justify="center" alignItems="center">
                    <Grid item><Typography component="h1" variant="h5">{t('admin.poll.title-edit')}</Typography></Grid>
                    <Grid item><EditInLanguageSelector language={language} onChange={e => {
                        setLanguage(e.target.value)
                    }}/></Grid>
                </Grid>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    required
                                    id="name"
                                    label={t('admin.default.fields.name') + " (" + language + ")"}
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    value={state.name[language] || ""}
                                    onChange={(e) => handleChange('name', e)}
                                    error={errorz.name !== ''}
                                    helperText={errorz.name}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    required
                                    id="content"
                                    label={t('admin.poll.fields.content') + " (" + language + ")"}
                                    name="content"
                                    autoComplete="content"
                                    value={state.content[language] || ""}
                                    onChange={(e) => handleChange('content', e)}
                                    error={errorz.content !== ''}
                                    helperText={errorz.content}
                                />
                            </FormControl>
                            <FormControl fullWidth required error={errorz.sessionId !== ""}
                                         className={classes.formControl}>
                                <InputLabel id="session-select-label" className={classes.formLabel}>
                                    {t('admin.default.fields.session')}</InputLabel>
                                <Select
                                    labelId="session-select-label"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="session"
                                    label={t('admin.default.fields.session')}
                                    name="session"
                                    autoComplete="session"
                                    value={state.sessionId}
                                    onChange={(e, value) => handleChange('session', e, value)}
                                    error={errorz.sessionId !== ""}
                                >
                                    <MenuItem value=""><em>{t('admin.default.labels.none')}</em></MenuItem>
                                    {sessions.map((s, i) =>
                                        <MenuItem key={"session" + i}
                                                  value={s.id}>{s.name[language] ? s.name[language] : s.name[Language.EN]}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText className={classes.helperText}>{errorz.sessionId}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl} style={{display: 'inline-block'}}>
                                <FormControlLabel label={t('admin.default.fields.enabled')}
                                                  style={{display: 'inline-block', marginRight: 0, width: '33%'}}
                                                  control={<Checkbox value={state.enabled} color="primary"
                                                                     checked={state.enabled}
                                                                     onChange={(e, value) => handleChange("enabled", e, value)}/>}/>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    margin="none"
                                    style={{display: 'inline-block', marginRight: 0, width: '33%'}}
                                    type="number"
                                    min={0}
                                    InputProps={{inputProps: {min: 0, max: 100}}}
                                    required
                                    id="order"
                                    label={t('admin.default.fields.order')}
                                    name="order"
                                    autoComplete="order"
                                    value={state.order}
                                    onChange={(e) => handleChange('order', e)}
                                    error={errorz.order !== ''}
                                    helperText={errorz.order}
                                />
                                <FormControlLabel label={t('admin.poll.fields.votes')} control={<> {state.count} </>}
                                                  style={{
                                                      display: 'inline-block', marginRight: 0, width: '33%',
                                                      textAlign: 'right'
                                                  }}/>
                            </FormControl>
                            <FormControl fullWidth error={errorz.options.length > 0} className={classes.formControl}>
                                <FormLabel component="legend" error={false}>{t("admin.poll.fields.options")}</FormLabel>
                                <Tooltip title={t('admin.poll.actions.add')}>
                                    <AddBoxOutlined style={{position: 'absolute', right: 0, top: -theme.spacing(1)}}
                                                    onClick={onAddOption}/>
                                </Tooltip>
                                <FormGroup style={{marginTop: theme.spacing(2)}}>
                                    {state.options.map((o, i) =>
                                        <div style={{position: 'relative'}} key={"option" + i}>
                                            <TextField
                                                variant="outlined"
                                                margin="none"
                                                required
                                                fullWidth
                                                id={"option" + i}
                                                key={"option" + i}
                                                label={t('admin.poll.fields.option') + " " + (i + 1) + " (" + language + ")"}
                                                name={"option" + i}
                                                autoComplete="option"
                                                style={{marginBottom: theme.spacing(1)}}
                                                value={o.content[language] || ""}
                                                onChange={(e) => handleChange('options', e, e.target.value, i)}
                                                error={errorz.options[i] && errorz.options[i] !== ""}
                                                helperText={errorz.options[i]}
                                            />
                                            <Tooltip title={t('admin.poll.actions.remove')}>
                                                <Remove onClick={onRemoveOption(i)}
                                                        style={{
                                                            position: 'absolute',
                                                            right: theme.spacing(1),
                                                            top: theme.spacing(2)
                                                        }}/>
                                            </Tooltip>
                                            <Tooltip title={t('admin.poll.fields.votes') + " " + o.count + " ( " + o.percent + "% ) "}>
                                                <MoreHoriz
                                                    style={{
                                                        position: 'absolute',
                                                        right: theme.spacing(4),
                                                        top: theme.spacing(2)
                                                    }}/>
                                            </Tooltip>
                                        </div>)}
                                </FormGroup>
                            </FormControl>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={e => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>{t('admin.default.actions.cancel')}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={submit}>{t('admin.default.actions.submit')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>
                }
            </div>
        </Container>
    );
}
