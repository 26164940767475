import ApiService from "../data/ApiService";
import {ENDPOINT_PAGES} from "../data/Configuration";

/**
 * Page service, manages CRUD operations
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 **/
const PageService = new ApiService(ENDPOINT_PAGES, ["name.en", "description.en", "label.en", "data"])

export default PageService
