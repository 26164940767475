export const API_URL = (process.env.REACT_APP_BASE_URL || '/') + 'api/v1/';
export const PUBLIC_API_URL = (process.env.REACT_APP_BASE_URL || '/') + 'service/v1/';
export const WS_URL = process.env.REACT_APP_WS_URL;
export const TENANT = process.env.REACT_APP_TENANT;
export const OIDC_AUTHORITY = process.env.REACT_APP_OIDC_AUTHORITY || "https://eventplattform.fusionauth.io";
export const OIDC_CLIENT_ID= process.env.REACT_APP_OIDC_CLIENT_ID || "b568e00d-6262-40e3-a3e5-d11f7616f790";
export const OIDC_REDIRECT_URL= process.env.REACT_APP_OIDC_REDIRECT_URL || "http://localhost:3000/callback";
export const OIDC_LOGOUT_URL= process.env.REACT_APP_OIDC_LOGOUT_URL || "http://localhost:3000/login";

export const ENDPOINT_EVENTS = "events/"
export const ENDPOINT_USERS = "users/"
export const ENDPOINT_SESSIONS = "sessions/"
export const ENDPOINT_GROUPS = "groups/"
export const ENDPOINT_PAGES = "pages/"
export const ENDPOINT_SPONSORS = "sponsors/"
export const ENDPOINT_ROOMS = "rooms/"
export const ENDPOINT_MESSAGES = "messages/"
export const ENDPOINT_POLLS = "polls/"

export const DEFAULT_SESSION_IMAGE = process.env.PUBLIC_URL + "/images/default-session.jpg"
export const DEFAULT_EVENT_IMAGE = process.env.PUBLIC_URL + "/images/default-event.jpg"
export const DEFAULT_EVENT_INTRO_IMAGE = process.env.PUBLIC_URL + "/images/default-event-intro.jpg"
export const DEFAULT_USER_IMAGE = process.env.PUBLIC_URL + "/images/default-user.jpg"

export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID
