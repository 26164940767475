import React, {useState} from "react";
import MaterialTable from "material-table";
import {tableIcons} from "../styles/TableIcons";
import Alert from "@material-ui/lab/Alert";
import {useHistory} from "react-router-dom";
import {AddBox, Edit} from "@material-ui/icons";
import DateUtil from "../utils/DateUtil";
import SessionService from "../services/SessionService";
import {Container} from "@material-ui/core";
import {Path} from "../data/Constants";
import useLanguage from "../data/Language";
import {useTranslation} from "react-i18next";
import CompUtil from "../utils/CompUtil";

/**
 * The events listing component, lists all incoming events for a participant user
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function SessionsListView() {

    const {value} = useLanguage()
    const {t} = useTranslation()
    const columns = [
        {title: t("admin.default.fields.id"), field: "id", hidden: true},
        {title: t("admin.default.fields.name"), field: `name`, render: rowData => value(rowData.name, null, 30) },
        {title: t("admin.default.fields.event"), field: `event.name`, render: rowData => value(rowData.event.name, null, 30) },
        {title: t('admin.default.fields.type'), field: "type"},
        {title: t("admin.default.fields.groups"), field: "groups", render: rowData => {
                return (rowData.groups || []).length
            }},
        {
            title: t("admin.default.fields.time-start"), field: "timeStart", render: rowData => {
                rowData.timeStart = DateUtil.toString(DateUtil.fromUtc(new Date(rowData.timeStart)))
                return rowData.timeStart.replace("T", " ")
            }
        },
        {
            title: t("admin.default.fields.time-end"), field: "timeEnd", render: rowData => {
                rowData.timeEnd = DateUtil.toString(DateUtil.fromUtc(new Date(rowData.timeEnd)))
                return rowData.timeEnd.replace("T", " ")
            }
        }
    ]

    const [errors, setErrors] = useState([])
    const history = useHistory()

    const handleRowDelete = (oldData, resolve) => {
        SessionService.delete(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
                clearErrors()
                resolve()
            })
    }

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    return (
        <Container maxWidth="lg" style={{width: '100%'}}>
            <MaterialTable
                title={t("admin.session.title")}
                columns={columns}
                icons={tableIcons}
                data={query => {
                    return new Promise((resolve, reject) => {
                        SessionService.search(query)
                            .then(response => {
                                resolve({
                                    data: response.data.result,
                                    page: query.page,
                                    totalCount: response.data.totalCount,
                                })
                            })
                            .catch(error => {
                                console.log("got error", error, error.response)
                                setErrors([t("admin.default.errors.data-load")])
                            })
                    })
                }}
                actions={[
                    {
                        icon: () => <AddBox/>,
                        tooltip: t("admin.default.actions.add"),
                        position: "toolbar",
                        onClick: () => {
                            history.push(Path.ADMIN_SESSION_EDIT.replace(":id", "0"))
                        }
                    },
                    {
                        icon: () => <Edit/>,
                        tooltip: t("admin.default.actions.edit"),
                        onClick: (event, rowData) => {
                            history.push(Path.ADMIN_SESSION_EDIT.replace(":id", rowData.id))
                        }
                    }
                ]}
                editable={{
                    onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                            handleRowDelete(oldData, resolve)
                        })
                }}
                options={{
                    actionsColumnIndex: -1,
                }}
                localization={CompUtil.localizeTable(t)}
            />
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>
                }
            </div>
        </Container>
    )
}
