import {createStyles, makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => createStyles({
    paperContainer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('sm')]: {
            minHeight: 350
        }
    },
    heroGridContainer: {
        height: '100%',
        padding: '4%',
        color: theme.palette.tertiary.var3,
        '& .left-grid-item': {
            '& .date-text': {
                position: 'relative',
                marginTop: theme.spacing(1),
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: theme.spacing(12),
                },
                [theme.breakpoints.up('lg')]: {
                    marginTop: theme.spacing(5),
                },
                '&:before': {
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    bottom: '50%',
                    width: theme.spacing(10),
                    borderTop: '4px solid',
                    borderColor: theme.palette.secondary.main,
                    transform: 'translateY(-50%)',
                    [theme.breakpoints.up('sm')]: {
                        content: '""',
                    },

                }
            }
        },
        // '& .right-grid-item': {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     justifyContent: 'space-between',
        //     alignItems: 'flex-end'
        // }
        '& .right-grid-item': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: theme.spacing(0, 0, 6),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2, 0, 6),
                justifyContent: 'space-between',
            },
            [theme.breakpoints.up('lg')]: {
                alignItems: 'flex-end',
                padding: theme.spacing(0, 0, 4),
            }
        }
    },
    textSection: {
        maxWidth: '80%',
        fontWeight: '600',
        margin: theme.spacing(2.75, 0, 2),
        color: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(4, 0, 4)
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(8.75, 0, 5),
        },
    },
    timer: {
        display: 'flex',
        flexDirection: 'column',
        '& .timer-title-wrap': {
            marginBottom: 0,
            color: theme.palette.tertiary.var3,
            [theme.breakpoints.up('sm')]: {
                marginBottom: theme.spacing(2),
             },
            '& .timer-text': {
                width: 'auto',
                whiteSpace: 'nowrap',
                lineHeight: '20px',
                paddingRight: theme.spacing(1),
                color: theme.palette.tertiary.var3,
            },
            '& .border-line-right': {
                display: 'none',
                [theme.breakpoints.up('sm')]: {
                    display: 'block',
                    width: '100%',
                    borderTop: `4px solid ${theme.palette.secondary.main}`,
                 }
            }
        }
    },
    papersSessions: {
        backgroundColor: theme.palette.primary.var2,
        width: 'auto',
        display: 'block',
        '& .MuiTab-root': {
            padding: 0,
            marginRight: 60,
            [theme.breakpoints.up('sm')]: {
                marginRight: 80,
            }
        },
        '& .date-box': {
            backgroundColor: theme.palette.primary.var1
        },
        '& .Mui-selected': {
            '& .date-box': {
                backgroundColor: theme.palette.secondary.main
            }
        },
    },
    paperDateBox: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FA5F1C',
        '& h6': {
            color: theme.palette.common.white
        }
    },
    sponsorsMainWrap: {
        margin: theme.spacing(-8, 1.5, 0),
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(-8, 7.5, 0),
        },
        '& .title-by': {
            color: theme.palette.tertiary.var3,
            opacity: '50%'
        },
        '& .MuiTab-root': {
            maxWidth: 'initial'
        }
    },
    sponsorTabs: {
        backgroundColor: theme.palette.common.white,
        maxWidth: 800,
        minWidth: 200,
        height: 80,
        webkitBoxShadow: "0px 0px 0px 0px #FFF",
        mozBoxShadow: "0px 0px 0px #FFF",
        boxShadow: "0px 0px 0px #FFF",
        borderRadius: theme.spacing(1),
        width: '100%',
        '& .MuiTabs-scrollButtonsDesktop': {
            display: 'block'
        }
    },
    dateTabs: {
        width: '100%',
        height: 100,
        padding: '0 10px',
        [theme.breakpoints.up('sm')]: {
            padding: 0
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            display: 'inline-flex'
        }
    },
    paperTabs: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: theme.spacing(2, 0, 2),
        background: theme.palette.primary.var2,
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(5.25, 0, 3.25),
            flexDirection: 'row',
            alignItems: 'center'
        },
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(11.25, 0, 6.25),
        },
        '& .MuiTabs-flexContainer': {
            height: '100%',
        },
        '& h3': {
            color: theme.palette.tertiary.var2,
            padding: '10px',
            [theme.breakpoints.up('sm')]: {
                padding: '0 30px 0 30px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '0 70px 0 30px',
            }
        }
    },
    speakersWrap: {
        position: 'relative',
        marginRight: -theme.spacing(1),
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(5)
        },
        '& h6': {
            paddingRight: theme.spacing(3)
        }
    }
}));