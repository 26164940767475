import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
import {
    Avatar,
    Button,
    Checkbox,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import {styles} from "../styles/FormStyles"
import {requiredValidation} from "../utils/Validators"
import Alert from '@material-ui/lab/Alert';
import EventService from "../services/EventService";
import GroupService from "../services/GroupService";
import ProgressDialog from "../components/ProgressDialog";
import {Language, Path} from "../data/Constants";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@material-ui/lab";
import UserService from "../services/UserService";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FuncUtil from "../utils/FuncUtil";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

/**
 * Displays an event groups edit view
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 */
export default function GroupEditView() {

    const {t} = useTranslation()
    const classes = styles()
    const history = useHistory()
    const [state, setState] = useState({
        name: "New Group",
        event: {id: 0},
        users: []
    })
    let [errorz, setErrorz] = useState({
        name: "",
        event: ""
    })
    const [language] = useState(Language.current)
    const [users, setUsers] = useState([])
    const [errors, setErrors] = useState([])
    const [events, setEvents] = useState([])
    const [loading, setLoading] = useState(false)
    const {id} = useParams()
    let timeout = useRef(0)
    const importFileInput = useRef(null)

    useEffect(() => {
        setLoading(parseInt(id) > 0)
        GroupService.load(id).then((result) => {
            setState(result.data)
            setLoading(false)
        })
        EventService.find(0, 5000).then((result) => {
            setEvents(result.data)
        })
        return () => clearInterval(timeout.current)
    }, [])

    const submit = (e) => {
        e.preventDefault()
        errorz = {...errorz, name: requiredValidation("Group name", state.name)}
        if (state.event.id === 0) errorz = {...errorz, event: "Please select event"}
        setErrorz(errorz)
        clearErrors()
        let props = ["name", "event"]
        for (let i in props) {
            if (errorz[props[i]] && errorz[props[i]].length > 0) return
        }
        setLoading(true)
        GroupService.save(state).then((result) => {
            setLoading(false)
            history.push(Path.ADMIN_GROUPS)
        }).catch((error) => {
            setLoading(false)
            setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearErrors()
        })
    }

    const handleChange = (field, e, value) => {
        switch (field) {
            case "name":
                setState({...state, [field]: e.target.value})
                setErrorz({...errorz, [field]: requiredValidation("Name", e.target.value)})
                break;
            case "event":
                let event = events.filter(ev => ev.id === e.target.value)[0] || {id: 0}
                setState({...state, event: event})
                setErrorz({...errorz, event: event.id === 0 ? "Please select event" : ""})
                break;
            default:
                break;
        }
    }

    const clearErrors = () => {
        timeout.current = setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    const queryUsers = (query) => () => {
        UserService.query(query).then(response => {
            setUsers(response.data)
        })
    }

    const selectFile = (e) => {
        let data = e.target.files[0]
        setLoading(true)
        GroupService.import(id, data).then(response => {
            setState({...state, users: state.users.concat(response.data)})
            setLoading(false)
        }).catch(e => {
            console.log("user import error")
            importFileInput.current.value = ''
            setLoading(false)
            if(e.response.data.message) {
                setErrors([e.response.data.message])
                clearErrors()
            }
        })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <ProgressDialog open={loading}/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddBoxOutlined/>
                </Avatar>
                <Grid spacing={2} container direction="row" justify="center" alignItems="center">
                    <Grid item><Typography component="h1" variant="h5">{t('admin.group.title-edit')}</Typography></Grid>
                </Grid>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField className={classes.input}
                                           variant="outlined"
                                           margin="none"
                                           fullWidth
                                           required
                                           id="name"
                                           label={t('admin.default.fields.name')}
                                           name="name"
                                           autoComplete="name"
                                           autoFocus
                                           value={state.name}
                                           onChange={(e) => handleChange('name', e)}
                                           error={errorz.name !== ''}
                                           helperText={errorz.name}
                                />
                            </FormControl>
                            <FormControl fullWidth error={errorz.event !== ''} className={classes.formControl}>
                                <InputLabel id="event-select-label" className={classes.formLabel}>Event *</InputLabel>
                                <Select
                                    labelId="event-select-label"
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="event"
                                    label={t('admin.default.fields.event')}
                                    name="event"
                                    autoComplete="event"
                                    value={state.event.id}
                                    onChange={(e) => handleChange('event', e)}
                                    error={errorz.event !== ""}
                                >
                                    <MenuItem value="0"><em>{t('admin.default.labels.none')}</em></MenuItem>
                                    {events.map((e, index) =>
                                        <MenuItem key={index}
                                                  value={e.id}>{e.name[language] ? e.name[language] : e.name[Language.DEFAULT]}</MenuItem>
                                    )}
                                </Select>
                                <FormHelperText error={errorz.event !== ""}
                                                className={classes.helperText}>{errorz.event}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <Autocomplete
                                    value={state.users}
                                    multiple
                                    autoComplete
                                    options={users}
                                    filterOptions={(x) => x}
                                    onInputChange={(event, value) => {
                                        FuncUtil.debounce(queryUsers, 2000)(value)
                                    }}
                                    onChange={(e, value) => {
                                        setState({...state, users: [...value]})
                                    }}
                                    getOptionLabel={(option) => option.name + " " + option.surname}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('admin.default.fields.users')} fullWidth
                                                   variant="outlined"/>
                                    )}
                                    renderOption={(option, {selected}) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                style={{marginRight: 4}}
                                                checked={selected}
                                            />
                                            {option.name + " " + option.surname + " (" + option.email + ")"}
                                        </React.Fragment>
                                    )}
                                    getOptionSelected={(option, value) => {
                                        return state.users.filter(u => u.id === option.id)[0] != null
                                    }}
                                />
                            </FormControl>
                            {state.id && state.id > 0 && <FormControl fullWidth className={classes.formControl}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadIcon/>}
                                >{t("admin.group.actions.import")}
                                    <input ref={importFileInput} type="file" hidden accept={'csv'} onChange={selectFile}/>
                                </Button>
                            </FormControl>}
                            <Button
                                type="submit"
                                variant="contained"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={e => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>{t('admin.default.actions.cancel')}</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{width: '50%'}}
                                className={classes.submit}
                                onClick={submit}>{t('admin.default.actions.submit')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <div style={{height: '30px'}}/>
            <div>
                {errors.length > 0 &&
                <Alert severity="error">
                    {errors.map((msg, i) => {
                        return <div key={i}>{msg}</div>
                    })}
                </Alert>
                }
            </div>
        </Container>
    );
}
