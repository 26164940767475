import { SvgIcon } from '@material-ui/core';
import React from 'react';

/**
 * Globe button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function PositiveFeedBackIcon({ width, height }) {
    return (
        <SvgIcon style={{ width: width ? width : '100%', height: height ? height : '100%' }} viewBox="0 0 45.205 42.191">
            <rect id="Rectangle_153" data-name="Rectangle 153" width="45.205" height="42.191" rx="16" fill="#4B83E5" />
            <path id="Path_732" data-name="Path 732" d="M434.05,641.714c-.562-.187-1.089.421-.926,1.071a9.546,9.546,0,0,0,18.21,0c.164-.649-.364-1.257-.923-1.071a26.872,26.872,0,0,1-16.361,0Z" transform="translate(-419.628 -616.117)" fill="#424242" />
            <path id="Path_733" data-name="Path 733" d="M444.946,639.348a2.073,2.073,0,1,1-2.072-2.072A2.073,2.073,0,0,1,444.946,639.348Z" transform="translate(-409.592 -621.853)" fill="#424242" />
            <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="2.073" cy="2.073" rx="2.073" ry="2.073" transform="translate(9.851 15.423)" fill="#424242" />
        </SvgIcon>
    )
}
