import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Divider, FormControl, FormHelperText,
    Grid, InputLabel,
    makeStyles, MenuItem, Select,
    TextField,
    Typography,
    useTheme
} from '@material-ui/core';
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";
import Alert from '@material-ui/lab/Alert';
import { emailValidation, phoneValidation, requiredValidation } from "../utils/Validators";
import FileUpload from "../components/FileUpload";
import { Autocomplete } from "@material-ui/lab";
import ProgressDialog from "../components/ProgressDialog";
import { Visibility } from "../data/Constants";
import FileService from "../services/FileService";
import GAEvent from "../models/GAEvent";
import { useAnalytics } from "../data/Analytics";
import { useTranslation } from "react-i18next";
import { styles } from "../styles/FormStyles"
import { SocialMediaName } from '../data/Constants';

export default function ProfileEditView() {

    const { t } = useTranslation()
    const classes = makeStyles((theme) => ({
        root: {},
        avatar: {
            height: 100,
            width: 100
        },
        upload: {
            padding: theme.spacing(1)
        }
    }))()
    const formClasses = styles()
    const user = AuthService.user();
    const theme = useTheme()
    const [changedFields, setChangedFields] = useState({ id: user.id, socialMedia: {} })
    const [state, setState] = useState({
        id: user.id,
        name: user.name,
        password: '***************',
        surname: user.surname,
        email: user.email,
        phone: user.phone,
        image: user.image,
        description: user.description,
        function: user.function,
        organization: user.organization,
        tags: user.tags,
        apiToken: user.apiToken,
        visibility: user.visibility,
        socialMedia: user.socialMedia
    });
    const [errorz, setErrorz] = useState({
        name: "",
        surname: "",
        password: "",
        email: "",
        phone: "",
        visibility: ""
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [alerts, setAlerts] = useState([])
    const analytics = useAnalytics()
    const position = state.function ? state.function + ' | ' +
        (state.organization ? state.organization : '') : ''

    const handleChange = (field, event, value) => {
        switch (field) {
            case "tags":
                setChangedFields({ ...changedFields, tags: value })
                setState({ ...state, tags: value })
                break;
            case "phone":
                setChangedFields({ ...changedFields, phone: event.target.value })
                setState({ ...state, phone: event.target.value })
                break;
            case 'socialMedia':
                const changedSocialMedia = changedFields.socialMedia
                const newSocialMedia = state.socialMedia
                changedSocialMedia[event.target.name] = event.target.value
                newSocialMedia[event.target.name] = event.target.value
                setChangedFields({ ...changedFields, socialMedia: changedSocialMedia })
                setState({ ...state, socialMedia: newSocialMedia })
                break;
            default:
                setChangedFields({ ...changedFields, [event.target.name]: event.target.value })
                setState({ ...state, [event.target.name]: event.target.value })
                break;
        }
        handleErrors(event)
    }

    const onUploadComplete = (response) => {
        setState({ ...state, image: null })
        setState({ ...state, image: response.data })
        UserService.saveUserImage(state.id, response.data).then(res => {
            AuthService.user(res.data) 
        })
    }

    const onRemoveFile = () => {
        UserService.deleteUserImage(state.id).then(response => {
            setState({ ...state, image: null })
            AuthService.user(response.data) 
        })
    }

    //FE error cases
    const handleErrors = (event) => {
        switch (event.target.name) {
            case "email":
                setErrorz({ ...errorz, email: emailValidation(event.target.value) })
                break;
            case "name":
                setErrorz({ ...errorz, name: requiredValidation("Name", event.target.value) })
                break;
            case "surname":
                setErrorz({ ...errorz, surname: requiredValidation("Surname", event.target.value) })
                break;
            case "phone":
                setErrorz({ ...errorz, phone: phoneValidation(event.target.value) })
                break;
            case "password":
                setErrorz({ ...errorz, password: requiredValidation("Password", event.target.value) })
                break
            case "visibility":
                setErrorz({ ...errorz, password: requiredValidation("Visibility", event.target.value) })
                break
            default:
                break;
        }
    }

    //Submit handle
    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        UserService.updateProfile(changedFields).then(response => {
            AuthService.user(response.data)
            console.log(response.data)
            analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.PROFILE_EDIT,
                response.data.name + ' ' + response.data.surname, response.data.id))
            setChangedFields({ id: user.id, socialMedia: {} })
            setErrors([])
            setLoading(false)
            setAlerts(["Your profile has been updated"])
            handleErrors(event)
            clearAlerts()
        }).catch(error => {
            if (user) analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.PROFILE_ERROR, user.name + ' ' + user.surname, user.id))
            setLoading(false)
            if (error.response.data.error.indexOf("DataIntegrityViolationException") > -1) setErrors(["Username or Email already exists"])
            else setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
            clearAlerts()
        })
    }

    const clearAlerts = () => setTimeout(() => {
        setAlerts([])
        setErrors([])
    }, 9000)

    // Profile view
    return (
        <Container maxWidth="lg">
            <ProgressDialog open={loading} />
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Box alignItems="center" display="flex" flexDirection="column">
                                <Avatar className={classes.avatar}
                                    src={state.image ? FileService.url(state.image.uri) : ''} />
                                <Typography color="textPrimary" gutterBottom variant="h5" component="h2"
                                    style={{ marginTop: theme.spacing(2) }}>
                                    {state.name + ' ' + state.surname}
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                    {position}
                                </Typography>
                                <Typography color="textSecondary" variant="body1">
                                </Typography>
                            </Box>
                        </CardContent>
                        <Divider />
                        <div className={classes.upload}>
                            <FileUpload onComplete={onUploadComplete} files={state.image ? [state.image] : []}
                                label={t('client.user.actions.upload-image')}
                                accept={".jpg, .jpeg, .gif, .png"} onRemove={onRemoveFile} update />
                        </div>
                    </Card>
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                    <form autoComplete="off" noValidate className={classes.root}>
                        <Card>
                            <CardHeader
                                subheader={t('client.user.labels.profile-info')}
                                title={t('client.user.labels.profile-title')}
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label={t('client.user.labels.first-name')}
                                            name="name"
                                            onChange={(e) => handleChange("name", e)}
                                            value={state.name}
                                            variant="outlined"
                                            error={errorz.name !== ""}
                                            helperText={errorz.name}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label={t('client.user.labels.last-name')}
                                            name="surname"
                                            onChange={(e) => handleChange("surname", e)}
                                            value={state.surname}
                                            variant="outlined"
                                            error={errorz.surname !== ""}
                                            helperText={errorz.surname}
                                        />

                                    </Grid>
                                    <Grid item md={6} xs={12}>

                                        <TextField
                                            fullWidth
                                            disabled
                                            label={t('client.user.labels.email-address')}
                                            name="email"
                                            onChange={(e) => handleChange("email", e)}
                                            value={state.email}
                                            variant="outlined"
                                            error={errorz.email !== ""}
                                            helperText={errorz.email}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.phone')}
                                            name="phone"
                                            onChange={(e) => handleChange("phone", e)}
                                            type="tel"
                                            value={state.phone}
                                            variant="outlined"
                                            error={errorz.phone !== ""}
                                            helperText={errorz.phone}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label={t('client.user.labels.password')}
                                            name="password"
                                            id="password"
                                            onChange={(e) => handleChange("password", e)}
                                            type="password"
                                            value={state.password}
                                            variant="outlined"
                                            error={errorz.password !== ""}
                                            helperText={errorz.password}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="tags-filled"
                                            freeSolo
                                            options={[]}
                                            value={state.tags}
                                            onChange={(e, value) => handleChange("tags", e, value)}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label={t('client.user.labels.tags')}
                                                    placeholder={t('client.user.labels.tags')} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.function')}
                                            name="function"
                                            onChange={(e) => handleChange("function", e)}
                                            value={state.function}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.organization')}
                                            name="organization"
                                            onChange={(e) => handleChange("organization", e)}
                                            value={state.organization || ''}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.description')}
                                            name="description"
                                            multiline
                                            onChange={(e) => handleChange("description", e)}
                                            SelectProps={{ native: true }}
                                            value={state.description}
                                            variant="outlined"
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth error={errorz.visibility !== ""}>
                                            <InputLabel id="visibility-select-label" className={formClasses.formLabel}>{t('client.user.labels.visibility')}</InputLabel>
                                            <Select
                                                labelId="visibility-select-label"
                                                variant="outlined"
                                                margin="none"
                                                required
                                                fullWidth
                                                id="visibility"
                                                label={t('client.user.labels.visibility')}
                                                name="visibility"
                                                autoComplete="visibility"
                                                value={state.visibility}
                                                onChange={(e) => handleChange('visibility', e)}
                                                error={errorz.visibility !== ""}>
                                                {[Visibility.HIDDEN, Visibility.VISIBLE, Visibility.ANONYMOUS].map((e, index) =>
                                                    <MenuItem key={index} value={e}>{t(`data.visibility-type.${e.toLowerCase()}`)}</MenuItem>
                                                )}
                                            </Select>
                                            <FormHelperText error={errorz.visibility !== ""}
                                                className={formClasses.helperText}>{errorz.visibility}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </CardContent>
                            <CardHeader
                                title="Social Media"
                                subheader="Manage your social links"
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.linkedIn')}
                                            name={SocialMediaName.LINKEDIN}
                                            multiline
                                            onChange={(e) => handleChange('socialMedia', e)}
                                            SelectProps={{ native: true }}
                                            value={state.socialMedia[SocialMediaName.LINKEDIN]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.facebook')}
                                            name={SocialMediaName.FACEBOOK}
                                            multiline
                                            onChange={(e) => handleChange('socialMedia', e)}
                                            SelectProps={{ native: true }}
                                            value={state.socialMedia[SocialMediaName.FACEBOOK]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.instagram')}
                                            name={SocialMediaName.INSTAGRAM}
                                            multiline
                                            onChange={(e) => handleChange('socialMedia', e)}
                                            SelectProps={{ native: true }}
                                            value={state.socialMedia[SocialMediaName.INSTAGRAM]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.twitter')}
                                            name={SocialMediaName.TWITTER}
                                            multiline
                                            onChange={(e) => handleChange("socialMedia", e)}
                                            SelectProps={{ native: true }}
                                            value={state.socialMedia[SocialMediaName.TWITTER]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('client.user.labels.website')}
                                            name={SocialMediaName.WEBSITE}
                                            multiline
                                            onChange={(e) => handleChange("socialMedia", e)}
                                            SelectProps={{ native: true }}
                                            value={state.socialMedia[SocialMediaName.WEBSITE]}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Box display="flex" justifyContent="flex-end" p={2}>
                                <Button color="primary" variant="contained" onClick={handleSubmit}>{t('client.user.actions.save-details')}</Button>
                            </Box>
                        </Card>
                    </form>

                    <Grid item md={12} xs={12} style={{ marginTop: theme.spacing(4) }}>
                        {alerts.length > 0 &&
                            <Alert severity="success">
                                {alerts.map((msg, i) => {
                                    return <div key={i}>{msg}</div>
                                })}
                            </Alert>}
                        {errors.length > 0 &&
                            <Alert severity="error">
                                {errors.map((msg, i) => {
                                    return <div key={i}>{msg}</div>
                                })}
                            </Alert>}
                    </Grid>

                </Grid>

            </Grid>
        </Container>
    );
};
