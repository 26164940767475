import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRoom } from "../data/Store";
import DateUtil from "../utils/DateUtil";
import { createStyles, Grid, makeStyles, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import SessionCard from '../components/SessionCard'
import ProgressDialog from "../components/ProgressDialog";
import EventService from "../services/EventService";
import SessionTab from "../components/SessionTab"
import { theme } from '../styles/Theme';
import { useAuth } from "../components/ProvideAuth";
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
import useEventContext from "../data/EventContext";
import { SessionType } from "../data/Constants";
import SessionService from "../services/SessionService";

const styles = makeStyles((theme) => createStyles({
    paper: {
        backgroundColor: theme.palette.primary.var2,
        width: 'auto',
        display: 'block',
        paddingBottom: theme.spacing(3),
        '& .MuiTab-root': {
            padding: 0,
            marginRight: 80
        },
        '& .date-box': {
            backgroundColor: theme.palette.primary.var1
        },
        '& .Mui-selected': {
            '& .date-box': {
                backgroundColor: theme.palette.secondary.main
            }
        },
    },
    pageTitle: {
        width: '100%',
        paddingBottom: theme.spacing(3),
        backgroundColor: 'transparent',
        color: theme.palette.tertiary.var2
    },
    paperTabs: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(5),
        marginBottom: theme.spacing(3),
        backgroundColor: theme.palette.primary.var2,
        '& .MuiTabs-flexContainer': {
            height: '100%',
        },
    },
    dateTabs: {
        width: '100%',
        height: 100,
    },
}))

/**
 * Sessions page view
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function EventSessionsView(props) {

    const { t } = useTranslation()
    const classes = styles()
    const auth = useAuth()
    const dispatch = useDispatch()
    const [index, setIndex] = useState(0)
    const [dates, setDates] = useState([])
    const [loading, setLoading] = useState(true)
    const [sessions, setSessions] = useState([])
    const { event, loadEvent } = useEventContext()
    const { id } = useParams()

    useEffect(() => {
        if (!event) setLoading(true)
        loadEvent(parseInt(id)).then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (event == null) return
        if (event.id !== parseInt(id)) return // skip for non current event
        let dates = DateUtil.extrapolate(getSessions().map((session) => DateUtil.fromUtc(new Date(session.timeStart))))
        setDates(dates)
        setIndex(0)
        showSessions(dates[0])
        if (event.room) dispatch(setRoom(event.room))
        console.log("event sessions mount", event)
    }, [event])

    useEffect(() => {
        if (event == null) return
        let dates = DateUtil.extrapolate(getSessions().map((session) => DateUtil.fromUtc(new Date(session.timeStart))))
        setDates(dates)
        setIndex(0)
        showSessions(dates[0])
    }, [props.max])

    useLayoutEffect(() => () => {
        dispatch(setRoom(null))
        console.log("event sessions unmount")
    }, [])

    const onDateChange = (event, value) => {
        setIndex(value)
        showSessions(dates[value])
    }

    const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] || (a[f(b)] = [])).push(b), a), {})

    const showSessions = (date) => {
        const sessions = getSessions().filter((s) => {
            let ss = DateUtil.fromUtc(new Date(s.timeStart)).getTime(), ts = date.getTime(), te = ts + 86400000
            return (ts <= ss) && (ss < te)
        })
        setSessions(groupBy(sessions, session => deleteSeconds(session.timeStart)))
    }

    const getSessions = () => (event.sessions || [])
        .filter(s => SessionService.hasAccess(auth.user, event, s))
        .filter(s => ((props.max && (new Date(s.timeEnd) < props.max)) || !props.max)
            && SessionType.NETWORKING !== s.type)

    
    const deleteSeconds = (time) => {
        const date = Date.parse(time);
        return new Date(date - new Date(date).getSeconds() * 1000).getTime();
    }

    return (
        <main>
            <ProgressDialog open={loading} />
            {event && EventService.hasAccess(auth.user, event) && <>
                <Typography variant="h3" className={classes.pageTitle}>{props.title}</Typography>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <div className={classes.paperTabs}>
                        <Tabs
                            id="sessions-tabs"
                            value={index}
                            onChange={onDateChange}
                            className={classes.dateTabs}
                            orientation="horizontal"
                            variant="scrollable"
                            indicatorColor="secondary"
                            textColor="primary"
                            TabIndicatorProps={{
                                style: {
                                    height: "4px",
                                    backgroundColor: theme.palette.secondary.main,
                                }
                            }}>
                            {dates.map((date, i) =>
                                <Tab key={"session-tab-" + i}
                                    label={<SessionTab date={date} />}>
                                </Tab>
                            )}
                        </Tabs>
                    </div>
                    <Grid container>
                        {Object.keys(sessions).map(timeKey =>
                            sessions[timeKey].map((session, index) =>
                                (sessions[timeKey].length % 2 !== 1 || index !== sessions[timeKey].length - 1)
                                    ? <Grid item xs={6} key={index}>
                                        <SessionCard xs={6} session={session} event={event} />
                                    </Grid>
                                    : <Grid item xs={12} key={index}>
                                        <SessionCard xs={12} session={session} event={event} />
                                    </Grid>
                            ))
                        }
                    </Grid>
                </Paper>
            </>}
        </main>
    )
}

EventSessionsView.propTypes = {
    title: PropTypes.string,
    max: PropTypes.object
}

EventSessionsView.defaultProps = {
    title: "Sessions"
}
