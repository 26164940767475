import {combineReducers, createStore} from "redux";
import {apiFacade} from "./ApiFacade";
import {Language} from "./Constants";

/**
 * Global application store
 * @author dame.gjorgjievski
 */
const ADD_EVENT = 'ADD_EVENT';
const SET_EVENTS = 'SET_EVENTS';
const SET_EVENT = 'SET_EVENT';
const SET_SESSION = 'SET_SESSION';
const ADD_ROOM = 'ADD_ROOM';
const DEL_ROOM = 'DEL_ROOM';
const SET_ROOM = 'SET_ROOM';
const SET_USER = 'SET_USER';
const SET_LANGUAGE = 'SET_LANGUAGE';

/**
 * Add an event to the collection of available events
 * @param event
 * @returns {{type: string, event}}
 */
export function addEvent(event) {
    return {type: ADD_EVENT, event}
}

/**
 * Set list of available user events
 * @param events collection of events
 * @returns {{type: string, events}}
 */
export function setEvents(events) {
    return {type: SET_EVENTS, events}
}

/**
 * Set current event viewed by current user
 * @param event instance of event to set
 * @returns {{type: string, event}}
 */
export function setEvent(event) {
    const l = Language.current()
    if (event && event.name) document.title = event.name[l] ? event.name[l] : event.name[Language.DEFAULT]
    return {type: SET_EVENT, event}
}

/**
 * Sets the current session viewed by current user
 * @param session instance of session to set
 * @returns {{session, type: string}}
 */
export function setSession(session) {
    return {type: SET_SESSION, session}
}

/**
 * Sets the current application language
 * @param language instance of language to set
 * @returns {{language, type: string}}
 */
export function setLanguage(language) {
    return {type: SET_LANGUAGE, language}
}

/**
 * Add a room to the collection of known rooms
 * @param room
 * @returns {{type: string, event}}
 */
export function addRoom(room) {
    return {type: ADD_ROOM, room}
}

/**
 * Removes a room from the collection of known rooms
 * @param room room to remove
 * @returns {{type: string, event}}
 */
export function removeRoom(room) {
    return {type: DEL_ROOM, room}
}

/**
 * Sets the current room displayed on page. If setting a non null room then API join action is invoked,
 * otherwise if setting a null room then API leave action is invoked
 * @param room current room to set, can be null to unset current room
 * @returns {{type: string, event}}
 */
export function setRoom(room, roomName) {
    if (room) apiFacade.joinRoom(room, roomName)
    else apiFacade.leaveRoom(roomName)
    return {type: SET_ROOM, room}
}

/**
 * Sets the current selected user state, for which profile info is displayed
 * @param user current user to set, can be null to unset current user
 * @returns {{type: string, user}}
 */
export function setUser(user) {
    return {type: SET_USER, user}
}

/**
 * Events reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {*[]}
 */
function events(state = [], action) {
    switch (action.type) {
        case ADD_EVENT:
            return [...state, action.event];
        case SET_EVENTS:
            return [...action.events]
        default:
            return state;
    }
}

/**
 * Event reducer
 * @param state
 * @param action
 * @returns {null|*}
 */
function event(state = null, action) {
    switch (action.type) {
        case SET_EVENT:
            return {...action.event}
        default:
            return state;
    }
}

/**
 * Rooms reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {*[]}
 */
function rooms(state = [], action) {
    switch (action.type) {
        case ADD_ROOM:
            let r = state.filter(r => r.id === action.room.id)[0]
            return r ? state : [...state, action.room]
        case DEL_ROOM:
            let room = state.filter(r => r.id === action.room.id)[0] || null
            let idx = state.indexOf(room)
            if (idx > -1) state.splice(idx, 1)
            return [...state]
        default:
            return state;
    }
}

/**
 * Room reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {null|*}
 */
function room(state = null, action) {
    switch (action.type) {
        case SET_ROOM:
            return {...action.room}
        default:
            return state
    }
}

/**
 * User reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {{}|null}
 */
function user(state = null, action) {
    switch(action.type) {
        case SET_USER:
            return {...action.user}
        default:
            return state
    }
}

/**
 * Session reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {null|{action: undefined}}
 */
function session(state = null, action) {
    switch (action.type) {
        case SET_SESSION:
            return {...action.session}
        default:
            return state
    }
}

/**
 * Language reducer
 * @param state reducer state
 * @param action reducer action
 * @returns {null|{action: undefined}}
 */
function language(state = Language.current(), action) {
    switch (action.type) {
        case SET_LANGUAGE:
            localStorage.setItem(Language.KEY, action.language)
            return action.language
        default:
            return state
    }
}

/**
 * Declare combined reducer
 * @type {Reducer<CombinedState<{room: (null|*); event: (null|*); rooms: *[]; events: *[]}>>}
 */
const reducer = combineReducers({
    events, event, rooms, room, session, language, user
});

/**
 * Global redux store
 * @type {Store<{events: []}, Action>}
 * @author dame.gjorgjievski
 */
export const store = createStore(reducer);
