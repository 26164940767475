import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";
import {MuiThemeProvider} from "@material-ui/core";
import {theme} from './styles/Theme'
import {store} from './data/Store'
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n"
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

/**
 * React init script, specifies context providers wrappers for main application component
 * @author dame.gjorgjievski
 */

ReactDOM.render(
    <React.StrictMode>
            <Router>
                <Provider store={store}>
                    <MuiThemeProvider theme={theme}>
                        <I18nextProvider i18n={i18n}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <App/>
                                </MuiPickersUtilsProvider>
                        </I18nextProvider>
                    </MuiThemeProvider>
                </Provider>
            </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
