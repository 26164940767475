import {createStyles, makeStyles} from '@material-ui/core/styles';

/**
 * Style for event intro view
 * @type {(props?: any) => ClassNameMap<"leftHalfInner" | "forgotAdorment" | "loginBtn" | "rightHalfInner" | "landingWrap" | "textSection" | "codeInput" | "headWrap" | "title" | "signUp" | "logoWrap" | "languageSwitch" | "faIdentityBox" | "formGrid">}
 * @author petar.todorovski
 */
export const styles = makeStyles((theme) => createStyles({
    headWrap: {
        paddingBottom: theme.spacing(4)
    },
    landingRoot: {
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            height: 'auto'
        },
    },
    landingWrap: {
        display: 'flex',
        flexDirection:  'column',
        height: '100%',
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 4, 0),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 8, 0),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1.5, 20, 0),
        }
    },
    landingGrid: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            height: '100%'
        },
        '& .grid-item': {
            display: 'flex',
            alignItems: 'center',
            '&.left': {
                alignItems: 'flex-start'
            },
            '&.right': {
                display: 'none',
                [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                    justifyContent: 'center',
                },
            }
        }
    },
    logoWrap: {
        maxWidth: 200,
        '& .logo-left': {
            color: theme.palette.primary.main
        },
        '& .logo-right': {
            color: theme.palette.secondary.main
        },
        '& img': {
            height: 40
        }
    },
    leftHalfInner: {
        width: '100%',
        maxWidth: 570
    },
    forgotAdorment: {
        color: theme.palette.secondary.main,
        fontSize: 12,
        textDecoration: 'none'
    },
    rightHalfInner: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            justifyContent: 'flex-end'
        },
        '& .image-wrap': {
            display: 'flex',
            justifyContent: 'flex-end',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            maxHeight: 800,
            zIndex: 1,
            // [theme.breakpoints.up('md')]: {
            //     justifyContent: 'flex-end'
            // },
        },
        '& .top-cube': {
            top: 0,
            right: 0
        },
        '& .cross-cube': {
            bottom: 0,
            left: 0
        }
    },
    textSection: {
        padding: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(10, 0, 7, 0),
        },
        '& .top-text': {
            color: theme.palette.secondary.main,
        },
        '& .bottom-text': {
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            fontSize: 16,
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(4),
                fontSize: 18,
            },
        }
    },
    formGrid: {
        width: '100%',
        padding: theme.spacing(1.5),
        margin: theme.spacing(3.75, 0),
        backgroundColor: theme.palette.common.white,
        boxShadow: '2px 14px 60px -15px rgba(0,0,0,0.36)',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% + 16px)',
        }
    },
    faIdentityBox: {
        maxWidth: 465
    },
    codeInput: {
        margin: 0
    },
    title: {
        color: theme.palette.primary.main
    },
    loginBtn: {
        maxWidth: '150px',
        marginRight: theme.spacing(4),
        backgroundColor: theme.palette.quaternary.var1,
        color: theme.palette.quaternary.var3,
        '&:hover': {
            backgroundColor: theme.palette.quaternary.var2,
            color: theme.palette.quaternary.var4,
        }
    },
    signUp: {
        textDecoration: 'none',
        '& .sign-up-text': {
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.main
        }
    },
    languageSwitch: {
        height: 10,
        '& .lng': {
            color: theme.palette.primary.var1,
            '&.lng-active': {
                color: theme.palette.secondary.main
            }
        },
        '& .MuiButtonGroup-groupedTextPrimary:not(:last-child)': {
            borderColor: theme.palette.primary.main,
            borderWidth: 2
        }
    },
    errors: {
        marginTop: theme.spacing(3),
        width: 'calc(100% + ' + theme.spacing(2) + 'px)',
        '& .MuiAlert-root': {
            width: '100%',
        }
    },
    icon: {
        color: theme.palette.background.dark,
        marginRight: theme.spacing(1)
    }
}))