import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DateUtil from "../utils/DateUtil";
import React from "react";
import PropTypes from "prop-types";
import { theme } from '../styles/Theme'
import clsx from "clsx";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const styles = makeStyles((theme) => createStyles({
    dateBoxWrapper: {
        position: 'relative',
        paddingLeft: '60px'
    },
    dateBox: {
        position:'absolute',
        top: '50%',
        left: '0',
        transform: 'translateY(-50%)',
        width: 50,
        height: 50,
        borderRadius: '7px'
    },
    fullDate: {
        opacity: '50%'
    },
    dayTitle: {
        color: theme.palette.tertiary.var2
    }
}));

/**
 * Session tab component
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function SessionTab(props) {

    const classes = styles()
    return (
        <Box className={classes.dateBoxWrapper}>
            <Box className={clsx(classes.dateBox, 'date-box')} display="flex" justifyContent="center" alignItems="center" color="white" bgcolor={theme.palette.primary.var3}>
                <Typography variant="h4" component="span" align="center">
                    {DateUtil.getDate(props.date)}
                </Typography>
            </Box>
            <Box color={theme.palette.tertiary.var2}>
                <Typography className={classes.dayTitle} variant="h6" component="p" align="left">
                    {DateUtil.getWeekDay(props.date)}
                </Typography>
                <Typography align="left" variant="body1" component="span" className={classes.fullDate}>
                    {DateUtil.format(DateUtil.fromUtc(new Date(props.date)), DateUtil.FORMAT_READABLE)}
                </Typography>
            </Box>
        </Box>
    )
}

SessionTab.propTypes = {
    date: PropTypes.object
}