import {useDispatch, useSelector} from "react-redux";
import {Language} from "./Constants";
import {setLanguage} from "./Store";
import {useTranslation} from "react-i18next";

/**
 * Hook providing language manipulation interface
 * @author dame.gjorgjievski
 * @author alexander.schamne
 * @returns {{default: (function(): string), l, lang: (function(*=): string), value: ((function(*, *=): (null|*))|*)}}
 */
export default function useLanguage() {

    const {i18n} = useTranslation()
    const l = useSelector(state => state.language)
    const dispatch = useDispatch()

    /**
     * Accessor method for selected language in order : react store, local storage, default language
     * @returns {void|*} current language from storage
     */
    const lang = function () {
        return l || localStorage.getItem(Language.KEY) || Language.DEFAULT
    }

    /**
     * Sets the language in react store and local storage
     * @param language
     */
    const setLang = function (language) {
        if (!language) return
        dispatch(setLanguage(language))
        i18n.changeLanguage(language)
        localStorage.setItem(Language.KEY, language)
    }

    if (!localStorage.getItem(Language.KEY)) lang(Language.DEFAULT)

    /**
     * Accessor getter/setter for the value of a translation object in current language
     * @param translation instance of translation object
     * @param value translation value to set
     * @param maxSize
     */
    const value = function (translation, value, maxSize) {
        if (translation == null) return null
        if (value) translation[l] = value
        if (maxSize) {
            let t = translation[l] || "", d = translation[Language.DEFAULT] || ""
            return t ? t.substr(0, Math.min(maxSize, t.length))
                : d.substr(0, Math.min(maxSize, d.length))
        } else {
            return translation[l] ? translation[l] : translation[Language.DEFAULT]
        }
    }

    return {
        l,
        lang: lang,
        setLang: setLang,
        value: value
    }
}
