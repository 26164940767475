import {ENDPOINT_SESSIONS} from "../data/Configuration"
import ApiService from "../data/ApiService";
import axios from "axios";

/**
 * Event service, manages CRUD operations for event data
 * @author dame.gjorgjievski
 **/
const SessionService = new ApiService(ENDPOINT_SESSIONS, ["name.en", "description.en", "event.name.en"])

/**
 * Load session feedback data
 * @param id session identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
SessionService.loadFeedback = function (id) {
    return axios.get(this.endpoint + id + "/feedback")
}

/**
 * Add or update session feedback
 * @param id session identifier
 * @param type feedback type
 * @returns {Promise<AxiosResponse<any>>}
 */
SessionService.addFeedback = function (id, type) {
    return axios.post(this.endpoint + id + "/feedback/" + type)
}

/**
 * Load session wordcloud data
 * @param id session identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
SessionService.loadWordCloud = function (id) {
    return axios.get(this.endpoint + id + "/wordcloud")
}

/**
 * Add or update session word cloud entry
 * @param id session identifier
 * @param content word cloud text content
 * @returns {Promise<AxiosResponse<any>>}
 */
SessionService.addWordCloud = function (id, content) {
    return axios.post(this.endpoint + id + "/wordcloud", {content: content})
}

/**
 * Checks if a user has access to session
 * @param session instance of session to check
 * @param user instance of user to authorize
 */
SessionService.hasAccess = function (user, event, session) {
    if (!user) return false
    // if only selected users are allowed, check if specified user is in the users list
    if (event.user.id === user.id) return true
    if (session.features.selectedUsers) {
        const isUserInGroup = !!event.groups.filter(g => session.groups.includes(g.id))
            .map(g => g.users).flat().find((u) => u.id === user.id)
        return isUserInGroup
    }
    // TODO : need other detection heuristic for max users
    return true
}

export default SessionService
