import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Message / chat button icon
 * @param className component class name
 * @param onClick click handler
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function MessageIcon({className, onClick, fontsize}) {
    return (
        <SvgIcon className={`${className}`} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fontSize={fontsize}>
            <path id="Path_750" data-name="Path 750"
                  d="M17.5-17.5H2.5A2.5,2.5,0,0,0,0-15V-3.75a2.5,2.5,0,0,0,2.5,2.5H6.25V2.031a.467.467,0,0,0,.469.469A.448.448,0,0,0,7,2.406L11.875-1.25H17.5A2.5,2.5,0,0,0,20-3.75V-15A2.5,2.5,0,0,0,17.5-17.5ZM18.75-3.75A1.254,1.254,0,0,1,17.5-2.5H11.457l-.332.25L7.5.469V-2.5h-5A1.254,1.254,0,0,1,1.25-3.75V-15A1.254,1.254,0,0,1,2.5-16.25h15A1.254,1.254,0,0,1,18.75-15ZM10.938-8.125H5.313A.313.313,0,0,0,5-7.812v.625a.313.313,0,0,0,.313.313h5.625a.313.313,0,0,0,.313-.312v-.625A.313.313,0,0,0,10.938-8.125Zm3.75-3.75H5.313A.313.313,0,0,0,5-11.562v.625a.313.313,0,0,0,.313.313h9.375A.313.313,0,0,0,15-10.937v-.625A.313.313,0,0,0,14.688-11.875Z"
                  transform="translate(0 17.5)" fill={theme.palette.primary.var3}/>
        </SvgIcon>
    )
}