import React, { useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../styles/TableIcons";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { AddBox, Edit } from "@material-ui/icons";
import SponsorService from "../services/SponsorService";
import { Container } from "@material-ui/core";
import { Path, Role } from '../data/Constants';
import useLanguage from "../data/Language";
import { useTranslation } from "react-i18next";
import CompUtil from "../utils/CompUtil";
import { useAuth } from '../components/ProvideAuth';

/**
 * The sponsors listing component, lists all sponsors managed by a participant user
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function SponsorsListView() {

    const auth = useAuth()
    const [errors, setErrors] = useState([])
    const history = useHistory()
    const { l, value } = useLanguage()
    const { t } = useTranslation()
    const columns = [
        { title: t("admin.default.fields.id"), field: "id", hidden: true },
        { title: t("admin.default.fields.name"), field: `name`, render: rowData => value(rowData.name, null, 30) },
        { title: t("admin.default.fields.type"), field: "type", render: rowData => t(`data.sponsor-type.${rowData.type.toString().toLowerCase()}`) },
        { title: t("admin.sponsor.fields.website"), field: "url" },
        {
            title: t("admin.default.fields.time-created"), field: "timeCreated", render: rowData => {
                return rowData.timeCreated.replace("T", " ")
            }
        }
    ]

    const handleRowDelete = (oldData, resolve) => {
        SponsorService.delete(oldData.id)
            .then(res => {
                resolve()
            })
            .catch(error => {
                setErrors(error.response.data.errors.length > 0 ? error.response.data.errors : [error.response.data.message])
                clearErrors()
                resolve()
            })
    }

    const sponsorActions = () => {
        return [
            {
                icon: () => <Edit />,
                tooltip: t("admin.default.actions.edit"),
                onClick: (event, rowData) => {
                    const path = auth.hasRole(Role.SPONSOR) ? Path.SPONSOR_EDIT : Path.ADMIN_SPONSOR_EDIT
                    history.push(path.replace(":id", rowData.id))
                },
            },
            auth.hasRole(Role.SPONSOR) ? null : {
                icon: () => <AddBox />,
                tooltip: t("admin.default.actions.add"),
                position: "toolbar",
                onClick: () => {
                    const path = auth.hasRole(Role.SPONSOR) ? Path.SPONSOR_EDIT : Path.ADMIN_SPONSOR_EDIT
                    history.push(path.replace(":id", "0"))
                }
            }
        ];
    }

    const sponsorEditable = () => {
        return auth.hasRole(Role.SPONSOR)
            ? null
            : {
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        handleRowDelete(oldData, resolve)
                    })
            }
    }

    const clearErrors = () => {
        setTimeout(() => {
            setErrors([])
        }, 3000)
    }

    const actions = sponsorActions();
    const editable = sponsorEditable();
   
    return (
        <Container maxWidth="lg" style={{ width: '100%' }}>
            <MaterialTable
                title={t("admin.sponsor.title")}
                columns={columns}
                icons={tableIcons}
                data={query => {
                    return new Promise((resolve, reject) => {
                        SponsorService.search(query)
                            .then(response => {
                                resolve({
                                    data: response.data.result,
                                    page: query.page,
                                    totalCount: response.data.totalCount,
                                })
                            })
                            .catch(error => {
                                console.log("got error", error, error.response)
                                setErrors([t("admin.default.errors.data-load")])
                            })
                    })
                }}
                actions={actions}
                editable={editable}
                options={{
                    actionsColumnIndex: -1,
                }}
                localization={CompUtil.localizeTable(t)}
            />
            <div style={{ height: '30px' }} />
            <div>
                {errors.length > 0 &&
                    <Alert severity="error">
                        {errors.map((msg, i) => {
                            return <div key={i}>{msg}</div>
                        })}
                    </Alert>
                }
            </div>
        </Container>
    )
}
