import {useAuth} from "./ProvideAuth";

/**
 *  Authorization component, defines a set of user roles required to render its children
 *  @author dame.gjorgjievski
 */
const Authorization = ({roles, children}) => {
    const auth = useAuth()
    const rolesMatch = auth.user ? (roles || []).some((r) => auth.user.roles.includes(r))  : false
    console.log("authorization", roles, rolesMatch, children)
    return Boolean(rolesMatch) && children;
}

export default Authorization