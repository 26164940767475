import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { theme } from '../styles/Theme';

/*
 * Facebook icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function FacebookIcon({ fontsize }) {
    return (
        <SvgIcon viewBox="0 0 11.783 22" fontSize={fontsize}>
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main} />
                    <stop offset="1" stopColor={theme.palette.primary.var3} />
                </linearGradient>
            </defs>
            <path id="Path_931" data-name="Path 931" d="M11.994-6.875l.611-3.981H8.785V-13.44a1.991,1.991,0,0,1,2.245-2.151h1.737v-3.39a21.181,21.181,0,0,0-3.083-.269c-3.146,0-5.2,1.907-5.2,5.359v3.034H.984v3.981h3.5V2.75h4.3V-6.875Z"
                transform="translate(-0.984 19.25)" fill={theme.palette.primary.var3} />
        </SvgIcon>
    )
}
