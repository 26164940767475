import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Sessions menu icon
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function SessionsIcon() {
    return (
        <SvgIcon viewBox="0 0 32 32.003">
            <path id="Path_140" data-name="Path 140"
                  d="M32.484,22.675h-.516V6.159a3.1,3.1,0,0,0-3.1-3.1H19.529a2.581,2.581,0,0,0-5.058,0H5.129a3.1,3.1,0,0,0-3.1,3.1V22.675H1.516A.516.516,0,0,0,1,23.191v2.065a2.584,2.584,0,0,0,2.581,2.581h13.69A4.612,4.612,0,0,0,17,29.385v3.1a.516.516,0,0,0,.516.516H29.9a.516.516,0,0,0,.516-.516v-3.1a4.612,4.612,0,0,0-.271-1.548h.271A2.584,2.584,0,0,0,33,25.256V23.191A.516.516,0,0,0,32.484,22.675ZM30.935,6.159V22.675H27.7a4.1,4.1,0,0,0,.136-1.032h1.548a.516.516,0,0,0,.516-.516V5.643a.516.516,0,0,0-.516-.516H19.051a2.557,2.557,0,0,0,.478-1.032h9.342A2.065,2.065,0,0,1,30.935,6.159ZM23.71,24.74a3.1,3.1,0,0,1-3.1-3.1V20.61a3.1,3.1,0,0,1,6.194,0v1.032A3.1,3.1,0,0,1,23.71,24.74ZM19.581,20.61H18.548V13.9H28.871v6.71H27.839a4.129,4.129,0,0,0-2.068-3.573c0-.013,0-.027,0-.04a2.065,2.065,0,1,0-4.129,0c0,.013,0,.027,0,.04a4.129,4.129,0,0,0-2.068,3.573ZM17.516,6.159V20.61H5.129V6.159Zm11.355,6.71H27.276a3.1,3.1,0,0,0-1.93-2.368,2.044,2.044,0,0,0,.428-1.245,2.065,2.065,0,0,0-4.129,0,2.044,2.044,0,0,0,.428,1.245,3.1,3.1,0,0,0-1.93,2.368H18.548V6.159H28.871ZM23.71,10.288a1.032,1.032,0,1,1,1.032-1.032A1.032,1.032,0,0,1,23.71,10.288Zm-.516,1.032h1.032a2.065,2.065,0,0,1,1.991,1.548H21.2A2.065,2.065,0,0,1,23.194,11.32ZM24.661,16.6a3.988,3.988,0,0,0-1.9,0,1.032,1.032,0,0,1,1.9,0ZM17,2.03a1.548,1.548,0,1,1-1.548,1.548A1.548,1.548,0,0,1,17,2.03ZM3.065,6.159A2.065,2.065,0,0,1,5.129,4.094h9.342a2.557,2.557,0,0,0,.477,1.032H4.613a.516.516,0,0,0-.516.516V21.127a.516.516,0,0,0,.516.516H19.581a4.1,4.1,0,0,0,.136,1.032H3.065ZM3.581,26.8a1.548,1.548,0,0,1-1.548-1.548V23.707H20.139a4.156,4.156,0,0,0,.9,1.077,4.649,4.649,0,0,0-3.251,2.02Zm25.806,5.161H27.839V29.9H26.806v2.065H20.613V29.9H19.581v2.065H18.032V29.385a3.617,3.617,0,0,1,3.613-3.613h4.129a3.617,3.617,0,0,1,3.613,3.613Zm2.581-6.71A1.548,1.548,0,0,1,30.419,26.8h-.786a4.649,4.649,0,0,0-3.252-2.02,4.156,4.156,0,0,0,.9-1.077h4.687Z"
                  transform="translate(-1 -0.995)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_141" data-name="Path 141"
                  d="M11.516,25.387h9.29a.516.516,0,0,0,.516-.516V22.806A3.618,3.618,0,0,0,18.4,19.262a3.082,3.082,0,0,0,.857-2.133V16.1a3.1,3.1,0,1,0-6.194,0v1.032a3.082,3.082,0,0,0,.857,2.133A3.618,3.618,0,0,0,11,22.806v2.065A.516.516,0,0,0,11.516,25.387Zm5.677-5.161a1.032,1.032,0,0,1-2.065,0ZM14.1,16.1a2.065,2.065,0,1,1,4.129,0v1.032a2.065,2.065,0,0,1-4.129,0Zm-2.065,6.71a2.585,2.585,0,0,1,2.07-2.529,2.06,2.06,0,0,0,4.119,0,2.585,2.585,0,0,1,2.07,2.529v1.548H19.258V22.29H18.226v2.065H14.1V22.29H13.065v2.065H12.032Z"
                  transform="translate(-5.839 -6.804)" fill={theme.palette.quaternary.var5}/>
        </SvgIcon>
    );
}
