import {createMuiTheme} from "@material-ui/core";
import ProximaNovaFont from "../assets/ProximaNovaFont";

export const theme = createMuiTheme({
    palette: {
        common: {
            black: '#000',
            white: '#fff'
        },
        primary: {
            main: '#121212',
            var1: '#002d69',
            var2: '#85CFE8', // Boxes background color
            var3: '#3a3b3b'
        },
        secondary: {
            main: '#002d69',
            var1: '#FFA882',
            var2: '#F7F6FD',
            var3: '#F0EEFA'
        },
        tertiary: {
            var1: '#E7E4F5',
            var2: '#121212', // Titles and labels text color
            var3: '#121212', // Text color for Lobby herosection
        },
        quaternary: {
            var1: '#002d69', // Buttons background color
            var2: '#002d69', // Buttons hover background color
            var3: '#ffffff', // Buttons text color
            var4: '#ffffff', // Buttons hover text color
            var5: '#002d69'  //  Menu icons color
        },
        passed: "#FFEFE7",
        golden: "#F9C13F",
        silver: '#EEE9E7',
        bronze: '#CD7F32',
        blue: '#021691',
        black: '#000000',
        background: {
            shadow: "#A6A6A6",
            dark: "#938FA5"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1450,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'ProximaRegular, Arial',
        useNextVariants: true,
        fontWeightRegular: 700
    },
    overrides: {
        MuiInputBase: {
            height: 300,
            overflow: 'hidden'
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [...ProximaNovaFont],
                body: {
                    overflow: 'hidden'
                }
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "1em",
                color: "white",
                backgroundColor: "#d39c2e",
                fontWeight: 'bold',
                border: '1px solid white'
            }
        },
        MuiButton: {
            sizeLarge: {
                height: 40,
                fontSize: '12px',
                ['@media (min-width:600px)']: {
                    fontSize: '14px'
                }
            }
        }
    }
})

// Typography variants
theme.typography.h1 = {
    fontFamily: 'ProximaExtraBold',
    fontSize: '1.25rem', // 50px Extra Bold
    fontWeight: 800,
    [theme.breakpoints.up('sm')]: {
        fontSize: '2.125rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '3.125rem',
    },
}
theme.typography.h2 = {
    fontFamily: 'ProximaLight',
    fontSize: '1.25rem', // 50px Light
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
        fontSize: '2.125rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '3.125rem',
    },
}

theme.typography.h3 = {
    fontFamily: 'ProximaExtraBold',
    fontSize: '1.125rem', //32px Extra Bold
    fontWeight: 800,
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '2rem', //32px Extra Bold
    },
}

theme.typography.h4 = {
    fontFamily: 'ProximaExtraBold',
    fontSize: '1.625rem', //26px Extra Bold
    fontWeight: 800
}

theme.typography.h5 = {
    fontFamily: 'ProximaBold',
    fontSize: '1rem', //24px Bold
    fontWeight: 700,
    [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
    },
}

theme.typography.h6 = {
    fontFamily: 'ProximaExtraBold',
    fontSize: '0.875rem', //20px Extra Bold
    fontWeight: 800,
    [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
    },
}

theme.typography.subtitle1 = {
    fontFamily: 'ProximaLight',
    fontSize: '0.875rem', //20px Light
    fontWeight: 100,
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem', //20px Light
    },
}

theme.typography.subtitle2 = {
    fontFamily: 'ProximaLight',
    fontSize: '1rem', //16px Light
    fontWeight: 700
}

theme.typography.body1 = {
    fontFamily: 'ProximaRegular',
    fontSize: '0.875rem', //14px Regular
    fontWeight: 400,
    [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',//16px Regular
    },
}
theme.typography.body2 = {
    fontFamily: 'ProximaRegular',
    // fontFamily: 'ProximaLight',
    fontSize: '0.75rem', //12px Regular
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem' //14px Regular
    },
}
theme.typography.button = {
    fontFamily: 'ProximaBold',
    fontSize: '0.75rem', //12px Bold
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem' //14px Bold
    },
}

const merge = (target, source) => {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object) Object.assign(source[key], merge(target[key], source[key]))
    }
    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
}
/**********************************
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * do not touch or format customOverrideMUITheme
 * variable. It is substituted during
 * build process
 *********************************/
const customOverrideMUITheme = { "palette": { "primary": { "main": "#4b8c8e", "var1": "#4b8c8e", "var2": "#b8c3c4", "var3": "#63b1bc" }, "secondary": { "main": "#ff449f", "var1": "#4b8c8e", "var2": "#f5f7f7" }, "tertiary": { "var1": "#dfe6e6", "var2": "#2d293e", "var3": "#ff449f" }, "quaternary": { "var1": "#ff449f", "var5": "#ff449f" } } }
/**********************************
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *********************************/

merge(theme, customOverrideMUITheme)


