import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Lobby menu icon
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function LobbyIcon() {
    return (
        <SvgIcon viewBox="0 0 33.75 32">
            <path id="Path_155" data-name="Path 155"
                  d="M100.149,94.638h-.681V79.66a.683.683,0,0,0-.681-.681h-3.4V72.851a.688.688,0,0,0-.391-.621l-8.46-3.906V64.681A.683.683,0,0,0,85.851,64H72.234a.683.683,0,0,0-.681.681V94.638H70.481a.681.681,0,0,0,0,1.362h29.668a.681.681,0,0,0,0-1.362Zm-14.979,0H72.915V65.362H85.17ZM86.532,69.83l7.489,3.455v5.694H86.532Zm8.851,24.808H89.255V87.83h6.128Zm2.723,0H96.745V87.149a.683.683,0,0,0-.681-.681H88.574a.683.683,0,0,0-.681.681v7.489H86.532V80.34H98.106Z"
                  transform="translate(-69.8 -64)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_156" data-name="Path 156"
                  d="M443.762,424h-.681a.681.681,0,0,0,0,1.362h.681a.681.681,0,0,0,0-1.362Z"
                  transform="translate(-410.689 -393.362)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_157" data-name="Path 157"
                  d="M137.889,96h-6.809a.683.683,0,0,0-.681.681v9.532a.683.683,0,0,0,.681.681h6.809a.683.683,0,0,0,.681-.681V96.681A.683.683,0,0,0,137.889,96Zm-6.128,9.532v-8.17H133.8v8.17Zm5.447,0h-2.043v-8.17h2.043Z"
                  transform="translate(-125.243 -93.277)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_158" data-name="Path 158"
                  d="M137.889,240h-6.809a.683.683,0,0,0-.681.681v9.532a.683.683,0,0,0,.681.681h6.809a.683.683,0,0,0,.681-.681v-9.532A.683.683,0,0,0,137.889,240Zm-6.128,9.532v-8.17H133.8v8.17Zm5.447,0h-2.043v-8.17h2.043Z"
                  transform="translate(-125.243 -225.021)" fill={theme.palette.quaternary.var5}/>
            <path id="Path_159" data-name="Path 159"
                  d="M290.57,272h-7.489a.683.683,0,0,0-.681.681V275.4a.683.683,0,0,0,.681.681h7.489a.683.683,0,0,0,.681-.681v-2.723A.683.683,0,0,0,290.57,272Zm-.681,2.723h-6.128v-1.362h6.128Z"
                  transform="translate(-264.306 -254.298)" fill={theme.palette.quaternary.var5}/>
        </SvgIcon>
    );
}