import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {Box, createStyles, Grid, makeStyles, Paper, TextField, Typography, useTheme} from '@material-ui/core';
import ProgressDialog from "../components/ProgressDialog";
import EventService from "../services/EventService";
import UserCard from "../components/UserCard";
import {ArrowForward} from "@material-ui/icons";
import {Pagination} from "@material-ui/lab";
import {useAuth} from "../components/ProvideAuth";
import {Visibility} from "../data/Constants";
import {useAnalytics} from "../data/Analytics";
import {useTranslation} from "react-i18next";
import useEventContext from "../data/EventContext";
import {useDispatch} from "react-redux";
import UserService from "../services/UserService";

const styles = makeStyles((theme) => createStyles({
    paperAttendees: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'block'
    },
    pageTitle: {
        color: theme.palette.tertiary.var2
    },
    usersGrid: {
        marginTop: theme.spacing(3),
        flexGrow: 1
    },
    filtersBox: {
        backgroundColor: theme.palette.primary.var2,
        boxShadow: theme.spacing(0, 0, 1 / 2, 1 / 2) + theme.palette.secondary.var3,
        width: '100%',
        minHeight: 200,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginLeft: '15%',
            width: '70%',
        }
    },
    searchInput: {
        marginBottom: theme.spacing(3),
        '& .MuiOutlinedInput-input': {
            padding: theme.spacing(1, 2)
        }
    },
    tag: {
        display: 'inline-flex',
        margin: theme.spacing(1, 2, 1, 0),
        cursor: 'pointer',
        '& .MuiSvgIcon-root': {
            width: '0.7em',
            height: '0.7em',
            color: theme.palette.secondary.main,
            marginRight: theme.spacing(2)
        }
    },
    tags: {
        marginTop: theme.spacing(1),
        width: '100%',
        maxHeight: 462,
        overflow: "auto"
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4, 0),
        '& ul': {
            "& .MuiPaginationItem-root": {
                color: theme.palette.primary.main,
                fontWeight: 700
            },
            '& .Mui-selected': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.var1,
                '&:hover': {
                    backgroundColor: theme.palette.primary.var1,
                    opacity: '50%'
                }
            }
        }
    }
}))

/**
 * Event attendees page view
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function EventAttendeesView() {

    const {t} = useTranslation()
    const PAGE_SIZE = 6
    const DEFAULT_PAGE = 1
    const classes = styles()
    const auth = useAuth()
    const theme = useTheme()
    const analytics = useAnalytics()
    const dispatch = useDispatch()
    const {id} = useParams()
    const {event, loadEvent} = useEventContext()
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        tags: [t('client.event.labels.all')],
        users: [],
        page: DEFAULT_PAGE,
        keyword: '',
        tag: t('client.event.labels.all'),
        count: 1
    })

    useEffect(() => {
        if (!event) setLoading(true)
        loadEvent(parseInt(id)).then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (event == null) return
        if (event.id !== parseInt(id)) return // skip for non current event
        // extract available tags
        let ts = [...state.tags]
        let us = users()
        if (state.tags.length < 2) {
            us.map((u, i) => {
                ts = [...ts, ...u.tags]
                return u
            })
        }
        setState({...state, users: [...us], tags: ts, count: countPages(us.length)})
        console.log("event attendees mount", event)
    }, [event])

    useLayoutEffect(() => () => {
        console.log("event attendees unmount")
    }, [])

    const paginate = (array, size, page) => array.slice((page - 1) * size, page * size)
    const countPages = (total) => total > PAGE_SIZE ? Math.ceil(total / PAGE_SIZE) : 1
    const users = () => {
        let users = (event.groups || []).reduce((acc, curVal) => acc.concat(curVal.users), [])
        return users.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    }

    const onTagSelect = (e) => {
        e.preventDefault()
        let tag = e.target.innerHTML
        let us = users()
        if (tag === t('client.event.labels.all')) {
            setState({...state, users: [...us], tag: null, page: DEFAULT_PAGE, count: countPages(us.length)})
            return
        }
        console.log("tag click ", tag)
        let usersFilteredByTags = us.filter(u => (u.tags || []).indexOf(tag) > -1)
        setState({
            ...state, tag: tag, page: DEFAULT_PAGE, keyword: '', count: countPages(usersFilteredByTags.length),
            users: usersFilteredByTags
        })
    }

    const onKeywordChange = (e) => {
        e.preventDefault()
        let value = (e.target.value || '').trim().toLowerCase()
        console.log("keyword ", value)
        if (value.length === 0) {
            const us = users()
            setState({...state, users: [...us], keyword: value, count: countPages(us.length)})
            return
        }
        let us = users().filter(u =>
            u.name.toLowerCase().indexOf(value) > -1 || u.surname.toLowerCase().indexOf(value) > -1
        )
        setState({
            ...state, keyword: value, users: [...us], tag: null, page: DEFAULT_PAGE,
            count: countPages(us.length)
        })
    }

    const onPageChange = (page) => {
        console.log("page", page)
        setState({...state, page: page})
    }

    const onUserSelect = (user, event) => {
        if (user.visibility === Visibility.VISIBLE) {
            UserService.viewProfile(dispatch, analytics, user)
        }
    }

    return (
        <main>
            <ProgressDialog open={loading}/>
            {event && EventService.hasAccess(auth.user, event) && <>
                <Paper square={true} elevation={0} className={classes.paperAttendees}>
                    <Typography variant="h3" className={classes.pageTitle}>{t('client.event.labels.attendees')}</Typography>
                    <Grid container className={classes.usersGrid} spacing={0}>
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={theme.spacing(1) / 2}>
                                {state.users &&
                                paginate(state.users.filter(u => u.visibility !== Visibility.HIDDEN).map((user, i) =>
                                    <Grid item xs={12} md={12} lg={6} key={i}>
                                        <UserCard event={event} user={user} onSelect={onUserSelect} maskdata={user.visibility !== Visibility.VISIBLE}/>
                                    </Grid>
                                ), PAGE_SIZE, state.page)}
                            </Grid>
                            <Pagination size="small" count={state.count} color="primary"
                                        showFirstButton showLastButton page={state.page} className={classes.pagination}
                                        defaultPage={DEFAULT_PAGE} onChange={(e, page) => onPageChange(page)}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box className={classes.filtersBox} flexDirection="column">
                                <Typography variant="h6"
                                            style={{marginBottom: theme.spacing(2)}}>{t('client.event.labels.filters')}</Typography>
                                <TextField variant="outlined" margin="none" onChange={onKeywordChange} fullWidth
                                           placeholder={t('client.event.actions.search-by-name')} className={classes.searchInput}/>
                                <Typography variant="button">{t('client.event.labels.tags')}</Typography>
                                <div className={classes.tags}>
                                    {state.tags
                                        .filter((v, i, a) => a.findIndex(t => (t === v)) === i) // filter duplicates
                                        .map((tag, i) =>
                                            <div className={classes.tag} key={'tag' + i}>
                                                <ArrowForward width={10} height={10} style={{verticalAlign: "middle"}}/>
                                                <Typography variant="body1" component="span"
                                                            onClick={onTagSelect}>{tag}</Typography>
                                            </div>
                                        )}
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </>}
        </main>
    )
}
