import React, { createContext, useContext, useEffect, useState } from "react";
import AuthService from "../services/AuthService"
import GAEvent from "../models/GAEvent";
import { useAnalytics } from "../data/Analytics";
import { useWebSocket } from "../data/WebSocketManager";
import { UserData } from "react-oidc";
import { useHistory } from "react-router-dom";
import useEventContext from "../data/EventContext";
import DateUtil from '../utils/DateUtil';

/**
 * Authentication provider components, wraps the application to provide authentication context to child components
 * @author dame.gjorgjievski
 */

const authContext = createContext();

export default function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {
    return useContext(authContext);
}

export function useProvideAuth() {

    const oidcContext = useContext(UserData);

    let [user, setUser] = useState(AuthService.user());
    const history = useHistory()
    const { event } = useEventContext()
    const analytics = useAnalytics()
    const ws = useWebSocket()

    useEffect(() => {
        /*if (!user && oidcContext.user) {
            oidcContext.signOut()
        }
        axios.interceptors.request.use(function (config) {
            let user = AuthService.user()
            if (user) config.headers.Authorization = "Bearer " + user.token;
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            response.headers['Access-Control-Allow-Origin'] = "*"
            response.headers['Access-Control-Allow-Methods'] = 'GET, PUT, POST, DELETE, PATCH, OPTIONS'
            if (user && response.status === 401) {
                signOut()
                if (history)
                    if (event) history.push(Path.EVENT_INTRO.replace(":id", event.id))
                    else history.push(Path.LOGIN)
            }
            return response;
        }, function (error) {
            if (user && error.response && error.response.status === 401) {
                signOut()
                if (history)
                    if (event) history.push(Path.EVENT_INTRO.replace(":id", event.id))
                    else history.push(Path.LOGIN)
            }
            return Promise.reject(error);
        });
        */


    }, [])

    const accessCode = (username, password) => {
        return AuthService.accessCode(username, password).then(response => {
            console.log("access code result", response)
            return response
        })
    }

    const signIn = (username, password, code) => {
        return AuthService.authenticate(username, password, code).then(response => {
            AuthService.user(response.data)
            setUser(response.data)
            analytics.set({ userId: response.data.email })
            analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.LOGIN,
                `name: ${response.data.name} surname: ${response.data.surname} date: ${DateUtil.getDateTimeNow()}`, response.data.id))
            console.log("login result", response)
            return response
        })
    }

    const signUp = (email, name, surname, password, phone) => {
        return AuthService.register(email, name, surname, password, phone).then(response => {
            console.log("register result", response)
            analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.REGISTER,
                response.data.name + ' ' + response.data.surname, response.data.id))
            return response
        })
    }

    const signOut = () => {
        if (ws) ws.disconnect()
        analytics.event(new GAEvent(GAEvent.Category.AUTH, GAEvent.Action.LOGOUT,
            `name: ${user.name} surname: ${user.surname} date: ${DateUtil.getDateTimeNow()}`, user.id))
        analytics.set({ userId: null })
        setUser(null)
        AuthService.logout()
        //oidcContext.signOut()
    }

    const hasRole = (...roles) => {
        let has = false
        if (!user) return has
        roles.forEach(role => {
            if (!has) has = (user.roles || []).indexOf(role) > -1
        })
        return has
    }

    return {
        user,
        signIn: signIn,
        signOut: signOut,
        signUp: signUp,
        hasRole: hasRole,
        accessCode: accessCode,
    }
}