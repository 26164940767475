import {Container, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import EventService from "../services/EventService";
import {useDispatch, useSelector} from "react-redux";
import {setEvent, setEvents} from "../data/Store";
import EventCard from "../components/EventCard";
import {useHistory} from "react-router-dom";
import {Path} from "../data/Constants";
import ProgressDialog from "../components/ProgressDialog";
import useEventContext from "../data/EventContext";

/**
 * Events overview page, lists all events available for view by current user
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function EventsOverView() {

    const dispatch = useDispatch()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const events = useSelector(state => state.events)
    const {loadEvents} = useEventContext()

    useEffect(() => {
        if (!events || events.length === 0) {
            setLoading(true)
            loadEvents().then(response => {
                if(response.data && response.data.length === 1)
                    history.push(Path.EVENT_LOBBY.replace(":id", response.data[0].id))
                setLoading(false)
            }).catch(_ => setLoading(false))
        }
    }, [])

    const onClick = (event, e) => {
        e.preventDefault()
        dispatch(setEvent(event))
        history.push(Path.EVENT_LOBBY.replace(":id", event.id))
    }

    return <Container maxWidth="lg">
        <ProgressDialog open={loading}/>
        {events &&
        <Grid container spacing={4}>
            {events.map((event, i) =>
                <Grid item xs={12} sm={6} md={3} key={i}>
                    <EventCard event={event} onClick={onClick}/>
                </Grid>
            )}
        </Grid>}
    </Container>
}
