import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Box, Divider, Grid} from '@material-ui/core';
import PropTypes from "prop-types";
import FileUpload from "./FileUpload";
import DateUtil from "../utils/DateUtil";
import {DEFAULT_SESSION_IMAGE} from "../data/Configuration";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import FileService from "../services/FileService";
import {useTranslation} from "react-i18next";
import useLanguage from "../data/Language";

const styles = makeStyles((theme) => createStyles({
    description: {
        height: 56,
        overflow: 'hidden'
    },
    title: {
        height: 36,
        overflow: 'hidden',
        fontWeight: 'bold'
    },
    dates: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold'
    },
    dateBox: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        '& h6': {
            color: "white"
        }
    },
}))

/**
 * Event card component used for display of event teaser information
 * @returns {JSX.Element}
 * @author dame.gjorgjievski
 */
export default function EventCard(props) {

    const classes = styles()
    const ts = DateUtil.fromUtc(new Date(props.event.timeStart))
    const te = DateUtil.fromUtc(new Date(props.event.timeEnd))
    const {t} = useTranslation()
    const {value} = useLanguage()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container item spacing={3}>
                    <Grid item xs={3}>
                        <Box className={classes.dateBox} borderRadius={10}>
                            <Typography variant="h6">{DateUtil.getDate(ts)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h6">
                            {DateUtil.getWeekDay(ts)}
                        </Typography>
                        <Typography variant="body1">
                            {DateUtil.getMonth(ts)} {DateUtil.getDate(ts)}, {ts.getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Divider/>
            <Grid item xs={12}>
                <Card style={{maxWidth: 345}}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image={props.event.media ? FileService.url(props.event.media.uri) : DEFAULT_SESSION_IMAGE}
                            title="Event Image"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="body2" component="h6" className={classes.dates}>
                                {DateUtil.getHours(ts)} : {DateUtil.getMinutes(ts)} -
                                {DateUtil.getMonth(te)} {DateUtil.getDate(te)}, {te.getFullYear()}&nbsp; {DateUtil.getHours(te)} : {DateUtil.getMinutes(te)}
                            </Typography>
                            <Typography gutterBottom variant="body2" component="h6" className={classes.title}>
                                {value(props.event.name)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p"
                                        className={classes.description}
                                        dangerouslySetInnerHTML={{__html: value(props.event.description,null,100)}}>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary" onClick={e => props.onClick(props.event, e)}>
                            {t('client.event.actions.view')}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
}

EventCard.propTypes = {
    event: PropTypes.object,
    onClick: PropTypes.func
};

FileUpload.defaultProps = {
    event: {},
    onClick: function (e) {
    }
}
