import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom"
import {Grid, Paper, Tab, Tabs, Typography} from '@material-ui/core'
import ProgressDialog from "../components/ProgressDialog"
import {createStyles, makeStyles} from "@material-ui/core/styles";
import SponsorBox from "../components/SponsorBox";
import {Path, SponsorType} from "../data/Constants";
import {Pagination} from "@material-ui/lab";
import {useTranslation} from "react-i18next";
import useEventContext from "../data/EventContext";
import EventService from "../services/EventService";
import {useAuth} from "../components/ProvideAuth";
import WindowUtil from "../utils/WindowUtil";

export const styles = makeStyles((theme) => createStyles({
    paper: {
        background: 'none'
    },
    subRoot: {
        flexGrow: 1,
        '& .tabs-wrap': {
            display: 'flex',
            alignItems: 'center',
            '& .show': {
                fontWeight: 800,
                paddingRight: theme.spacing(2),
                color: theme.palette.tertiary.var2
            }
        },
        '& .MuiTabPanel-root': {
            padding: 0
        },
        '& .MuiTabs-root': {
            minHeight: 'initial',
            padding: theme.spacing(3, 0)
        }
    },
    titlePage: {
        color: theme.palette.tertiary.var2
    },
    tabList: {
        '& .MuiTab-root': {
            minWidth: 'auto',
            minHeight: 'initial',
            fontSize: 20,
            margin: theme.spacing(0, 2),
            padding: 0,
            color: theme.palette.tertiary.var2
        },
        '& .Mui-selected': {
            color: theme.palette.secondary.main,
            '& .MuiTab-wrapper': {
                '& span': {
                    fontWeight: 800
                }
            }
        }
    },
    tabPanel: {
        padding: 0
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2, 0),
        '& ul': {
            "& .MuiPaginationItem-root": {
                color: theme.palette.primary.main,
                fontWeight: 700
            },
            '& .Mui-selected': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.var1,
                '&:hover': {
                    backgroundColor: theme.palette.primary.var1,
                    opacity: '50%'
                }
            }
        }
    }
}))

/**
 * Displays the sponsors for an event
 * @returns {JSX.Element}
 * @author petar.todorovski
 */
export default function EventSponsorsView() {

    const {t} = useTranslation()
    const PAGE_SIZE = 8
    const DEFAULT_PAGE = 1
    const classes = styles()
    const history = useHistory()
    const auth = useAuth()
    const {event, loadEvent} = useEventContext()
    const [state, setState] = useState({
        sponsors: [],
        index: "",
        count: 0,
        page: DEFAULT_PAGE
    })
    const [loading, setLoading] = useState(true)
    const {id} = useParams()

    useEffect(() => {
        if (!event) setLoading(true)
        loadEvent(parseInt(id)).then(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (!event) return
        if (event.id !== parseInt(id)) return // skip for non current event
        let result = sponsors()
        setState({...state, sponsors: result, count: countPages(result.length)})
    }, [event])

    const onTypeChange = (e, value) => {
        let result = value && value.length > 0 ? sponsors().filter(s => s.type === value) : [...sponsors()]
        setState({...state, index: value, count: countPages(result.length), page: DEFAULT_PAGE, sponsors: result})
    }

    const onPageChange = (page) => {
        setState({...state, page: page})
    }

    const onSelect = (sponsor, event) => {
        event.preventDefault()
        console.log("sponsor select", sponsor)
        if (sponsor.features && sponsor.features.enablePage)
            history.push(Path.EVENT_SPONSOR.replace(":id", id).replace(":sid", sponsor.id))
        else WindowUtil.open(sponsor.url)
    }

    const sponsors = () => {
        let sessionSponsors = event.sessions.reduce((acc, curVal) => acc.concat(curVal.sponsors), [])
        return [...event.sponsors, ...sessionSponsors].filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
    }
    const paginate = (array, size, page) => array.slice((page - 1) * size, page * size)
    const countPages = (total) => total > PAGE_SIZE ? Math.ceil(total / PAGE_SIZE) : 1
    const styleProp = {style: {display: 'none'}}

    return (
        <main>
            <ProgressDialog open={loading}/>
            {event && EventService.hasAccess(auth.user, event) && <>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography className={classes.titlePage} variant="h3">{t('client.event.labels.sponsors')}</Typography>
                    <div className={classes.subRoot}>
                        <div className="tabs-wrap">
                            <Typography className="show" variant="body1"
                                        component="span">{t('client.event.labels.show')}</Typography>
                            <Tabs className={classes.tabList}
                                  value={state.index}
                                  onChange={onTypeChange}
                                  TabIndicatorProps={styleProp}>
                                <Tab TouchRippleProps={styleProp}
                                     label={<Typography variant="body1"
                                                        component="span">{t('client.event.labels.all')}</Typography>}
                                     value={""}/>
                                {SponsorType.ALL.map(type =>
                                    <Tab TouchRippleProps={styleProp}
                                         label={<Typography variant="body1"
                                                            component="span">{t('data.sponsor-type.' + type.toLowerCase())}</Typography>}
                                         value={type}/>
                                )}
                            </Tabs>
                        </div>
                        <Grid container spacing={3}>
                            {state.sponsors && paginate(state.sponsors.map((sponsor, i) =>
                                <Grid item xs={12} sm={6} md={3} key={"sponsor" + i}>
                                    <SponsorBox sponsor={sponsor} onSelect={onSelect}/>
                                </Grid>
                            ), PAGE_SIZE, state.page)}
                        </Grid>
                    </div>
                    <Pagination
                        size="small"
                        count={state.count}
                        color="primary"
                        showFirstButton
                        showLastButton
                        page={state.page}
                        className={classes.pagination}
                        defaultPage={DEFAULT_PAGE} onChange={(e, page) => onPageChange(page)}
                    />
                </Paper>
            </>}
        </main>
    )
}
