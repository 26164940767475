import {Route} from "react-router-dom";
import {useAuth} from "./ProvideAuth"

/**
 * Wrapper/decorator for <Route> that redirects to login page if user is not authenticated
 *
 * @author dame.gjorgjievski
 */
export default function ProtectedRoute({children, ...rest}) {

    let auth = useAuth();

    return (
        <Route
            {...rest}
            render={({location}) => children}
            /*    auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: Path.EVENTS,
                            state: {from: location}
                        }}
                    />
                )
            }*/
        />
    );
}