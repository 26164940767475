import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import FileService from "../services/FileService";
import FileUtil from "../utils/FileUtil";
import GAEvent from "../models/GAEvent";
import {useAnalytics} from "../data/Analytics";
/**
 * Component that displays a list of files
 * @param props component properties
 * @author dame.gjorgjievski
 */
export default function FileList(props) {

    const classes = makeStyles((theme) => createStyles({
        box: {
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.primary.var2,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            borderRadius: 10,
            overflow: 'hidden'
        },
        label: {
            display: 'inline-block',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            marginLeft: theme.spacing(2)
        },
        link: {
            textDecoration: 'none',
            color: 'black'
        }
    }))()
    
    const analytics = useAnalytics()
    
    const onFileSelect = (file, event) => {
        if(file) analytics.event(new GAEvent(GAEvent.Category.NAV, GAEvent.Action.FILE, file.name, file.id))
    }

    return <Box display="flex" flexDirection="column">
        {props.files.map((file, i) =>
            <Box className={classes.box} key={i}>
                {/* <InsertDriveFile className={classes.icon}>{file.ext}</InsertDriveFile> */}
                {FileUtil.getIcon(file)}
                <div className={classes.label} title={file.name} style={{maxWidth: '80%', verticalAlign: 'middle'}}>
                    <a className={classes.link} href={FileService.url(file.uri)} target='_blank' onClick={e => onFileSelect(file, e)}
                       rel="noreferrer">{file.name}</a>
                </div>
            </Box>
        )}
    </Box>
}

FileList.propTypes = {
    files: PropTypes.array
}

FileList.defaultProps = {
    files: []
}