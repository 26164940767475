import React from "react"
import {MenuItem, Select} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import PropTypes from "prop-types";
import {Languages as languages} from "../data/Constants";

/**
 * Edit Language selector component, used to edit the element for a selected language.
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 */
export default function EditInLanguageSelector(props) {

    const {t} = useTranslation()

    const {onChange, language} = props

    return <div className="lang">
        <Select
            labelId="lang-select-label"
            variant="standard"
            id="language"
            name="language"
            autoComplete="language"
            value={language}
            onChange={onChange}>
            {languages.map((lang, index) => {
                if (language.code !== lang.code) {
                    return <MenuItem key={index} value={lang.code}>{lang.code}</MenuItem>
                }
            })}
        </Select>
    </div>
}

EditInLanguageSelector.propTypes = {
    onChange: PropTypes.func,
    language: PropTypes.string
};
