import ProximaNovaRegular from '../assets/proxima-nova-regular.woff2';
import ProximaNovaBold from '../assets/proxima-nova-bold.woff2';
import ProximaNovaExtraBold from '../assets/proxima-nova-extra-bold.woff2';
import ProximaNovaThin from'../assets/proxima-nova-thin.woff2'
import ProximaNovaLight from'../assets/proxima-nova-light.woff2'

/**
 * Style definitions for proxima nova web font
 * @type {{fontFamily: string, src: string, fontDisplay: string, unicodeRange: string, fontStyle: string, fontWeight: number}}
 */
const proximaNovaThin = {
    fontFamily: 'ProximaNovaThin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 100,
    src: `
      local('ProximaNovaThin'),
      local('proxima-nova-thin'),
      url(${ProximaNovaThin}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const proximaNovaLight = {
    fontFamily: 'ProximaLight',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
      local('ProximaNovaLight'),
      local('proxima-nova-light'),
      url(${ProximaNovaLight}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


const proximaNovaRegular = {
    fontFamily: 'ProximaRegular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('ProximaNovaRegular'),
      local('proxima-nova-regular'),
      url(${ProximaNovaRegular}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const proximaNovaBold = {
    fontFamily: 'ProximaBold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `
      local('ProximaNovaBold'),
      local('proxima-nova-bold'),
      url(${ProximaNovaBold}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const proximaNovaExtraBold = {
    fontFamily: 'ProximaExtraBold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 800,
    src: `
      local('ProximaNovaExtraBold'),
      local('proxima-nova-extra-bold'),
      url(${ProximaNovaExtraBold}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const ProximaNovaFont = [proximaNovaThin, proximaNovaLight, proximaNovaRegular, proximaNovaBold, proximaNovaExtraBold]
export default ProximaNovaFont