import { Box, CardHeader, Fade, Slide, Tooltip, Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos, Close } from '@material-ui/icons';
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { apiFacade } from "../data/ApiFacade";
import { EventType, MessageType } from "../data/Constants";
import RoomService from "../services/RoomService";
import { theme } from "../styles/Theme";
import ChatMessages from "./ChatMessages";
import NotificationBox from "./NotificationBox";

const styles = makeStyles((theme) => createStyles({
    container: {
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        width: 350,
        maxHeight: 400,
        overflow: 'hidden auto',
        right: 10,
        top: 64,
        border: '1px solid ' + theme.palette.background.shadow,
        scrollbarWidth: 'none',
        borderRadius: 10,
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': {
            width: 0
        },
        "& .MuiBox-root": {
            padding: 0
        },
        "& :scrollbar": {
            display: "none"
        }
    },
    textHeader: {
        margin: '10px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: '1.3rem',
        letterSpacing: '1px'
    },
    iconColor: {
        color: 'white'
    }
}))

/**
 * Notification component, used for display of system notifications sent to the current user
 * @author dame.gjorgjievski
 */
export default function Notifications(props) {

    const classes = styles()
    const [room, setRoom] = useState()
    const roomRef = useRef(room)
    const [open, setOpen] = useState(room ? room.open : false)
    const [count, setCount] = useState(0)
    const countRef = useRef(count)
    const iconRef = useRef(null)
    const notifBoxRef = useRef(null)
    const { t } = useTranslation()
    const [openNotifMessage, setOpenNotifMessage] = useState(null)

    useEffect(() => {
        RoomService.provideNotificationRoom().then(response => {
            setRoom(response.data)
            roomRef.current = response.data
            console.log("loaded notification room", response.data)
        })
        apiFacade.on(EventType.MESSAGE, onMessageEvent)
        document.addEventListener('click', onClickOutside, true);
        return () => {
            apiFacade.off(EventType.MESSAGE, onMessageEvent)
            document.removeEventListener('click', onClickOutside, true);
        }
    }, [])

    const onClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this)
        const iconNode = ReactDOM.findDOMNode(iconRef.current)
        const boxNode = ReactDOM.findDOMNode(notifBoxRef.current)

        if (iconNode.contains(event.target)) return

        if (boxNode !== null) {
            const headerBoxNode = boxNode.childNodes[0]
            const notifBoxNode = boxNode.childNodes[1]

            if (headerBoxNode.contains(event.target)) return
            if (notifBoxNode.contains(event.target)) return
        }

        if (!domNode || !domNode.contains(event.target)) setOpen(false)
    }

    const toggle = open => () => {
        setOpen(open)
        if (room) room.open = open
    }

    const onMessageEvent = (e) => {
        let m = JSON.parse(e.data.message)
        let room = roomRef.current
        if (!room || room.id !== m.roomId) return
        console.log("handling notification event for room", m.roomId)
        countRef.current = countRef.current + 1
        setCount(countRef.current)
    }

    const handleClickOnNotification = (event, crossRef, message) => {
        if (crossRef.current !== null) {
            const cross = ReactDOM.findDOMNode(crossRef.current)
            if (cross.contains(event.target)) return
        }

        RoomService.updateMessageSeenStatus(message.roomId, message.id)

        if (countRef.current !== 0) {
            countRef.current = countRef.current - 1
            setCount(countRef.current)
        }

        setOpenNotifMessage(message)
    }

    const handleDeleteNotification = () => {
        if (countRef.current !== 0) {
            countRef.current = countRef.current - 1
            setCount(countRef.current)
        }
    }

    return (
        <div>
            <Tooltip title={t('client.menu.notifications')} placement="bottom"
                TransitionComponent={Slide} TransitionProps={{ timeout: 0 }}>
                <IconButton onClick={toggle(!open)} ref={iconRef}>
                    <Badge badgeContent={count} style={{ color: 'white' }}>
                        <NotificationsIcon style={{ color: theme.palette.quaternary.var5 }} />
                    </Badge>
                </IconButton>
            </Tooltip>
            {room &&
                <Fade in={open} enter exit>
                    <Box className={classes.container} ref={notifBoxRef}>
                        <CardHeader
                            title={
                                <Typography component="div" className={classes.textHeader}>
                                    {openNotifMessage
                                        ? t('client.menu.new-notification')
                                        : t('client.menu.notifications')}
                                </Typography>
                            }
                            action={
                                <IconButton size="small" onClick={() => setOpen(false)}>
                                    <Close className={classes.iconColor} />
                                </IconButton>
                            }
                            avatar={
                                openNotifMessage
                                    ? <IconButton size="small" onClick={() => setOpenNotifMessage(null)}>
                                        <ArrowBackIos className={classes.iconColor} />
                                    </IconButton>
                                    : null
                            } />
                        {openNotifMessage
                            ? <NotificationBox message={openNotifMessage} isSingle={true} />
                            : <ChatMessages
                                notificationOnClick={handleClickOnNotification}
                                notificationOnDelete={handleDeleteNotification}
                                room={room}
                                type={MessageType.NOTIFICATION}
                                component={NotificationBox}
                                avatars={false} input={false}
                            />
                        }
                    </Box>
                </Fade>}
        </div>
    )
}