import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {createStyles, makeStyles, Paper, Typography} from '@material-ui/core';
import ProgressDialog from "../components/ProgressDialog";
import EventService from "../services/EventService";
import {useAuth} from "../components/ProvideAuth";
import useEventContext from "../data/EventContext";
import {Path} from "../data/Constants";
import dompurify from 'dompurify';
import useLanguage from "../data/Language";
import {setRoom} from "../data/Store";
import {useDispatch} from "react-redux";

const styles = makeStyles((theme) => createStyles({
    paper: {
        backgroundColor: 'transparent',
        width: 'auto',
        display: 'block',
        '& .MuiTab-root': {
            padding: 0,
            marginRight: 80
        },
        '& .Mui-selected': {
            '& .date-box': {
                backgroundColor: theme.palette.secondary.main
            }
        },
    },
    pageTitle: {
        color: theme.palette.tertiary.var2
    },
    description: {
        marginTop: theme.spacing(3),
        color: theme.palette.primary.main,
    },
    data: {
        marginTop: theme.spacing(4),
        '& :first-child': {
            width: '100%',
            maxWidth: theme.spacing(120),
            minHeight: theme.spacing(63)
        }
    },
    paperTabs: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(5),
        marginBottom: theme.spacing(6.25),
        marginTop: theme.spacing(3),
        background: theme.palette.secondary.var3,
        '& .MuiTabs-flexContainer': {
            height: '100%',
        },
    },
    dateTabs: {
        width: '100%',
        height: 100,
    },
}))

/**
 * Page page view
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author: Alexander Schamne <alexander.schamne@gmail.com>
 */
export default function EventPageView() {

    const classes = styles()
    const auth = useAuth()
    const history = useHistory()
    const dispatch = useDispatch()
    const {value} = useLanguage()
    const [loading, setLoading] = useState(false)
    const {event, loadEvent} = useEventContext()
    const [page, setPage] = useState(null)
    const {id, pid} = useParams()

    useEffect(() => {
        if (!event) {
            setLoading(true)
            loadEvent(parseInt(id)).then(() => setLoading(false))
            return
        }
        let page = event.pages.filter(p => p.id === parseInt(pid))[0]
        if (!page) history.push(Path.ROOT)
        if (event.room) dispatch(setRoom(event.room))
        setPage(page)
    }, [event])

    const getHTMLSanitized = () => {
        return {'__html': dompurify.sanitize(page.data, {ADD_TAGS: ["iframe"]})}
    }

    return (
        <main>
            <ProgressDialog open={loading}/>
            {event && EventService.hasAccess(auth.user, event) && page && <>
                <Paper square={true} elevation={0} className={classes.paper}>
                    <Typography variant="h3" className={classes.pageTitle}>{value(page.name)}</Typography>
                    <Typography variant="h6" className={classes.description}>{value(page.description)}</Typography>
                    <div className={classes.data} dangerouslySetInnerHTML={getHTMLSanitized()}/>
                </Paper>
            </>}
        </main>
    )
}
