import {createStyles, makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => createStyles({
    chatMsgWrap: {
        height: '100%',
        '& .chatMsgWrapInner': {
            overflow: 'hidden scroll',
            "-ms-overflow-style": "none"
        },
        '& .chatMsgWrapInner::-webkit-scrollbar': {
            display: 'none'
        },
    },
    notifChat: {
        "& div:first-child": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
        },
        "& div:last-child": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
        }
    },
    inputChat: {
        width: '100%',
        backgroundColor: theme.palette.common.white,
        borderRadius: '10px',
        '& .MuiFilledInput-root': {
            borderRadius: '10px',
            backgroundColor: theme.palette.secondary.var2
        },
        '& .MuiFilledInput-multiline': {
            padding: theme.spacing(2, 3.5, 2, 1),
            maxHeight: 100,
            overflow: 'hidden'
        },
        '& .MuiFilledInput-multiline::-webkit-scrollbar': {
            display:"none"
        },
        '& input': {
            padding: '10px',
            paddingRight: theme.spacing(8),
            height: '30px'
        }
    },
    attendeeMessageWrap: {
        margin: theme.spacing(1, 1, 1, 0),
        '& span': {
            fontSize: '10px',
            color: '#625C7B'
        }
    },
    speakerMessageWrap: {
        margin: theme.spacing(1, 0, 1, 1),
        '& span': {
            fontSize: '10px',
            color: '#625C7B'
        }
    },
    attendeeMessage: {
        fontWeight: 'bold',
        // backgroundColor: theme.palette.primary.var3,
        backgroundColor: theme.palette.primary.var1,
        borderRadius: '15px',
        borderTopRightRadius: '0',
        padding: '10px',
        margin: ' 5px 0 0 0',
        color: theme.palette.common.white,
        fontSize: '12px',
        wordBreak: 'break-word'
    },
    speakerMessage: {
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.var2,
        borderRadius: '15px',
        borderTopLeftRadius: '0',
        padding: '10px',
        margin: ' 5px 0 0 0',
        color: theme.palette.tertiary.var2,
        fontSize: '12px',
        wordBreak: 'break-word'
    },
    date: {
        fontWeight: 'bold',
        fontSize: 12
    },
    avatar: {
        backgroundColor: theme.palette.secondary.var1
    },
    attendeeAvatar: {
        // border: `2px solid ${theme.palette.primary.main}`
        border: `2px solid ${theme.palette.primary.var1}`,
        cursor: 'pointer'
    },
    userAvatar: {
        border: `2px solid ${theme.palette.primary.var2}`,
        cursor: 'pointer'
    },
    button: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 9999,
        padding: 4,
        minWidth: 0,
        position: "absolute",
        bottom: 12,
        right: 5,
        zIndex: 9999,
        "& .MuiSvgIcon-root": {
            color: theme.palette.background.default,
            fontSize: theme.spacing(2)
        }
    },
    delete:{
        color:"white",
        backgroundColor:theme.palette.primary.var1,
        borderRadius:"50%",
        fontSize:15,
        marginTop: theme.spacing(1.2),
        "&:hover":{
            cursor:"pointer",
            backgroundColor: theme.palette.secondary.var1
        }
    }
}))
