import React from "react";
import ProvideAuth from "../components/ProvideAuth";
import WebSocketManager from "../data/WebSocketManager";
import ApplicationView from "./ApplicationView";

export default function AuthView() {
    return (
        <ProvideAuth>
            <WebSocketManager>
                <ApplicationView/>
            </WebSocketManager>
        </ProvideAuth>
    )
}
