/**
 * Component specific utility methods
 * @author dame.gjorgjievski
 */
export default class CompUtil {

    /**
     * Builds localization component for material table component
     * @param t translation interface
     * @return material table localization component
     */
    static localizeTable(t: any) {
        return {
            pagination: {
                labelDisplayedRows: t("admin.default.labels.pagination-count"),
                firstTooltip: t("admin.default.actions.page-first"),
                lastTooltip: t("admin.default.actions.page-last"),
                previousTooltip: t("admin.default.actions.page-prev"),
                nextTooltip: t("admin.default.actions.page-next"),
                labelRowsSelect: t("admin.default.labels.rows-selected")
            },
            header: {
                actions: t("admin.default.fields.actions")
            },
            body: {
                emptyDataSourceMessage: t("admin.default.labels.no-records"),
                addTooltip: t("admin.default.actions.add"),
                deleteTooltip: t("admin.default.actions.delete"),
                editTooltip: t("admin.default.actions.edit"),
            },
            toolbar: {
                searchTooltip: t("admin.default.actions.search"),
                searchPlaceholder: t("admin.default.actions.search")
            }
        }
    }

}