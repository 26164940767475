import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import ProtectedRoute from "../components/ProtectedRoute";
import LoginView from "./LoginView";
import EventsListView from "./EventsListView";
import EventEditView from "./EventEditView";
import UsersListView from "./UsersListView";
import ProfileEditView from "./ProfileEditView";
import EventLobbyView from "./EventLobbyView";
import UserEditView from "./UserEditView";
import SessionEditView from "./SessionEditView";
import SponsorsListView from "./SponsorsListView";
import SponsorEditView from "./SponsorEditView";
import ApplicationMenu from "../components/ApplicationMenu";
import { styles } from "../styles/ApplicationViewStyles";
import SessionsListView from "./SessionsListView";
import EventsOverView from "./EventsOverView";
import Conversations from "../components/Conversations";
import EventSessionsView from "./EventSessionsView";
import Authorization from "../components/Authorization";
import { Path, Role } from "../data/Constants";
import { useAuth } from "../components/ProvideAuth";
import ChatBar from "../components/ChatBar";
import { useSelector } from "react-redux";
import EventSponsorsView from "./EventSponsorsView";
import EventAttendeesView from "./EventAttendeesView";
import { useTheme } from "@material-ui/core";
import PollsListView from "./PollsListView";
import PollEditView from "./PollEditView";
import EventSponsorView from "./EventSponsorView";
import DateUtil from "../utils/DateUtil";
import EventSessionView from "./EventSessionView";
import EventRoundTablesView from "./EventRoundTablesView";
import GroupsListView from "./GroupsListView";
import GroupEditView from "./GroupEditView";
import { useTranslation } from "react-i18next";
import EventPageView from "./EventPageView";
import PagesListView from "./PagesListView";
import PageEditView from "./PageEditView";
import FooterLinks from "../components/FooterLinks";
import UserProfileView from "../components/UserProfileView";

/**
 * Main application view component
 * @author dame.gjorgjievski
 */
export default function ApplicationView() {

    const auth = useAuth()
    const location = useLocation()
    const theme = useTheme()
    const classes = styles()
    const room = useSelector(state => state.room)
    const event = useSelector(state => state.event)
    const session = useSelector(state => state.session)
    const navigation = location && location.pathname.indexOf('/intro') === -1
    const { t } = useTranslation()
    const [openChat, setOpenChat] = useState(false)

    return (
        <div className={classes.root}
            style={{ backgroundColor: navigation ? theme.palette.secondary.var2 : theme.palette.common.white }}>
            <CssBaseline />
            {navigation && <ApplicationMenu onOpenChat={() => setOpenChat(!openChat)} />}
            <main className={classes.content}>
                {navigation && <div className={classes.appBarSpacer} />}
                <Container maxWidth={false} className={classes.container}>
                    <Switch>
                        <ProtectedRoute exact path="/">
                            <EventsOverView />
                        </ProtectedRoute>
                        <ProtectedRoute exact path={Path.EVENTS}>
                            <EventsOverView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_LOBBY}>
                            <EventLobbyView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_SESSIONS}>
                            <EventSessionsView title={t('client.event.labels.sessions')} />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_REPLAY}>
                            <EventSessionsView title={t('client.event.labels.replay-sessions')} max={DateUtil.utc()} />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_SPONSORS}>
                            <EventSponsorsView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_SPONSOR}>
                            <EventSponsorView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_ATTENDEES}>
                            <EventAttendeesView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_SESSION}>
                            <EventSessionView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_PAGE}>
                            <EventPageView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.EVENT_ROUND_TABLES}>
                            <EventRoundTablesView />
                        </ProtectedRoute>
                        <ProtectedRoute path={Path.PROFILE}>
                            <ProfileEditView />
                        </ProtectedRoute>
                        <Route path={Path.LOGIN}>
                            <LoginView />
                        </Route>
                        <Authorization roles={[Role.ADMIN, Role.SUPER, Role.SPONSOR]}>
                            {auth.hasRole(Role.SUPER, Role.ADMIN) && <>
                                <ProtectedRoute path={Path.ADMIN_EVENTS}>
                                    <EventsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_EVENT_EDIT}>
                                    <EventEditView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_SESSIONS}>
                                    <SessionsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_SESSION_EDIT}>
                                    <SessionEditView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_SPONSORS}>
                                    <SponsorsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_SPONSOR_EDIT}>
                                    <SponsorEditView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_POLLS}>
                                    <PollsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_POLL_EDIT}>
                                    <PollEditView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_PAGES}>
                                    <PagesListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_PAGE_EDIT}>
                                    <PageEditView />
                                </ProtectedRoute>
                            </>}
                            {auth.hasRole(Role.SUPER) && <>
                                <ProtectedRoute path={Path.ADMIN_GROUPS}>
                                    <GroupsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_GROUP_EDIT}>
                                    <GroupEditView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_USERS}>
                                    <UsersListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.ADMIN_USER_EDIT}>
                                    <UserEditView />
                                </ProtectedRoute>
                            </>}
                            {auth.hasRole(Role.SPONSOR) && <>
                                <ProtectedRoute path={Path.SPONSORS}>
                                    <SponsorsListView />
                                </ProtectedRoute>
                                <ProtectedRoute path={Path.SPONSOR_EDIT}>
                                    <SponsorEditView />
                                </ProtectedRoute>
                            </>}
                        </Authorization>
                    </Switch>
                    <UserProfileView />
                    <FooterLinks event={event} />
                </Container>
            </main>
            {navigation && auth.user && room && room.id && <ChatBar open={openChat} room={room} session={session} />}
            {navigation && auth.user && <Conversations />}
        </div>
    )
}
