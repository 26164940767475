import axios from "axios"
import {API_URL} from "../data/Configuration"

/**
 * File upload and update service
 * @author dame.gjorgjievski
 **/
const FileService = {
    ENDPOINT: API_URL + "files/",
    upload: async function (file, onUploadProgress, id) {
        let formData = new FormData();
        formData.append("data", file);
        return axios.post(this.ENDPOINT + "upload" + (id != null ? "/" + id : ""), formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress,
        });
    },
    delete: async function (id) {
        return axios.delete(this.ENDPOINT + id);
    },
    url: function(uri) {
        return process.env.REACT_APP_BASE_URL + uri
    }
}

export default FileService