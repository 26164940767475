import {CountdownCircleTimer} from "react-countdown-circle-timer";
import PropTypes from "prop-types";
import {Typography, useTheme} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

/**
 * Countdown timer component, displays remaining time until a specified target date
 * @param props component properties
 * @cauthor dame.gjorgjievski
 */
export default function CountDownTimer(props) {
    const classes = makeStyles((theme) => createStyles({
        rootTimer: {
            width: 320,
            display:'flex',
            marginLeft: -theme.spacing(2.5),
            [theme.breakpoints.up('sm')]: {
                marginLeft: 0,
                justifyContent: 'space-between',
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: -theme.spacing(2.5),
            },
            '& svg': {
                display: 'none'
            }
        },
        timeWrapper: {
            '& .time': {
                color: theme.palette.tertiary.var3,
            }
        }
    }))()
    const {t} = useTranslation()
    const theme = useTheme()
    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
        isPlaying: true,
        size: 60,
        strokeWidth: 6
    };

    const childProps = {
        style: {margin: 5}
    }

    const renderTime = (dimension, time) => {
        return (
            <div className={classes.timeWrapper}>
                <Typography variant="h1" align="center" className="time">{time}</Typography>
                <Typography variant="body1" align="center" className="time">{dimension}</Typography>
            </div>
        );
    };

    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;

    const startTime = Date.now() / 1000; // UNIX timestamp in seconds
    const endTime = props.time / 1000; // UNIX timestamp in seconds

    const remainingTime = endTime - startTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    if (startTime < endTime)
        return <div style={{display: 'flex'}} className={classes.rootTimer}>
            <div {...childProps} >
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[["#7E2E84"]]}
                    duration={daysDuration}
                    initialRemainingTime={remainingTime}
                    strokeWidth={7}
                >
                    {({elapsedTime}) =>
                        renderTime(t('client.default.labels.days').toUpperCase(), getTimeDays(daysDuration - elapsedTime))
                    }
                </CountdownCircleTimer>
            </div>
            <div {...childProps} >
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[["#D14081"]]}
                    duration={daySeconds}
                    initialRemainingTime={remainingTime % daySeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > hourSeconds
                    ]}
                >
                    {({elapsedTime}) =>
                        renderTime(t('client.default.labels.hours').toUpperCase(), getTimeHours(daySeconds - elapsedTime))
                    }
                </CountdownCircleTimer>
            </div>
            <div {...childProps} >
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[["#EF798A"]]}
                    duration={hourSeconds}
                    initialRemainingTime={remainingTime % hourSeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > minuteSeconds
                    ]}
                >
                    {({elapsedTime}) =>
                        renderTime(t('client.default.labels.minutes').toUpperCase(), getTimeMinutes(hourSeconds - elapsedTime))
                    }
                </CountdownCircleTimer>
            </div>
            <div {...childProps} >
                <CountdownCircleTimer
                    {...timerProps}
                    colors={[[theme.palette.secondary.var1]]}
                    duration={minuteSeconds}
                    initialRemainingTime={remainingTime % minuteSeconds}
                    onComplete={(totalElapsedTime) => [
                        remainingTime - totalElapsedTime > 0
                    ]}
                >
                    {({elapsedTime}) =>
                        renderTime(t('client.default.labels.seconds').toUpperCase(), getTimeSeconds(elapsedTime))
                    }
                </CountdownCircleTimer>
            </div>
        </div>
    else return ""
}

CountDownTimer.propTypes = {
    time: PropTypes.number
};

CountDownTimer.defaultProps = {
    time: 100000
}
