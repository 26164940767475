import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import PollService from "../services/PollService";
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LinearProgressBar from "./LinearProgressBar";
import {useTranslation} from "react-i18next";
import useLanguage from "../data/Language";

const styles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: "space-between",
        '& .pollWrap': {
            width: '100%',
            overflow: 'hidden scroll',
            margin: theme.spacing(1, 0),
            "-ms-overflow-style": "none"
        },
        '& ::-webkit-scrollbar': {
            display: 'none'
        }
    },
    poll: {
        backgroundColor: theme.palette.secondary.var2,
        width: '100%',
        maxWidth: 258,
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        "& .MuiFormControl-root": {
            margin: 0
        }
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    question: {
        marginBottom: theme.spacing(1)
    },
    radioOff: {
        display: 'none'
    },
    radioOn: {
        display: 'block'
    },
    option: {
        margin: theme.spacing(0),
        marginTop: theme.spacing(1),
        '& .MuiFormControlLabel-root': {
            margin: theme.spacing(0),
            marginBottom: theme.spacing(1),
            '& .MuiTypography-body1': {
                fontWeight: 800
            }
        },
        '& .Mui-disabled': {
            color: theme.palette.common.black
        },
        '& .MuiRadio-root': {
            padding: theme.spacing(0.25),
            paddingRight: theme.spacing(1)
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: theme.palette.primary.var1
        }
    }
}));

/**
 * Component displaying the available polls in a room
 * @author dame.gjorgjievski
 */
export default function SessionPolls(props) {

    const {t} = useTranslation()
    const {value} = useLanguage()
    const classes = styles()
    const [polls, setPolls] = useState([])

    useEffect(() => {
        if (!props.session || !props.session.id) return
        PollService.findForSession(props.session.id).then(response => {
            setPolls(response.data)
        })
    }, [props.room])

    const onPollVote = (poll, option, event) => {
        event.preventDefault()
        let index = -1
        polls.forEach((p, i) => {
            if (p.id === poll.id) index = i
        })
        // skip if there is no change in vote option
        if (polls[index].option && polls[index].option.id === option.id) return
        console.log("Vote", poll, event.target.value)
        PollService.vote(poll.id, option.id).then(response => {
            // console.log("poll vote response", response)
            polls[index] = response.data
            setPolls([...polls])
        })
    }
    return <div className={classes.root}>
        <div className="pollWrap">
            {polls.filter(p => p.enabled).map((poll, i) =>
                <Box key={"poll" + i} className={classes.poll}>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend" className={classes.title}>{value(poll.name)}</FormLabel> */}
                        <Typography className={classes.question} variant='h6'>{value(poll.content)}</Typography>
                        <RadioGroup aria-label={t('poll.options')} name="poll-options"
                                    value={(poll.option && poll.option.id) || -1}>
                            {poll.options.map((option, i) =>
                                <div key={"option" + i} className={classes.option}>
                                    <FormControlLabel label={value(option.content)}
                                                      control={<Radio value={option.id}
                                                                      disabled={!!poll.option}
                                                                      className={poll.option ? classes.radioOff : classes.radioOn}
                                                                      checked={poll.option && poll.option.id === option.id}
                                                                      onChange={e => onPollVote(poll, option, e)}/>}/>
                                    {/**<Typography variant="button" style={{verticalAlign: 'middle'}}>{option.count} / {option.percent}%</Typography>**/}
                                    {poll.option && <LinearProgressBar className="large" value={option.percent}/>}
                                </div>
                            )}
                        </RadioGroup>
                    </FormControl>
                </Box>)}
        </div>
    </div>
}

SessionPolls.propTypes = {
    session: PropTypes.object
}

SessionPolls.defaultProps = {
    session: null
}
