import {SvgIcon} from '@material-ui/core';
import React from 'react'
import {theme} from '../styles/Theme';

/**
 * Mov icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function VideoIcon() {
    return (
        <SvgIcon viewBox="0 0 57.758 77.01">
            <defs>
                <linearGradient id="linear-gradient" x1="1.028" y1="0.16" x2="-0.141" y2="-0.17" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor={theme.palette.secondary.main}/>
                    <stop offset="1" stopColor={theme.palette.primary.var3}/>
                </linearGradient>
            </defs>
            <path id="Path_929" data-name="Path 929" d="M33.692,7.2a3.7,3.7,0,0,0-3.7-3.7H13.325a3.7,3.7,0,0,0-3.7,3.7V23.872a3.7,3.7,0,0,0,3.7,3.7H29.993a3.7,3.7,0,0,0,3.7-3.7V22.306L37.9,26.513a4.146,4.146,0,0,0,2.552,1.057h4.07a3.61,3.61,0,0,0,3.61-3.61V7.115a3.61,3.61,0,0,0-3.61-3.61h-4.07A4.147,4.147,0,0,0,37.9,4.562L33.692,8.769ZM28.879,22.758H14.439V8.318H28.879ZM40.95,8.318h2.368V22.758H40.95l-7.258-6.193V14.511ZM55.643-20.269,43.026-32.885A8.292,8.292,0,0,0,37.921-35H7.22A7.22,7.22,0,0,0,0-27.78V34.79a7.22,7.22,0,0,0,7.22,7.22H50.538a7.22,7.22,0,0,0,7.22-7.22V-15.164A8.293,8.293,0,0,0,55.643-20.269ZM38.505-30.113a2.817,2.817,0,0,1,1.118.631L52.24-16.865a2.816,2.816,0,0,1,.631,1.118H38.505Zm14.439,64.9A2.407,2.407,0,0,1,50.538,37.2H7.22A2.407,2.407,0,0,1,4.813,34.79V-27.78A2.407,2.407,0,0,1,7.22-30.187H33.692v15.643a3.61,3.61,0,0,0,3.61,3.61H52.944Z" transform="translate(0 35)" fill="url(#linear-gradient)"/>
        </SvgIcon>
    )
}
