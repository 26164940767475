import PropTypes from "prop-types"
import {SessionStatus} from "../data/Constants";
import {Typography} from "@material-ui/core";
import React from "react";
import DateUtil from "../utils/DateUtil";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const styles = makeStyles((theme) => createStyles({
    sessionInfoBox: {
        color: theme.palette.primary.main,
        borderRadius: '10px',
        '& span': {
            padding: theme.spacing(0.5),
            borderRadius: '6px',
            color: theme.palette.common.white,
            letterSpacing: '2px'
        },
        '& .live-now': {
            backgroundColor: theme.palette.secondary.main,
        },
        '& .upcoming': {
            backgroundColor: theme.palette.primary.var1,
        },
        '& .passed': {
            backgroundColor: theme.palette.passed,
        },
        '& h6': {
            opacity: '50%'
        }
    }
}))

/**
 * Simple component displaying a session time status
 * @author dame.gjorgjievski
 */
export function SessionStatusFlag(props) {

    const {t} = useTranslation()
    const classes = styles()
    const getStatus = () => {
        let ts = new Date(props.session.timeStart), te = new Date(props.session.timeEnd), now = DateUtil.utc()
        let status = SessionStatus.PASSED
        if(ts <= now && now < te) status = SessionStatus.LIVE
        else if(ts > now && te > now) status = SessionStatus.UPCOMING
        return status
    }

    switch (getStatus()) {
        case SessionStatus.LIVE:
            return <div className={classes.sessionInfoBox}>
                <Typography variant="button" component="span" className="live-now">{t('client.session.labels.live')}</Typography>
            </div>
        case SessionStatus.UPCOMING:
            return <div className={classes.sessionInfoBox}>
                <Typography variant="button" component="span" className="upcoming">{t('client.session.labels.upcoming')}</Typography>
            </div>
        case SessionStatus.PASSED:
            return <div className={classes.sessionInfoBox}>
                <Typography variant="button" component="span" className="passed">{t('client.session.labels.premiered')}</Typography>
            </div>
        default:
            return <div className={classes.sessionInfoBox}>
                <Typography variant="button" component="span" className="live-now">{t('client.session.labels.live')}</Typography>
            </div>
    }
}

SessionStatusFlag.propTypes = {
    session: PropTypes.object
}
