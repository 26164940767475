import React from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {styles} from "../styles/FormStyles"
import {useTranslation} from "react-i18next";

/**
 * Displays an user login view
 * @author dame.gjorgjievski
 */
export default function LoginFAView(props) {

    const classes = styles()
    const {t} = useTranslation()

    const submit = (e) => {
        props.userManager.signinRedirect()
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">{t('client.user.labels.login-title')}</Typography>
                <form className={classes.form} noValidate>
                    <Button
                        //type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={submit}
                        data-cy="login-btn-submit"
                    >{t('client.user.actions.sign-in')}</Button>
                </form>
            </div>
        </Container>
    );
}
