import {Box, makeStyles, Slide, Tooltip} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addRoom} from "../data/Store";
import DateUtil from "../utils/DateUtil";
import ChatBox from "./ChatBox";
import MessageService from "../services/MessageService";
import {apiFacade} from "../data/ApiFacade";
import RoomService from "../services/RoomService";
import ConversationIcon from "../assets/ConversationIcon";
import {useTranslation} from "react-i18next";
import {useWebSocket} from "../data/WebSocketManager";
import {EventType, MessageType} from "../data/Constants";

const styles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 10000
    },
    wrapper: {
        marginLeft: theme.spacing(3),
        display: 'inline-flex',
        alignItems: 'flex-end'
    },
    actionButton: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        } 
    },
    circle: {
        padding: theme.spacing(1),
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: '50%',
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
            width: theme.spacing(7),
            height: theme.spacing(7),
        } 
    }
}))

/**
 * Component displaying active user-2-user conversations, i.e. rooms in state store
 * @author dame.gjorgjievski
 */
export default function Conversations() {

    const {t} = useTranslation()
    const classes = styles()
    const dispatch = useDispatch()
    const rooms = useSelector(state => state.rooms)
    const roomsRef = useRef(rooms)
    const ws = useWebSocket()

    const loadMessages = () => {
        MessageService.findUnseen().then(response => {
            console.log("loaded unseen messages", response.data, rooms)
            response.data.forEach(m => {
                apiFacade.onEvent({action: EventType.MESSAGE, data: {message: JSON.stringify(m)}})
            })
        })
    }

    useEffect(() => {
        ws.connect()
        loadMessages()
        apiFacade.on(EventType.MESSAGE, onMessageEvent)
        console.log("conversations mount")
        return () => {
            apiFacade.off(EventType.MESSAGE, onMessageEvent)
            ws.disconnect()
            console.log("conversations unmount")
        }
    }, [])

    useEffect(() => {
        if (rooms) roomsRef.current = rooms
    }, [rooms])

    const onNewConversation = (e) => {
        let room = rooms.filter(r => r.name === 'default')[0]
        if (!room) {
            room = {
                id: null,
                name: "default",
                api_created: false,
                created_at: DateUtil.toString(DateUtil.utc()),
                users: [null],
                open: true
            }
            dispatch(addRoom(room))
        }
    }

    const onMessageEvent = (e) => {
        let m = JSON.parse(e.data.message)
        let room = roomsRef.current.filter(r => r.id === m.roomId)[0]
        console.log("handling message event for room", m.roomId)
        if (room) {
            if (room.open) RoomService.updateSeenStatus(room.id)
            else room.count++
        } else {
            if (MessageType.CHAT === m.type)
                RoomService.provideChatRoom(m.user.id).then(response => {
                    dispatch(addRoom({...response.data, count: 1}))
                })
        }
    }

    return <div className={classes.root} id="conversations">
        <Box style={{width: 110, display: 'inline-block', verticalAlign: "bottom"}}>

            <Tooltip className={classes.actionButton} title={t('client.chat.labels.new-conversation')} placement="top"
                     TransitionComponent={Slide} TransitionProps={{timeout: 0}}>
                <Box
                    className={classes.circle}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={onNewConversation}
                >
                    <ConversationIcon/>
                </Box>
            </Tooltip>
            {/* <Fab className={classes.circle} size="large" color="default" aria-label="add" onClick={onNewConversation}>
                    <ConversationIcon/>
                </Fab> */}
        </Box>
        <Box className={classes.wrapper} flexDirection="row">
            {rooms.map((room, i) =>
                <ChatBox room={room} key={"chatbox" + i}/>
            )}
        </Box>
    </div>
}
