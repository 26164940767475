import { Box, Button, TextField } from '@material-ui/core';
import ForwardIcon from "@material-ui/icons/Forward";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { apiFacade } from "../data/ApiFacade";
import { EventType, MessageType } from "../data/Constants";
import MessageService from "../services/MessageService";
import { styles } from '../styles/ChatStyle';
import DateUtil from "../utils/DateUtil";
import StringUtil from "../utils/StringUtil";
import MessageBox from "./MessageBox";

/**
 * Chat messages component
 * @param Component component class/function to render
 * @param props component properties
 * @returns {JSX.Element}
 * @author petar.todorovski
 * @author dame.gjorgjievski
 */
function Messages({ component: Component, ...props }) {

    const { t } = useTranslation()
    const PAGE_SIZE = 15
    const BUFFER_SIZE = 15
    const classes = styles()
    const [message, setMessage] = useState("")
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        start: DateUtil.utc(),
        end: DateUtil.utc(),
        size: BUFFER_SIZE,
        append: false,
        top: false,
        bottom: false
    })
    const chatRef = useRef(null)
    const stateRef = useRef(null)
    const messagesRef = useRef(messages)
    const messageRef = []
    let scroll = false

    useEffect(() => {
        if (!props.room || !props.room.id) return
        console.log("loading room messages ", props.type, props.room)
        // load initial room messages
        loadMessages()
        apiFacade.on(EventType.MESSAGE, handleMessageEvent)
        return () => {
            apiFacade.off(EventType.MESSAGE, handleMessageEvent)
        }
    }, [props.room])

    useEffect(() => {
        if (!messages || !stateRef.current) return
        scroll = false // disable scroll event listener processing
        messagesRef.current = messages
        if (stateRef.current.append) chatRef.current.scrollTop = chatRef.current.scrollHeight - chatRef.current.offsetHeight
        else chatRef.current.scrollTop = calculateHeight(stateRef.current.size)
        // console.log("scrolling into view ", stateRef.current, chatRef.current.scrollTop, state)
    }, [messages])

    const loadMessages = () => {
        if (!props.room.id || loading) return
        setLoading(true)
        MessageService.findForRoom(props.room.id, PAGE_SIZE, props.type).then(response => {
            let result = (response.data && response.data.reverse()) || []
            if (result.length > 0) {
                let first = result[0], last = result[result.length - 1]
                stateRef.current = {
                    ...stateRef.current,
                    start: first.timeCreated,
                    end: last.timeCreated,
                    append: true,
                    size: BUFFER_SIZE,
                    bottom: true,
                    top: false
                }
                setState(stateRef.current)
                setMessages(result)
            } else setMessages([])
            setLoading(false)
        })
    }

    const handleMessageEvent = (event) => {
        let message = JSON.parse(event.data.message)
        if (!message) {
            console.log("Failed to parse message from event", event)
            return
        }
        let m = messagesRef.current.filter(m => m.id === message.id)[0] || null
        if (m || message.type !== props.type || message.roomId !== props.room.id) return

        MessageType.NOTIFICATION === message.type
            ? messagesRef.current.unshift(message)
            : messagesRef.current.push(message)

        stateRef.current = { ...stateRef.current, end: message.timeCreated, append: true, bottom: true }
        setState(stateRef.current)
        setMessages([...messagesRef.current])
        console.log("handling message event", event)
    }

    const calculateHeight = (idx) => {
        let h = 0;
        messageRef.some((m, i) => {
            h = h + m.offsetHeight
            return i === (idx - 1)
        })
        return h
    }

    const handleKeyPress = (event) => {
        // handle enter key
        if (event.charCode === 13) sendMessage()
    }

    const sendMessage = () => {
        if (StringUtil.isEmpty(message) || !props.room.id) return
        setMessage("")
        MessageService.sendMessage(props.room, message, props.type).then(response => {
            let message = response.data
            if (MessageType.CHAT !== props.type) apiFacade.sendMessage(message)
            console.log("appending message", message)
            stateRef.current = { ...stateRef.current, end: message.timeCreated, append: true, bottom: true }
            setState(stateRef.current)
            setMessages([...messages, message])
        })
    }

    const handleScroll = (event) => {

        if (!scroll) { // skip scroll
            scroll = true;
            return
        }
        if (event.target.scrollTop === 0 && !stateRef.current.top && !loading) {

            console.log("scroll to top")
            setLoading(true)
            MessageService.findForRoom(props.room.id, BUFFER_SIZE, props.type, state.start).then(response => {
                let result = (response.data && response.data.reverse()) || []
                if (result.length > 0) {
                    let first = result[0]
                    console.log("prepending messages", result)
                    setMessages([...result, ...messages])
                    stateRef.current = {
                        ...stateRef.current, start: first.timeCreated, append: false,
                        size: result.length, top: false
                    }
                    setState(stateRef.current)
                } else {
                    stateRef.current = { ...stateRef.current, append: false, size: 0, top: true }
                    setState(stateRef.current)
                }
                setLoading(false)
            })

        } else if (event.target.scrollTop === (event.target.scrollHeight - event.target.offsetHeight)
            && !stateRef.current.bottom && !loading) {

            console.log("scroll to bottom")
            setLoading(true)
            MessageService.findForRoom(props.room.id, BUFFER_SIZE, props.type, null, state.end).then(response => {
                let result = (response.data && response.data.reverse()) || []
                if (result.length > 0) {
                    let hasResult = false
                    for (let i in result) {
                        let msg = result[i]
                        let existing = messages.find(m => m.id === msg.id)
                        if (!existing) {
                            messages.push(msg)
                            hasResult = true
                        }
                    }
                    let last = result[result.length - 1]
                    if (hasResult) {
                        console.log("appending messages", result)
                        setMessages([...messages])
                        stateRef.current = {
                            ...stateRef.current, end: last.timeCreated, append: true,
                            size: result.length, bottom: false
                        }
                        setState(stateRef.current)
                    } else {
                        let dateTime = new Date(last.timeCreated)
                        dateTime.setSeconds(dateTime.getSeconds() + 1)
                        stateRef.current = {
                            ...stateRef.current, end: DateUtil.toString(dateTime), append: true, size: 0, bottom: true
                        }
                        setState(stateRef.current)
                    }
                } else {
                    stateRef.current = { ...stateRef.current, append: false, size: 0, bottom: true }
                    setState(stateRef.current)
                }
                setLoading(false)
            })
        }
    }

    const handleDelete = (message) => (e) => {
        e.preventDefault()
        MessageService.delete(message.id).then(response => {
            let arr = messages
            let idx = arr.findIndex(m => m.id === message.id)
            if (idx > -1) arr.splice(idx, 1)
            setMessages([...arr])
        })

        if (MessageType.NOTIFICATION === props.type && !message.seen) {
            props.notificationOnDelete()
        }
    }

    return (
        <>
            <Box className={classes.chatMsgWrap} display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" flexDirection="column" ref={chatRef}
                    className={MessageType.NOTIFICATION === props.type ? classes.notifChat : "chatMsgWrapInner"}
                    onScroll={handleScroll}>
                    {MessageType.NOTIFICATION === props.type && (messages && messages.length === 0)
                        ? <Component isSingle={true} isEmpty={true}/>
                        : messages && messages.map((message, i) =>
                            <Component message={message} key={"msg" + i} ref={ref => messageRef[i] = ref}
                                avatars={props.avatars} onDelete={handleDelete(message)}
                                click={props.notificationOnClick} />)
                    }
                </Box>
                {props.input && <Box style={{ position: "relative", display: "inline-block" }}>
                    <Button onClick={sendMessage} className={classes.button}>
                        <ForwardIcon />
                    </Button>
                    <TextField size="medium" margin="none" className={classes.inputChat}
                        variant="filled" onKeyPress={handleKeyPress}
                        placeholder={t('client.chat.actions.type-message')} value={message}
                        InputProps={{ disableUnderline: true }} onChange={e => setMessage(e.target.value)} />
                </Box>}
            </Box>
        </>
    )
}

Messages.propTypes = {
    room: PropTypes.object,
    type: PropTypes.string,
    avatars: PropTypes.bool,
    input: PropTypes.bool,
    component: PropTypes.elementType
};

Messages.defaultProps = {
    room: {},
    type: MessageType.TOPIC,
    avatars: true,
    input: true,
    component: MessageBox
}

const ChatMessages = React.memo(Messages, (prev, next) => {
    return prev.room?.id === next.room?.id && prev.type === next.type
})
export default ChatMessages
