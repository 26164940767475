import React from "react";
import UserProfile from "./UserProfile";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../data/Store";

/**
 * Wrapper augmenting user state for user profile dialog
 * @author dame.gjorgjievski
 */
export default function UserProfileView() {

    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const event = useSelector(state => state.event)

    const onClose = (e) => {
        console.log("closing")
        dispatch(setUser(null))
        setTimeout(() => {
            console.log("user is ", user)
        }, 5000)
    }

    return <UserProfile event={event} user={user} onClose={onClose}/>
}
