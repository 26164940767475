import { SvgIcon } from '@material-ui/core';
import React from 'react'

/**
 * Globe button icon
 * @param className component class name
 * @returns {JSX.Element}
 * @author petar.todorovski
*/

export default function GoodFeedBackIcon({ width, height }) {
    return (
        <SvgIcon style={{ width: width ? width : '100%', height: height ? height : '100%' }} viewBox="0 0 45.205 42.191">
            <rect id="Rectangle_154" data-name="Rectangle 154" width="45.205" height="42.191" rx="17" fill="#ECBF2B" />
            <ellipse id="Ellipse_18" data-name="Ellipse 18" cx="2.073" cy="2.073" rx="2.073" ry="2.073" transform="translate(31.208 15.423)" fill="#424242" />
            <path id="Path_734" data-name="Path 734" d="M384.492,639.348a2.072,2.072,0,1,1-2.072-2.072A2.073,2.073,0,0,1,384.492,639.348Z" transform="translate(-370.496 -621.853)" fill="#424242" />
            <line id="Line_7" data-name="Line 7" y1="0.401" x2="16.026" transform="translate(14.59 29.195)" fill="none" stroke="#424242" />
        </SvgIcon>
    )
}
