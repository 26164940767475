import React from 'react'
import {Box, Button, ButtonGroup, Typography} from '@material-ui/core'
import {createStyles, makeStyles} from "@material-ui/core/styles"
import {useTranslation} from 'react-i18next'
import PropTypes from "prop-types";

const styles = makeStyles((theme) => createStyles({
    footer: {
        marginTop: '5px'
    },
    bottomLinks: {
        position: 'relative',
        width: '100%',
        '& .company-brand': {
            color: theme.palette.primary.main,
            opacity: '50%',
            padding: theme.spacing(1, 0),
            textAlign: 'center'
        },
    },
    btnGroup: {
        height: 14,
        color: theme.palette.primary.main,
        opacity: '50%',
        padding: theme.spacing(2),
        position: 'absolute',
        right: 0,
        bottom: 0,
        '& .MuiButton-text': {
            padding: 0
        }
    },
    partners: {
        maxWidth: theme.spacing(103),
        margin: '0 auto',
        '& img': {
            width: '100%'
        },
        '& .partners': {
            padding: theme.spacing(1.2)
        }
    }
}))

/**
 * Component displaying footer information & links
 * @author petar-todorovski
 */
export default function FooterLinks(props) {

    const {t} = useTranslation()
    const classes = styles()

    return (
        <Box className={classes.footer} display="flex" justifyContent="flex-end" alignItems="flex-end" flexDirection="column">
            {/* <Box className={classes.partners}>
                <Box className="partners">
                    <img src="/images/Partners.png" alt=""/>
                </Box>
            </Box> */}
            <Box display="flex" justifyContent="center" className={classes.bottomLinks}>
                <Typography variant="body2" className="company-brand">{t("client.default.labels.built-by")} <a className="company-brand" href="https://www.newsroom.tech" target="_blank" rel="noreferrer">newsroom.tech</a>
                </Typography>
                <ButtonGroup disableRipple className={classes.btnGroup} variant="text" color="primary"
                             aria-label="text primary button group">
                    {props.event && props.event.contact &&
                    <Button href={`mailto:${props.event.contact}`}>
                        <Typography variant="body2">{t('client.default.labels.contact-us')}</Typography>
                    </Button>}
                </ButtonGroup>
            </Box>
        </Box>
    )
}

FooterLinks.propTypes = {
    event: PropTypes.object
};

FooterLinks.defaultProps = {
    event: null
}
